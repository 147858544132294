import React from 'react'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'
import config from 'config'
import getBrowserLanguage from './utils/languageDetection'
import store from './redux/store'
import App from './App'
import './index.css'
import commonEnUS from './translations/enUS/common.json'
import commonEnGB from './translations/enGB/common.json'

const region = getBrowserLanguage() === 'en-GB' ? 'enGB' : 'enUS'

const orgTranslations = {}

const orgTranslationsConfigs = region === 'enGB'
  ? require.context('./translations/enGB/organisation/', false, /\.(json)$/)
  : require.context('./translations/enUS/organisation/', false, /\.(json)$/)

orgTranslationsConfigs.keys().map((_key) => {
  const obj = orgTranslationsConfigs(_key)
  const key = _key.split('/').pop().split('.').shift()
  orgTranslations[key] = obj
  return _key
})

const translationsGB = region === 'enGB' ? orgTranslations : {}
const translationsUS = region === 'enUS' ? orgTranslations : {}

const resources = {
  enUS: {
    common: commonEnUS, // 'common' is our custom namespace
    ...translationsUS,
  },
  enGB: {
    common: commonEnGB,
    ...translationsGB,
  },
}
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: region,
  nsSeparator: false,
  resources,
})

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement)

window.reactAppVersion = process.env.REACT_APP_VERSION

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <Auth0Provider
          domain={config.auth0.domain || ''}
          clientId={config.auth0.client || ''}
          redirectUri={`${window.location.origin}/home`}
          audience={config.auth0.audience}
          scope={config.auth0.scope}
        >
          <App />
        </Auth0Provider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
)
