import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Dialog, DialogContent, Modal, Typography, Button,
  Container, Grid, IconButton, DialogTitle,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import ReactCodeInput from 'react-verification-code-input'
import classNames from 'classnames'

import { updateVerifiedPhoneNumber } from 'reducers/usersReducer'
import Loading from 'fragments/Loading'
import { ModalClose, ModalDiv } from 'pages/Profile/Profile.style'

import { sendPhonenumberVerification, verifyPhoneNumber } from 'utils/api'

import { SubHeader } from 'components/Welcome'
import { Title, DescriptionText, ErrorMessage } from 'components/Typography'
import { useStyles, FormSuccess } from './VerifyDialog.style'

export default function VerifyDialog({
  openDialog = () => {},
  onCloseDialog = () => {},
  setPhoneNumberForRegistration = () => {},
  canSkipVerify = false,
  hideDetailsText = false,
}) {
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user)
  const [validPhoneNumber, setValidPhoneNumber] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const [formError, setFormError] = useState(null)
  const [formSuccess, setFormSuccess] = useState(null)
  const [verifyClicked, setVerifyClicked] = useState(false)

  const clickOutsideOfModalBehaviour = () => {
    if (canSkipVerify) onCloseDialog()
  }
  const closeModal = () => setModalOpen(() => false)
  const skipVerification = () => {
    setModalOpen(() => false)
    onCloseDialog()
  }

  const onCodeComplete = async (code) => {
    setLoading(true)

    try {
      await verifyPhoneNumber(undefined, validPhoneNumber, code)
      dispatch(updateVerifiedPhoneNumber(validPhoneNumber))
      onCloseDialog()
      setFormError(null)
      setLoading(false)
    } catch (error) {
      setFormSuccess(null)
      setFormError(error.message
        || 'There was an error verifying your phone number, please contact us to resolve this issue.')
      setLoading(false)
    }
  }

  const validatePhoneNumber = (phoneNumber) => (
    typeof phoneNumber === 'string'
        && phoneNumber.length > 4
        && isValidPhoneNumber(phoneNumber)
  )

  const handlePhoneInput = (value) => {
    setFormError(null)
    if (validatePhoneNumber(value)) {
      setValidPhoneNumber(value)
    } else {
      setValidPhoneNumber(null)
      setVerifying(false)
    }
  }

  const onVerifyClick = async () => {
    try {
      if (!validPhoneNumber) throw new Error('Invalid phone number!')
      setVerifyClicked(true)
      await sendPhonenumberVerification(validPhoneNumber)
      setVerifying(true)
      setVerifyClicked(false) // re-enable the verify button
      if (setPhoneNumberForRegistration) setPhoneNumberForRegistration(validPhoneNumber)
      return true
    } catch (err) {
      setFormError(err.message)
      setFormSuccess(null)
      setVerifyClicked(false) // re-enable the verify button
      return null
    }
  }

  const classes = useStyles()

  return (
    <Dialog
      open={openDialog}
      maxWidth="xs"
      fullWidth
      onClose={clickOutsideOfModalBehaviour}
      PaperProps={{
        style: {
          borderRadius: '20px',
          padding: '20px 0',
        },
      }}
    >
      <DialogTitle>
        {canSkipVerify && (
          <IconButton
            style={{
              position: 'absolute',
              right: '0px',
              top: '0px',
            }}
            onClick={onCloseDialog}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Container maxWidth="xs">
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Grid item>
              <Typography
                variant="h6"
                style={{
                  fontSize: '0.99rem',
                  fontFamily: 'Poppins',
                  fontWeight: 'bold',
                  color: '#383838',
                }}
              >
                Verify your phone number
              </Typography>
            </Grid>

            <Grid item>
              {hideDetailsText ? null : (
                <SubHeader>
                  So we can send you a reminder at the time and date you requested.
                </SubHeader>
              )}
            </Grid>

            <Grid item>
              <PhoneInput
                defaultCountry={userState?.country || 'US'}
                // countries={['GB', 'US', 'CA', 'AE', 'AL', 'NL', 'ES', 'TR', 'UA', 'CH']}
                placeholder="Mobile number"
                value={validPhoneNumber}
                onChange={(number) => { handlePhoneInput(number) }}
                international
              />
            </Grid>

            <Grid item>
              {verifying ? (
                <>
                  <SubHeader>
                    We&#39;ve sent you a verification code via SMS, please enter it below:
                  </SubHeader>

                  <ReactCodeInput
                    type="number"
                    fieldWidth={40}
                    fieldHeight={40}
                    onComplete={onCodeComplete}
                  />

                </>
              ) : (
                <Button
                  onClick={() => onVerifyClick()}
                  size="medium"
                  color="primary"
                  fullWidth
                  className={classes.verifyButton}
                  variant="contained"
                  disabled={verifyClicked}
                >
                  <span className={classNames('MuiButton-label', classes.labelVerifyButton)}>Verify</span>
                </Button>
              )}
            </Grid>
            <Grid item xs>
              {loading && <Loading />}
              {formSuccess && <FormSuccess id="success_verification">{formSuccess}</FormSuccess>}
            </Grid>
            <Grid item xs={12} className={formError ? classes.errorMessageContainer : classes.hideItem} id="error_message">
              <ErrorMessage>{formError}</ErrorMessage>
            </Grid>
            <Modal
              open={modalOpen}
              onClose={closeModal}
              aria-labelledby="simple-modal-title"
            >
              <ModalDiv>
                <ModalClose>
                  <IconButton size="small" onClick={closeModal}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </ModalClose>
                <Grid container>
                  <Grid item xs={12} style={{ marginLeft: '27px', marginRight: '27px' }}>
                    <Title>
                      <b>Skip phone registration?</b>
                    </Title>
                    <DescriptionText>
                      We won&#39;t be able to send you the SMS self-assessment reminders.
                    </DescriptionText>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      id="close_modal"
                      size="medium"
                      color="primary"
                      className={classes.close_modal}
                      onClick={skipVerification}
                    >
                      <span className={classNames('MuiButton-label', classes.labelCloseModal)}>Skip</span>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      onClick={closeModal}
                      size="medium"
                      variant="text"
                      color="primary"
                      className={classes.skipButton}
                    >
                      <span className={classNames('MuiButton-label', classes.skipPrimaryButton)}>Back</span>
                    </Button>
                  </Grid>
                </Grid>
              </ModalDiv>
            </Modal>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  )
}
