import React, { useEffect, useState } from 'react'
import { AutoscrollRunner } from '@tripetto/runner-autoscroll'
import { Export } from '@tripetto/runner'
import CompletingAssessement from 'fragments/CompletingAssessement'
import { Grid } from '@material-ui/core'
import { TripettoStyle } from 'constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { submitSurvey } from 'utils'
import * as api from 'utils/api'
import FollowUpSurveyDefinition from './FollowUpSurvey.json'

const FollowUp = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const search = useLocation().search

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const surveyName = searchParams.get('surveyName')
    const results = searchParams.get('results')
    const surveyQuestion = searchParams.get('question')

    if (surveyName && results && surveyQuestion) {
      api.submitSurvey(surveyName, [surveyQuestion], [results])
    }
  }, [])

  const handleSubmit = async (instance) => {
    // This exports all exportable data in the form
    const exportables = Export.exportables(instance)

    // Iterate through all the fields
    const questions = []
    const answers = []
    exportables.fields.forEach((field) => {
      questions.push(field.name)
      if (!field.string) answers.push('Not Specified')
      else answers.push(field.string)
    })

    await submitSurvey(
      'FOLLOW_UP',
      questions,
      answers,
    )

    setLoading(true)
    navigate('/questions')
  }

  if (loading) return <CompletingAssessement />

  return (
    <Grid
      container
      maxWidth="xs"
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: '40px' }}
    >
      <Grid item style={{ width: '360px' }}>
        <AutoscrollRunner
          definition={FollowUpSurveyDefinition}
          styles={TripettoStyle}
          onSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  )
}

export default FollowUp
