/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import * as api from 'utils/api'
import { REDUX_FETCH_STATUS } from 'constants'

const initialState = {
  // Identifiers
  userId: null, // string
  organisationId: null, // string
  syndiPayId: null, // string
  phoneNumber: null, // string
  email: null, // string
  userRoles: null, // string[]
  country: '', // Detected via cloud-front headers on the backend

  // Privacy and verifications
  verified: null, // boolean
  acceptedMarketingComms: null, // boolean
  acceptedPrivacyPolicy: null, // boolean
  requiresUnidaysVerification: null, // boolean

  // Timestamps
  createdAt: null, // string
  lastLoggedIn: null, // string
  lastVerificationTime: null, // string
  lastRequestTime: null, // string
  userTimezone: null, // string

  // Conditions / Functionalities
  conditions: [], // string[]
  functionalities: [], // string[]
  selectedHealthAreas: [], // string[]

  // User Health
  health: [], // {}[]
  questionsAnswered: null, // number
  criticalHealth: null, // boolean

  // Updated by fetchCurrentSchedule call
  checkupTime: null, // string
  preferredReminder: null, // string
  reminderFrequency: null, // string

  // Updated by fetch reminder options call
  reminderOptions: null, // { name: string, type: string, enabled: boolean }[]

  // User Completed Surveys So Far
  userSurveys: [], // {}[]

  // Search Input
  userSearchInput: '', // string

  userFetchState: REDUX_FETCH_STATUS.idle,
  userUpdateState: REDUX_FETCH_STATUS.idle,
  error: null, // string
}

// Order a users health by health assessment date
const orderUserHealth = (health = []) => {
  const groupedByDay = {}
  const lineDataKeys = {}

  health.forEach(({
    name, score, date, level,
  }) => {
    // Reset the date to the beginning of the day for grouping
    const day = moment(date).hours(0).minutes(0).seconds(0)
      .valueOf()

    // Set the points data grouped by day (append to the days data if already exists)
    if (!groupedByDay[day]) groupedByDay[day] = {}
    groupedByDay[day].dateTime = day

    // Set the data keys for retrieving the scores in the <Line> element
    lineDataKeys[name] = true
    groupedByDay[day][name] = score
    groupedByDay[day][`${name}Level`] = level // add the level for the tooltip
  })
  return Object.values(groupedByDay)
}

// Calculate if user is in critical condition and open the SOS modal if that's the case
const calculateUserCritical = (orderedHealth = []) => {
  if (orderedHealth.length === 0) return false
  const lastHealthRecord = orderedHealth.slice(-1)[0]
  const severeAnxiety = lastHealthRecord?.anxietyLevel === 'severe'
  const severeDepression = lastHealthRecord?.depressionLevel === 'severe'
  return !!(severeAnxiety || severeDepression)
}

export const getUserCritical = (userState) => calculateUserCritical(orderUserHealth(userState.health || []))

export const fetchUser = createAsyncThunk('/user/fetchUser', async () => {
  const user = await api.getUser()
  const country = await api.getCountry()
  const currentSchedule = await api.getCurrentSchedule()
  const reminderOptions = await api.getReminderOptions()
  const { surveys: userSurveys } = await api.getSurveys()
  return {
    user, currentSchedule, reminderOptions, userSurveys, country,
  }
})

export const submitSurvey = createAsyncThunk('/user/submitSurvey', async ({
  surveyName,
  questions,
  results,
}) => {
  const response = await api.submitSurvey(surveyName, questions, results)
  return response
})

export const updateUserPassword = createAsyncThunk('/user/updateUserPasswords', async ({
  userId,
  password,
}) => {
  await api.userUpdate({ password })
  return { userId, password }
})

export const updateUserServiceTypes = createAsyncThunk('/user/updateUserPasswords', async ({
  serviceTypes,
}) => {
  await api.userUpdate({ serviceTypes })
  return { serviceTypes }
})

export const updatePrivacyMarketing = createAsyncThunk('/user/updatePrivacyMarketing', async ({
  acceptedPrivacyPolicy,
  acceptedMarketingComms,
}) => {
  await api.userUpdate({
    acceptedPrivacyPolicy,
    acceptedMarketingComms,
  })
  return {
    acceptedPrivacyPolicy,
    acceptedMarketingComms,
  }
})

export const verifyUser = createAsyncThunk('/user/verifyUser', async ({ email, code }) => {
  await api.userVerifyV2(email, code)
  return { verified: true }
})

export const deleteUser = createAsyncThunk('/user/deleteUser', async ({ userId }) => {
  await api.deleteUser(userId)
  return true
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserFetchState: (state, action) => ({
      ...state,
      userFetchState: action.payload,
    }),
    updateCurrentSchedule: (state, action) => ({
      ...state,
      checkupTime: action.payload.checkupTime,
      preferredReminder: action.payload.preferredReminder,
      reminderFrequency: action.payload.reminderFrequency,
    }),
    updateUserSelectedHealthAreas: (state, action) => ({
      ...state,
      selectedHealthAreas: action.payload,
    }),
    updateVerifiedPhoneNumber: (state, action) => ({
      ...state,
      phoneNumber: action.payload,
    }),
    updateReminderOptions: (state, action) => ({
      ...state,
      reminderOptions: action.payload,
    }),
    updateUserSearchInput: (state, action) => ({
      ...state,
      userSearchInput: action.payload,
    }),
  },
  extraReducers(builder) {
    // Fetch User
    builder
      .addCase(fetchUser.pending, (state) => {
        state.userFetchState = REDUX_FETCH_STATUS.loading
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const {
          user, currentSchedule, reminderOptions, userSurveys, country,
        } = action.payload
        Object.assign(
          state,
          user,
          {
            ...currentSchedule,
            reminderOptions,
            userSurveys,
            country,
            userFetchState: REDUX_FETCH_STATUS.succeeded,
            selectedHealthAreas: user?.serviceTypes || [],
          },
        )
      })
      .addCase(fetchUser.rejected, (state, action) => {
        console.error(action.error.message)
        state.userFetchState = REDUX_FETCH_STATUS.failed
        state.error = action.error.message
      })
    // Update privacy and marketing
    builder
      .addCase(updatePrivacyMarketing.pending, (state) => {
        state.userUpdateState = REDUX_FETCH_STATUS.loading
      })
      .addCase(updatePrivacyMarketing.fulfilled, (state, action) => {
        state.userUpdateState = REDUX_FETCH_STATUS.succeeded
        state.acceptedPrivacyPolicy = action.payload.acceptedPrivacyPolicy
        state.acceptedMarketingComms = action.payload.acceptedMarketingComms
      })
      .addCase(updatePrivacyMarketing.rejected, (state, action) => {
        console.error(action.error.message)
        state.userUpdateState = REDUX_FETCH_STATUS.failed
        state.error = action.error.message
      })
    // Verify User
    builder
      .addCase(verifyUser.pending, (state) => {
        state.userUpdateState = REDUX_FETCH_STATUS.loading
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.userUpdateState = REDUX_FETCH_STATUS.succeeded
        state.verified = action.payload.verified
      })
      .addCase(verifyUser.rejected, (state, action) => {
        console.error(action.error.message)
        state.userUpdateState = REDUX_FETCH_STATUS.failed
        state.error = action.error.message
      })
    // Update User Service Types
    builder
      .addCase(updateUserServiceTypes.pending, (state) => {
        state.userUpdateState = REDUX_FETCH_STATUS.loading
      })
      .addCase(updateUserServiceTypes.fulfilled, (state, action) => {
        state.userUpdateState = REDUX_FETCH_STATUS.succeeded
        state.serviceTypes = action.payload.serviceTypes
      })
      .addCase(updateUserServiceTypes.rejected, (state, action) => {
        console.error(action.error.message)
        state.userUpdateState = REDUX_FETCH_STATUS.failed
        state.error = action.error.message
      })
  },
})

export const {
  updateUserFetchState,
  updateVerifiedPhoneNumber,
  updateCurrentSchedule,
  updateUserSelectedHealthAreas,
  updateUserSearchInput,
} = userSlice.actions

export default userSlice.reducer
