import styled from 'styled-components'
import { COLORS } from '../../constants'

export const CardDisplay = styled.div`
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 6;
  transform: translate(-50%, -50%);
`

export const FadedBackground = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 4;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
`

export const CardContainer = styled.div`
    z-index: 5;
    margin: 30px 50px 50px 20px;
    width: 440px;
    @media (max-width: 1800px) {
      margin: 140px 50px 0px 35px;
    }
    @media (max-width: 1400px) {
      margin: 10px 50px 0px 50%;
    }
    @media (max-width: 600px) {
      margin: 0px 0px 0px 18px;
      width: 90vw;
    }
`

export const GreenBlock = styled.div`
    height: 98.1px;
    border-radius: 30px 30px 0 0;
    background-color: #68db8d;
    padding: 36px;
    display: flex;
    justify-content: space-between;
`

export const GreyBlock = styled.div`
    padding: 36px;
    min-height: 346.5px;
    border-radius: 0 0 30px 30px;
    background-color: ${COLORS.lightGrey};
`

export const Logo = styled.img`
    height: 60px;
    width: 230px;
`

export const Icon = styled.img`
    width: 40px;
    height: 40px;
`

export const SubTitle = styled.div`
    width: auto;
    height: 21px;
    margin: 0 0 30px 0;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: ${COLORS.grey};
`

export const Currency = styled.span`
    width: 16px;
    height: 35px;
    margin: 21px 2px 7px 0;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.68;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.primary};
`

export const Balance = styled.p`
    width: auto;
    height: 63px;
    margin: 0 0 0 2px;
    font-family: Poppins;
    font-size: 70px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.38;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.primaryBlue};
`

export const ButtonWrapper = styled.div``

export const ContactButtonWrapper = styled.div`
    margin: 20px 0 0 0;
    width: 230px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${COLORS.grey};
    z-index: 5;
`

export const Status = styled.p`
    width: auto;
    height: auto;
    font-family: Poppins;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: ${({ isProcessing }) => (isProcessing ? COLORS.primary : COLORS.primaryRed)};
`

export const StatusInfo = styled.p`
    width: 320px;
    height: 68px;
    margin: -10px 0 -50px 0;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.black};
`

export const CardDetailContainer = styled.div`
    text-align: left;
`

export const Text = styled.p`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin: 20px 5px 5px 5px;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.grey};
`

export const GreyLine = styled.div`
    // width: 280px;
    border: 0.25px #8a96a2 solid;
`

export const Detail = styled.p`
    font-family: Poppins;
    margin: 5px;
    font-size: 14px;
    font-weight: 550;
    font-stretch: normal;
    font-style: normal;
    text-decoration: underline;
    line-height: 0.5;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.primaryBlue};
`

export const TextWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const CopyButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
`

export const AddtoWalletContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 25px;
    margin: 10px 25px 10px 0px;
`

export const AddToWallet = styled.img`
    width: 172.9px;
    height: 52.2px;
    cursor: pointer;
`
