import React, { useState } from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'

const Container = styled.div``

const TimezoneSelector = ({ onChange }) => {
  const [timezone, setTimezone] = useState(moment.tz.guess())

  const formattedTimezones = moment
    .tz.names().reduce((zones, zone) => {
      if (!zone.includes('/')) return zones
      if (zone.includes('Etc/')) return zones

      let formattedZone = `(GMT${moment.tz(zone).format('Z')}) ${zone}`
      if (formattedZone.length > 26) formattedZone = `${formattedZone.slice(0, 23)}...`

      return {
        ...zones,
        [formattedZone]: zone,
      }
    }, {})

  const handleChange = (event) => {
    setTimezone(event.target.value)
    onChange(event.target.value)
  }

  return (
    <Container>
      <Select
        labelId="tz-simple-select-label"
        id="tz-simple-select"
        variant="outlined"
        value={timezone}
        onChange={handleChange}
      >
        {Object.keys(formattedTimezones).map((zone, index) => (
          <MenuItem key={index} value={formattedTimezones[zone]}>
            {zone}
          </MenuItem>
        ))}
      </Select>
    </Container>
  )
}

TimezoneSelector.propTypes = {
  onChange: PropTypes.func,
}

export default TimezoneSelector
