import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Loading from 'fragments/Loading'
import { isIOS } from 'react-device-detect'
import device from 'device-uuid'
import { qrClick } from '../../utils'

function QrCodeTracker({
  userId,
  iOSUrl,
  androidUrl,
  title,
  resourceId,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    if (!userId) {
      setError('Missing user info')
      return
    }

    if (isIOS && !iOSUrl) {
      setError('This health service is not available on iOS')
      return
    }

    if (!isIOS && !androidUrl) {
      setError('This health service is not available on Android')
      return
    }

    const url = isIOS ? iOSUrl : androidUrl
    const identityId = new device.DeviceUUID().get()

    qrClick(userId, url, identityId, resourceId, title).then(() => {
      window.location.replace(url)
    }).catch((err) => {
      setError(JSON.stringify(err))
      setIsLoading(false)
    })
  })

  return isLoading ? <Loading /> : error
}

QrCodeTracker.propTypes = {
  userId: PropTypes.string,
  iOSUrl: PropTypes.string,
  androidUrl: PropTypes.string,
}

QrCodeTracker.defaultProps = {
  userId: '',
  iOSUrl: '',
  androidUrl: '',
}

export default QrCodeTracker
