import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Dialog, Grid,
} from '@material-ui/core'
import { BsX } from 'react-icons/bs'
import { AiFillCheckCircle } from 'react-icons/ai'
import TripettoSurvey from 'components/TripettoSurvey'
import Button from 'components/Button'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser } from 'reducers/usersReducer'
import { COLORS } from '../../constants'
import SuccessImg from '../../assets/img/Success.png'

const SurveyButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: ${COLORS.darkNavyBlue};
  border-radius: 8px;
  border: none;
  padding: 8px 12px 8px 12px;
  margin: 2px 8px 10px 0px;

  height: 33px;
  border-radius: 8px;

  cursor: pointer;
  background-color: ${({ selected }) => (selected ? COLORS.altGrey : COLORS.altLightGreen)};
  &:hover {
    background-color: ${({ hoverBackgroundColor, selected }) => (!selected ? hoverBackgroundColor : '')};
    color: ${({ hoverColor }) => hoverColor || ''};
  }
`

const SurveyButtonText = styled.div`
  font-size: 12px;
  font-family: Poppins;
  font-weight: 500;
`

const CompletedSurveyIcon = styled(AiFillCheckCircle)`
  width: 14px;
  height: 14px;
  color: #4dde7a;
  margin-right: 5px;
`

const BackgroundPopup = styled.div`
  border-radius: 8px;
  background-color: #fff;
`

const TripettoBackground = styled.div`
  overflow: scroll;
  // height: 600px;
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
`

const AssessmentCompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AssessmentCompleteText = styled.span`
  width: 314px;
  margin-top: 10px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #283873;
`

const HeaderBackground = styled.div`
  height: 30px;
  border-radius: 8px 8px 0 0;
  background-color: ${COLORS.altGrey};
  padding: 16px 24px 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const HealthAssessments = styled.span`
  margin-top: 10px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.7;
  letter-spacing: normal;
  color: #283873;
`

const Cross = styled(BsX)`
    width: 32px;
    height: 32px;
    color: ${COLORS.navy};
    &:hover {
      cursor: pointer;
    }
`

const Description = styled.div`
  width: 485px;
  height: 57px;
  margin: 24px 24px 19px 24px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.lightNavy};
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2px 24px 19px 24px;
`

const SubTitle = styled.span`
  width: 509px;
  height: 28px;
  margin: 24px 24px 19px 24px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.navy};
`

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 0 0 10px 10px;
  @media only screen and (max-width: 600px) {
    height: 100px;
  }
`

const ButtonWrapper = styled.div``

const BottomLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 24px;
  margin-top: 48px;
  margin-bottom: 21px;
`

const HealthServicesLink = styled.div`
  width: 274px;
  height: 17px;
  font-family: Poppins;
  text-decoration: underline;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #5161aa;
`

export default function AssessmentModal({
  showModal,
  setShowModal,
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const organisationHealthSurveys = useSelector((state) => state.organisation.organisationHealthSurveys)
  const userSelectedHealthAreas = useSelector((state) => state.user.selectedHealthAreas)
  const userSurveys = useSelector((state) => state.user.userSurveys)

  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const [assessmentComplete, setAssessmentComplete] = useState(null)
  const [healthAreaSurveys, setHealthAreaSurveys] = useState({})
  const [completedSurveyTypes, setCompletedSurveyTypes] = useState([])

  const closeModal = () => {
    setShowModal(false)
    setSelectedSurvey(false)
    setAssessmentComplete(false)
  }

  useEffect(() => {
    setHealthAreaSurveys(userSelectedHealthAreas.reduce((acc, healthArea) => {
      const surveys = organisationHealthSurveys.filter((survey) => survey?.serviceType?.serviceTypeName === healthArea)
      if (surveys.length === 0) return acc
      acc[healthArea] = surveys
      return acc
    }, {}))
  }, [organisationHealthSurveys, userSelectedHealthAreas])

  useEffect(() => {
    setCompletedSurveyTypes(userSurveys.map((survey) => survey.surveyType))
  }, [userSurveys])

  return (
    <Dialog
      open={showModal}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      {selectedSurvey ? (
        <BackgroundPopup>
          <HeaderBackground>
            <HealthAssessments>Health Assessments</HealthAssessments>
            <Cross onClick={closeModal} />
          </HeaderBackground>
          <TripettoBackground>
            <TripettoSurvey
              tripettoSurveys={[selectedSurvey]}
              onComplete={() => {
                setSelectedSurvey(false)
                setAssessmentComplete(true)
                dispatch(fetchUser())
                navigate('/insights')
              }}
            />
          </TripettoBackground>
        </BackgroundPopup>
      )
        : assessmentComplete ? (
          <BackgroundPopup>
            <HeaderBackground>
              <HealthAssessments>Health Assessment Completed</HealthAssessments>
              <Cross onClick={closeModal} />
            </HeaderBackground>
            <AssessmentCompleteWrapper>
              <img
                src={SuccessImg}
                alt="showing assessment success"
                style={{
                  width: '214px',
                  padding: '24px',
                }}
              />
              <AssessmentCompleteText>
                Your assessment is complete!
              </AssessmentCompleteText>
            </AssessmentCompleteWrapper>
            <BottomWrapper>
              <ButtonWrapper onClick={() => {
                setSelectedSurvey(false)
                setAssessmentComplete(false)
              }}
              >
                <Button variant="secondary">
                  Take another assessment
                </Button>
              </ButtonWrapper>
              <ButtonWrapper>
                <Link to="/insights">
                  <Button variant="primary" onClick={closeModal}>
                    View health insights first
                  </Button>
                </Link>
              </ButtonWrapper>
            </BottomWrapper>
          </BackgroundPopup>
        )
          : (
            <BackgroundPopup>
              <HeaderBackground>
                <HealthAssessments>Health Assessments</HealthAssessments>
                <Cross onClick={closeModal} />
              </HeaderBackground>

              <Description>
                Some of the health areas you have selected have self-assessments that would give you valuable health
                insights and access health service recommendations designed to your specific needs.
              </Description>

              {Object.keys(healthAreaSurveys).map((healthAreaName, idx) => (
                <div key={`healthAreaName-${idx}`}>
                  <SubTitle>{healthAreaName}</SubTitle>
                  <DescriptionWrapper>
                    {healthAreaSurveys[healthAreaName].filter((s) => s.tripettoJsonString).map((survey, idx) => (
                      <SurveyButton selected={completedSurveyTypes.includes(survey.surveyName)} onClick={() => setSelectedSurvey(survey)} key={`healthAreaName-${idx}`}>
                        <Grid item>
                          {completedSurveyTypes.includes(survey.surveyName) && (
                            <CompletedSurveyIcon />
                          )}
                        </Grid>
                        <Grid item>
                          <SurveyButtonText>{survey.surveyName}</SurveyButtonText>
                        </Grid>
                      </SurveyButton>
                    ))}
                  </DescriptionWrapper>
                </div>
              ))}
              <BottomLinkWrapper>
                <Link to="/recommendations">
                  <HealthServicesLink>
                    See health services without assessment first
                  </HealthServicesLink>
                </Link>
              </BottomLinkWrapper>
            </BackgroundPopup>
          )}
    </Dialog>
  )
}
