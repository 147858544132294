import React from 'react'
import {
  Routes, Route, BrowserRouter, Navigate,
} from 'react-router-dom'

// Components
import Layout from 'layout'

// Pages
import Profile from 'pages/Profile'
import Dashboard from 'pages/Dashboard'
import OnboardingQuestions from 'pages/OnboardingQuestions'
import ForgotPassword from 'pages/ForgotPassword'
import AppStoreRedirect from 'pages/AppStoreRedirect'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import Privacy from 'pages/Privacy'
import Terms from 'pages/Terms'
import QrCodeTracker from 'pages/QRCodeTracker'
import FollowUp from 'pages/FollowUp'
import Insights from 'pages/Insights'
import HealthEducation from 'pages/HealthEducation'
import SyndiCard from 'pages/SyndiCard'
import SignIn from 'pages/SignIn'
import SignUp from 'pages/SignUp'
import SignOut from 'pages/SignOut'
import Verification from 'pages/Verification'
import HomePage from 'pages/HomePage'
import UniDays from 'pages/UniDays'

// Utils & Config
import PrivateRoutes from 'auth'
import ResetPassword from 'pages/ResetPassword'
import config from './config'

const App = () => {
  const query = new URLSearchParams(window.location.search)

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={<Navigate to="/signin" />}
        />

        <Route
          path="/app"
          element={<Navigate to="/signup" />}
        />

        <Route
          path="/qr-code"
          element={(
            <QrCodeTracker
              iOSUrl={query.get('iOSUrl')}
              androidUrl={query.get('androidUrl')}
              userId={query.get('userId')}
              title={query.get('title')}
              resourceId={query.get('resourceId')}
            />
)}
        />

        <Route path="/privacy" element={<Layout hideNavbar><Privacy /></Layout>} />

        <Route path="/terms" element={<Layout hideNavbar><Terms /></Layout>} />

        <Route
          path="/signup"
          element={(
            <Layout hideNavbar>
              <SignUp
                refCode={query.get('refcode') || config.subdomainCodeMap[config.subDomain]}
                email={query.get('email') || ''}
              />
            </Layout>
)}
        />

        <Route
          path="/signin"
          element={
            <Layout hideNavbar><SignIn errorParam={query.get('error')} /></Layout>
}
        />

        <Route path="/forgot-password" element={<Layout hideNavbar><ForgotPassword email={query.get('email')} /></Layout>} />

        <Route path="/syndi-app" element={<Layout hideNavbar><AppStoreRedirect /></Layout>} />

        <Route path="/signout" element={<Layout><SignOut /></Layout>} />

        {/* Start Private Routes */}
        <Route path="/" element={<PrivateRoutes />}>

          <Route path="/privacy-policy" element={<Layout hideNavbar><PrivacyPolicy /></Layout>} />

          <Route path="/reset-password" element={<Layout hideNavbar><ResetPassword /></Layout>} />

          <Route path="/verification" element={<Layout hideNavbar><Verification /></Layout>} />

          <Route
            path="/unidays"
            element={(
              <Layout>
                <UniDays university={query.get('university')} />
              </Layout>
)}
          />

          <Route
            path="/home"
            element={(
              <Layout>
                <HomePage />
              </Layout>
)}
          />

          <Route
            element={(
              <Layout>
                <Profile />
              </Layout>
)}
            path="/profile"
          />

          <Route
            element={(
              <Layout>
                <Insights />
              </Layout>
)}
            path="/insights"
          />

          <Route
            element={(
              <Layout>
                <HealthEducation />
              </Layout>
)}
            path="/health-education"
          />

          <Route
            element={(
              <Layout>
                <Dashboard />
              </Layout>
)}
            path="/recommendations"
          />

          <Route
            exact
            path="/dashboard"
            element={<Navigate to="/recommendations" />}
          />

          <Route
            element={(
              <Layout>
                <SyndiCard />
              </Layout>
)}
            path="/card"
          />

          <Route
            element={(
              <Layout hideNavbar>
                <OnboardingQuestions />
              </Layout>
)}
            path="/questions"
          />

          <Route
            element={(
              <Layout>
                <FollowUp />
              </Layout>
)}
            path="/checkup"
          />

          <Route
            element={(
              <Layout>
                <FollowUp />
              </Layout>
)}
            path="/followup"
          />
        </Route>
        {/* End Private Routes */}
      </Routes>
    </BrowserRouter>
  )
}

export default App
