import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from '../../constants'

export const useStyles = makeStyles((theme) => ({
  item_header: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
    marginTop: '40px',
    marginBottom: theme.spacing(4),
  },
  verifyButton: {
    color: COLORS.veryDarkGray,
    backgroundColor: '#5fcd81',
    '&:hover': {
      backgroundColor: '#5fcd81',
    },
    minWidth: '250px',
  },
  close_modal: {
    color: COLORS.veryDarkGray,
    backgroundColor: '#5fcd81',
    minWidth: '125px',
    marginTop: theme.spacing(3),
  },
  labelCloseModal: {
    fontSize: '15px',
    fontWeight: 'bold',
    textTransform: 'none',
    fontFamily: 'Poppins',
    color: '#383838',
  },
  skipButton: {
    color: COLORS.veryDarkGray,
    minWidth: '250px',
  },
  labelVerifyButton: {
    fontSize: '15px',
    fontWeight: 'bold',
    textTransform: 'none',
    fontFamily: 'Poppins',
    color: '#383838',
  },
  skipSecondaryButton: {

  },
  skipPrimaryButton: {
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    color: '#383838',
    fontSize: '15px',
    textTransform: 'none',
  },
  hideItem: {
    display: 'none',
  },
  errorMessageContainer: {
    backgroundColor: '#ffeded',
    margin: theme.spacing(1.5),
  },
}))

export const FormSuccess = styled.div`
  margin: 10px 0;
  color: ${COLORS.primary};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 25px;
`
