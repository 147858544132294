import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Dialog, DialogContent, Typography, Container, Grid, IconButton,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import { updateConditionFilter, updateFunctionalityFilter } from 'reducers/filtersReducer'
import { COLORS } from '../../constants'

const FilterOptionButton = styled.button`
  color: ${COLORS.darkNavyBlue};
  border-radius: 8px;
  font-size: 12px;
  font-family: Poppins;
  background-color: ${({ selected }) => (selected ? COLORS.altLightGreen : COLORS.altGrey)};
  font-weight: 500;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ hoverBackgroundColor, selected }) => (!selected ? hoverBackgroundColor : '')};
    color: ${({ hoverColor }) => hoverColor || ''};
  }
`

const ApplyFilterButton = styled.button`
  color: white;
  background-color: ${COLORS.darkNavyBlue};
  border-radius: 16px;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor || ''};
    color: ${({ hoverColor }) => hoverColor || ''};
  }
`

const ClearButton = styled.button`
  color: ${COLORS.darkNavyBlue};
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 12px;
  margin-left: 20px;
  font-family: Poppins;
  font-weight: 500;
  border: none;
  cursor: pointer;
`

export default function FilterDialog({
  openDialog,
  onCloseDialog,
  filterType,
  recommendationsForTab,
}) {
  const dispatch = useDispatch()
  const [selectedOptions, setSelectedOptions] = useState([])

  // Filter State
  const recommendationType = useSelector((state) => state.recommendations.recommendationType)
  const functionalityFilter = useSelector((state) => state.filters.functionalityFilter)
  const conditionFilter = useSelector((state) => state.filters.conditionFilter)
  const [filterConfigurationOptions, setFilterConfigurationOptions] = useState({})
  const [availableOptionsForTab, setAvailableOptionsForTab] = useState([])

  const buildSelectedOptions = () => {
    switch (filterType) {
      case 'condition':
        setFilterConfigurationOptions({ propertyName: 'conditions', variableName: 'conditionName' })
        return conditionFilter[recommendationType]
      case 'functionality':
        setFilterConfigurationOptions({ propertyName: 'functionalities', variableName: 'functionalityName' })
        return functionalityFilter[recommendationType]
      default:
        throw new Error('Invalid filter type')
    }
  }

  const getFilterOptionsForTab = (recommendations) => {
    const uniqueFilterOptions = []

    recommendations?.forEach((recommendation) => {
      const options = recommendation[filterConfigurationOptions.propertyName]
      options?.forEach((option) => {
        const optionName = option[filterConfigurationOptions.variableName]
        if (optionName !== undefined && !uniqueFilterOptions.includes(optionName)) {
          uniqueFilterOptions.push(optionName)
        }
      })
    })
    setAvailableOptionsForTab(uniqueFilterOptions)
  }

  useEffect(() => {
    getFilterOptionsForTab(recommendationsForTab)
  }, [recommendationsForTab])

  useEffect(() => {
    const options = buildSelectedOptions() || []
    setSelectedOptions(options)
  }, [recommendationType, filterType, onCloseDialog])

  const onApplyFilter = () => {
    switch (filterType) {
      case 'condition':
        dispatch(updateConditionFilter({
          [recommendationType]: selectedOptions,
        }))
        break
      case 'functionality':
        dispatch(updateFunctionalityFilter({
          [recommendationType]: selectedOptions,
        }))
        break
      default: throw new Error('Invalid filter type')
    }
    onCloseDialog()
  }

  const handleOptionClick = (optionName) => {
    if (selectedOptions.includes(optionName)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== optionName))
    } else {
      setSelectedOptions([...selectedOptions, optionName])
    }
  }

  return (
    <Dialog open={openDialog} maxWidth="xs" onClose={onCloseDialog}>
      <DialogContent>
        <Container maxWidth="xs">
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                style={{
                  fontSize: '0.99rem',
                  fontFamily: 'Poppins',
                  fontWeight: 'bold',
                  color: COLORS.darkNavyBlue,
                }}
              >
                Filter by &nbsp;
                { filterType }
              </Typography>
            </Grid>
            <IconButton
              style={{
                position: 'absolute',
                right: '0px',
                top: '0px',
              }}
              onClick={onCloseDialog}
            >
              <CloseIcon />
            </IconButton>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  fontSize: '12px',
                  fontFamily: 'Poppins',
                  fontWeight: 'normal',
                  color: COLORS.darkNavyBlue,
                }}
              >
                <ApplyFilterButton
                  onClick={onApplyFilter}
                  hoverBackgroundColor={COLORS.primaryGreen}
                  hoverColor={COLORS.navy}
                  style={{ marginRight: '20px' }}
                >
                  Apply Filters
                </ApplyFilterButton>
                {selectedOptions.length}
                &nbsp; selected
                {selectedOptions.length > 0
                && (
                <ClearButton onClick={() => setSelectedOptions([])}>
                  X Clear All
                </ClearButton>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {availableOptionsForTab.map((option, index) => (
                <FilterOptionButton
                  onClick={() => handleOptionClick(option)}
                  key={index}
                  selected={selectedOptions.includes(option)}
                  hoverBackgroundColor={COLORS.darkerGrey}
                >
                  {option}
                </FilterOptionButton>
              ))}
            </Grid>
            <Grid item style={{ margin: '0 auto' }}>
              <ApplyFilterButton
                onClick={onApplyFilter}
                hoverBackgroundColor={COLORS.primaryGreen}
                hoverColor={COLORS.navy}
              >
                Apply Filters
              </ApplyFilterButton>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

FilterDialog.propTypes = {
  filterType: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  recommendationsForTab: PropTypes.array.isRequired,

}
