import styled from 'styled-components'
import { BsCheckCircle } from 'react-icons/bs'
import { COLORS } from '../../constants'

export const ModalContainer = styled.div`
    width: 371px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    background-color: ${COLORS.darkBlue};
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    // mobile
    @media only screen and (max-width: 600px) {
        max-width: 300px;
        height: auto;
    }
`

export const HeaderWrapper = styled.div`
    border-radius: 10px 10px 0 0;
    background-color: ${COLORS.primaryBlue};
    margin-top: -12px;
    text-align: center;
    margin-bottom: -12px;

    // mobile
    @media only screen and (max-width: 600px) {
        width: auto;
    }
`

export const Header = styled.h1`
    height: 20px;
    padding: 25px 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.white};

    // mobile
    @media only screen and (max-width: 600px) {
        width: auto;
        font-size: 14px;
    }
`

export const CompleteSubtitle = styled.h2`
    width: auto;
    height: auto;
    padding: 0px 40px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.white};

    // mobile
    @media only screen and (max-width: 600px) {
        padding: 0px 30px 0px 30px;
    }
`

export const Body = styled.div`
    display: flex;
    flex-flow: column;
    margin: 0 0 0 33px;

    // mobile
    @media only screen and (max-width: 600px) {
        padding: 0;
        margin: 0 0 0 30px;
    }
`

export const OfferContainer = styled.div`
    display: flex;
    margin-left: 10px;
    margin-top: 25px;

    // mobile
    @media only screen and (max-width: 600px) {
        margin-left: 25px;
    }
`

export const CardImage = styled.img`
    width: 140px;
    height: 150px;

    // mobile
    @media only screen and (max-width: 600px) {
        width: 25%;
        height: 25%;
    }
`

export const TextWrapper = styled.div`
    margin: 15px 0 0 15px;
    display: flex;
    width: 280px;

    // mobile
    @media only screen and (max-width: 600px) {
        margin: 5px 0 0 -50px;
    }
`

export const OfferText = styled.h2`
    width: 189px;
    height: 67px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.white};

    // mobile
    @media only screen and (max-width: 600px) {
        width: 150px;
        font-size: 10px;
        margin: 0 0 0 70px;
    }
`

export const CompleteText = styled.h2`
    width: 311px;
    height: 20px;
    padding: 15px 20px 25px 40px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.white};

    // mobile
    @media only screen and (max-width: 600px) {
        width: auto;
        font-size: 15px;
        padding: 10px 20px 25px 30px;
    }
`

export const DescriptionWrapper = styled.div`
    display: flex;
    // flex-flow: column;
    height: auto;
    margin-top: 10px;

    // mobile
    @media only screen and (max-width: 600px) {
        width: 250px;
    }
`

export const SubTitle = styled.h3`
    width: 220px;
    height: 15px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.primary};
`

export const Text = styled.p`
    width: 294px;
    height: auto;
    margin: 8px 0px 8px 0px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.white};

    // mobile
    @media only screen and (max-width: 600px) {
        width: 250px;
        height: auto;
        font-size: 10px;
    }
`

export const RegisterButton = styled.button`
    width: 284.6px;
    height: 50.8px;
    margin: 50px 0 0 5px;
    border-radius: 25.4px;
    border: transparent;
    background-color: ${COLORS.primaryGreen};
    color: ${COLORS.navy};
    cursor: pointer;
    &:hover {
      background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor || ''};
      color: ${({ hoverColor }) => hoverColor || ''};
    }

    // mobile
    @media only screen and (max-width: 600px) {
        width: 220px;
        height: 20px;
        padding: 20px;
    }
`

export const CloseButton = styled(RegisterButton)`
    margin: 20px 0;

    // mobile
    @media only screen and (max-width: 600px) {
      margin: 20px 0 30px 10px;
    }
`

export const ButtonLabel = styled.p`
    font-family: Poppins;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;

    // mobile
    @media only screen and (max-width: 600px) {
        margin-top: -6px;
    }
`

export const GreenBoldText = styled.b`
    color: ${COLORS.primary};
`

export const Tick = styled(BsCheckCircle)`
    width: 14px;
    height: 14px;
    margin: 10px 10px 0 0;
    color: ${COLORS.primary};
`

export const PrivacyPolicy = styled.span`
    color: ${COLORS.primary};
    font-size: 13px;
    cursor: pointer;
`

export const Checkbox = styled.div`
    margin: -2px -15px 0 -15px;

    // mobile
    @media only screen and (max-width: 600px) {
        margin: 0 -15px 0 52px;
    }
`

export const ErrorMessage = styled.p`
    font-size: 13px;
    color: ${COLORS.primaryRed};
    text-align: center;
    width: 260px;
    margin: 20px 0 -15px 20px;

    // mobile
    @media only screen and (max-width: 600px) {
      margin: 0;
  }
  `

export const CheckboxWrapper = styled.div`
    margin-top: 20px;
`

export const VerifyDetailsContainer = styled.div`
    display: flex;
    margin-top: 10px;
    flex-flow: column;
`

export const VerifyDetailsText = styled.p`
    width: 294px;
    height: auto;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.white};

    // mobile
    @media only screen and (max-width: 600px) {
        width: 250px;
        height: auto;
        font-size: 10px;
    }
`
