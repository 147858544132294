import React from 'react'
import styled from 'styled-components'
import { AiOutlineMessage, AiOutlineBank } from 'react-icons/ai'
import { FaRegCompass } from 'react-icons/fa'
import MuiIconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { ChevronRight, ChevronLeft } from '@material-ui/icons'
import config from 'config'
import { COLORS } from '../../constants'

export const CarouselButton = styled.button`
  background-color: ${({ selected }) => (selected ? COLORS.white : '#edeff3')};
  border: ${({ selected }) => (selected ? `2px solid ${COLORS.darkNavyBlue}` : 'none')};
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.darkNavyBlue};
  cursor: pointer;
  &:hover {
    background-color: ${({ hoverBackgroundColor, selected }) => (!selected ? hoverBackgroundColor : '')};
    color: ${({ hoverColor }) => hoverColor || ''};
  }
`

export const IconButton = withStyles({
  root: {
    borderRadius: '20%',
    padding: '0',
    height: '15px',
  },
})(({ children, ...rest }) => <MuiIconButton {...rest}>{children}</MuiIconButton>)

export const MoreAppsIcon = withStyles({
  root: {
    borderRadius: '10px',
    color: COLORS.grey,
    fontSize: '2rem',
  },
})((props) => <ChevronRight {...props} fontSize="large" />)

export const LessAppsIcon = withStyles({
  root: {
    borderRadius: '10px',
    color: COLORS.grey,
    fontSize: '2rem',
  },
})((props) => <ChevronLeft {...props} fontSize="large" />)

export const MoreAppsTitle = styled.p`
  font-size: 14px;
  color: ${COLORS.grey};
  font-weight: bold;
  line-height: 36px;
 `
export const MoreApps = styled.div`
  display: contents;
`

export const IconCarousel = styled.div`
  display: flex;
  flex-flow: row;
  margin: 10px 0 50px 0;
  width: 100%;
  justify-content: space-between;
`

export const AppWrapper = styled.div`
  color: ${COLORS.grey};
  opacity: ${({ selected }) => (selected ? 1 : 0.3)};
`

export const AppIcons = styled.img`
  width: ${({ selected }) => (selected ? '125px' : '70px')};
  height: ${({ selected }) => (selected ? '125px' : '70px')};
  margin-right: 20px;
  border-radius: 20px;
  box-shadow: ${({ selected }) => (selected ? '0 3px 6px 0 rgba(0, 0, 0, 0.16)' : '0')};
`

export const AppName = styled.p`
  font-size: ${({ selected }) => (selected ? '17px' : '12px')};
  font-weight: ${({ selected }) => (selected ? 600 : 'normal')};
  color: ${COLORS.black};
  text-align: left;
  margin: 0 0 20px 0;
  padding: ${({ selected }) => (selected ? '15px 0' : '0 0 20px 0')};
  position: absolute;
  max-width: ${({ selected }) => (selected ? null : '70px')};
`

export const TabIconSG = styled(FaRegCompass)`
  position: absolute;
  top: 25px;
  left: 0px;
`

export const TabIconPG = styled(AiOutlineMessage)`
  position: absolute;
  top: 25px;
  left: 0px;
`

export const TabIconOG = styled(AiOutlineBank)`
  position: absolute;
  top: 25px;
  left: 0px;
`

export const TabTitle = styled.b`
`

export const RecommendationsContainer = styled.div`
  min-height: 400px;
`

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const Tab = styled.button`
  background-color: ${({ selected, value }) => {
    if (value.toLowerCase() === config.companyFundedHealthAreaName) return (selected ? COLORS.white : COLORS.altLightGreen)
    return (selected ? COLORS.white : '#edeff3')
  }};
  border: ${({ selected, value }) => {
    if (value.toLowerCase() === config.companyFundedHealthAreaName) return (selected ? `2px solid ${COLORS.primaryGreen}` : 'none')
    return (selected ? `2px solid ${COLORS.darkNavyBlue}` : 'none')
  }};
  height: 49px;
  padding: 8px 16px;
  border-radius: 8px;
  margin-right: 5px;
  margin-top: 7px;
  font-size: 14px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.darkNavyBlue};
  cursor: pointer;
  &:hover {
    background-color: ${({ hoverBackgroundColor, selected, value }) => {
    if (value?.toLowerCase() === config.companyFundedHealthAreaName) return hoverBackgroundColor
    return (!selected ? hoverBackgroundColor : '')
  }};
    color: ${({ hoverColor }) => hoverColor || ''};
  }
`

export const RequestNewServiceButton = styled.button`
  width: 157px;
  height: 33px;
  padding: 8px 24px;
  border-radius: 16.5px;
  background-color: ${COLORS.darkNavyBlue};
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  border: none;
  margin-left: 30px;
  @media screen and (max-width: 600px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
  cursor: pointer;
  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor || ''};
    color: ${({ hoverColor }) => hoverColor || ''};
  }
`
