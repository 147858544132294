import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../constants'

const SkipButton = styled.button`
    width: ${(props) => props.width || 'auto'};
    height: 21px;
    background-color: transparent;
    border: none;
    margin: ${(props) => props.margin || '20px 19px 20px 0'};
    font-family: Poppins;
    font-size:  ${(props) => props.size || '15px'};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: ${(props) => props.align || 'center'};
    color:  ${(props) => props.colour || COLORS.primaryBlue};
    cursor: pointer;
`

export default function ButtonSkip(
  {
    onClick,
    children,
    colour,
    size,
    width,
    margin,
    align,
  },
) {
  return (
    <SkipButton
      onClick={onClick}
      colour={colour}
      size={size}
      width={width}
      margin={margin}
      align={align}
    >
      {children}
    </SkipButton>
  )
}
