/**
 * Global Config
 */

if (!process.env.REACT_APP_API_DOMAIN) throw new Error('Missing API Domain')
if (!process.env.REACT_APP_WEB_DOMAIN) throw new Error('Missing Web Domain')

const config = {
  // Domains
  domains: {
    api: process.env.REACT_APP_API_DOMAIN,
    web: window.location.hostname + (window.location.port ? `:${window.location.port}` : ''),
  },

  // Stripe Public Key
  subDomain: window.location.hostname.split('.')[0].replace('-staging', ''),
  env: process.env.REACT_APP_ENV,

  // Landing Page Config
  landingPageBaseUrl: process.env.REACT_APP_CUSTOM_LANDING_PAGE_BASE_URL,

  // Company funded health area name
  companyFundedHealthAreaName: 'paid by your company',

  // Preset Referral Codes
  subdomainCodeMap: {
    health: 'uni224',
    vitality: 'vi21',
    onevoice: 'ov21',
    foundersfactory: 'FF22',
    workspace: 'Work21Pav',
    demo: 'demo22',
    studentbeans: 'sb2022',
    familylowdown: 'fl2022',
    fuhzz: 'fuhzz2022',
    savethestudent: 'sts2022',
    axa: 'axa2022',
  },

  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    client: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: 'read:user',
  },
}

export default config
