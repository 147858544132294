import { makeStyles, createTheme } from '@material-ui/core/styles'
import { COLORS } from '../../constants'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  errorBorder: {
    border: 'solid 1px #d60505',
  },
  errorMessageContainer: {
    backgroundColor: '#ffeded',
    maxWidth: '330px',
    margin: '21px 0 11px',
  },
  hideItem: {
    visibility: 'hidden',
  },
  mainButton: {
    width: '230px',
    borderRadius: '22px',
    backgroundColor: '#66f091',
  },
  mainLabelButton: {
    fontSize: '20px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    color: COLORS.darkNavyBlue,
    textTransform: 'none',
    fontFamily: 'Poppins',
  },
  clinicalIcon: {
    width: '45px',
    height: '49x',
  },
  recommendIcon: {
    width: '45px',
    height: '36px',
    paddingTop: '18px',
  },
  analyiticsIcon: {
    width: '45px',
    height: '37px',
    paddingTop: '52px',
  },
  contentText: {
    marginRight: '-50px',
  },
}))

export const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        color: '#292929',
        borderRadius: '8px',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#5fcd81',
        },
      },
      containedPrimary: {
        height: '47px',
        color: '#292929',
        backgroundColor: '#5fcd81',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#5fcd81',
          '@media (hover: none)': {
            backgroundColor: '#5fcd81',
          },
          boxShadow: 'none',
        },
      },
    },
  },
})
