import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid, Container, ThemeProvider,
} from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import NextStep from 'components/NextStep'
import SearchArea from 'components/SearchArea'
import SyndiCardHome from 'components/SyndiCardHome'
import styled from 'styled-components'
import { fetchUser, getUserCritical } from 'reducers/usersReducer'
import { fetchCard } from 'reducers/cardReducer'
import { fetchOrganisation } from 'reducers/organisationReducer'
import { fetchRecommendations, fetchCrisisContent } from 'reducers/recommendationsReducer'
import Loading from 'fragments/Loading/Loading'
import Support from '../../components/Support'
import { REDUX_FETCH_STATUS, FEATURE_FLAGS } from '../../constants'
import style from './HomePage.style'

const SectionBlock = styled.div`
  background: #f7fbfe;
  padding: 30px;
  @media only screen and (max-width: 600px) {
    padding: 5px;
  }
`

const theme = createTheme(style)

export default function HomePage() {
  const dispatch = useDispatch()

  // Global State Selectors
  // User State
  const userFetchState = useSelector((state) => state.user.userFetchState)
  const user = useSelector((state) => state.user)

  // Org State
  const organisationFetchState = useSelector((state) => state.organisation.organisationFetchState)
  const stripeConnectId = useSelector((state) => state.organisation.stripeConnectId)
  const featureFlags = useSelector((state) => state.organisation.featureFlags)

  // Card State
  const cardFetchState = useSelector((state) => state.card.cardFetchState)
  const cardNumber = useSelector((state) => state.card.number)
  const cardCvc = useSelector((state) => state.card.cvc)
  const cardBalance = useSelector((state) => state.card.cardBalance)
  const cardExpiryMonth = useSelector((state) => state.card.exp_month)
  const cardExpiryYear = useSelector((state) => state.card.exp_year)
  const cardHolderName = useSelector((state) => state.card.cardholder?.name)

  // Recommendations State
  const recommendationsDataFetchStatus = useSelector((state) => state.recommendations.recommendationsDataFetchStatus)
  const crisisContentFetchStatus = useSelector((state) => state.recommendations.crisisContentFetchStatus)
  const crisisContent = useSelector((state) => state.recommendations.crisisContent)

  // Component State
  const [openSos, setOpenSos] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (recommendationsDataFetchStatus === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchRecommendations())
    }
    if (userFetchState === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchUser())
    }
    if (organisationFetchState === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchOrganisation())
    }
    if (crisisContentFetchStatus === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchCrisisContent())
    }
    if (cardFetchState === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchCard())
    }
    if (userFetchState === REDUX_FETCH_STATUS.succeeded
      && organisationFetchState === REDUX_FETCH_STATUS.succeeded
    ) {
      getUserCritical(user)
      setLoaded(true)
    }
  }, [userFetchState, organisationFetchState, crisisContentFetchStatus, cardFetchState])

  if (!loaded) return <Loading />

  return (
    <>
      <Container maxWidth="md" style={{ marginTop: '41px' }}>
        <Grid
          id="wrapper_dashboard"
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <ThemeProvider theme={theme}>
            {featureFlags?.includes(FEATURE_FLAGS.searchFeature) ? (
              <SearchArea /> // === FEATURE FLAG: Search Feature ===
            ) : (
              <Grid item xs={12}>
                <NextStep includeButton />
              </Grid>
            )}
          </ThemeProvider>
        </Grid>
      </Container>

      {stripeConnectId && (
        <>
          <Container maxWidth="xl" disableGutters>
            <ThemeProvider theme={theme}>
              <>
                <SectionBlock>
                  <Container maxWidth="md">
                    <Grid item xs={12}>
                      <SyndiCardHome
                        cardNumber={cardNumber}
                        cardBalance={cardBalance}
                        cardCvc={cardCvc}
                        cardHolderName={cardHolderName}
                        cardExpiryMonth={cardExpiryMonth}
                        cardExpiryYear={cardExpiryYear}
                      />
                    </Grid>
                  </Container>
                </SectionBlock>
              </>
            </ThemeProvider>
          </Container>
        </>
      )}
      <Container maxWidth="md">
        <ThemeProvider theme={theme}>
          {crisisContent?.length > 0 && (
            <Support
              open={openSos}
              setOpen={setOpenSos}
              content={crisisContent}
            />
          )}
        </ThemeProvider>
      </Container>
    </>
  )
}
