import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserSelectedHealthAreas, updateUserServiceTypes } from 'reducers/usersReducer'
import { Grid } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import AssessmentModal from 'components/AssessmentModal'
import config from 'config'
import Loading from 'fragments/Loading'
import { COLORS } from '../../constants'

const Title = styled.h1`
    width: auto;
    height: 46px;
    margin: 0 0 10px 0;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.darkNavyBlue};

    // mobile
    @media only screen and (max-width: 600px) {
      margin: 0px 0 50px 0;
    }
`

const TextWrapper = styled.div`
    display: flex;
    flex-flow: column;
    width: 550px;

    // mobile
    @media only screen and (max-width: 600px) {
      padding: 20px 10px 0 10px;
      width: 100%;
    }
`

const Header = styled.h2`
    width: auto;
    height: auto;
    margin: 25px 0 10px 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.darkNavyBlue};


    // mobile
    @media only screen and (max-width: 600px) {
      margin: 12px 0 30px 0;
    }
`

const HealthAreaButton = styled.button`
  display: flex;
  height: 115px;
  align-items: center;
  background-color: #edeff4;
  padding: 16px 131px 19px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.darkerGrey};
  }

  // mobile
  @media only screen and (max-width: 600px) {
    padding: 0;
  }

`

const HealthAreaButtonText = styled.p`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.darkNavyBlue};

  // mobile
  @media only screen and (max-width: 600px) {
    font-size: 12px;
    margin: 0 15px 0 15px;
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export default function NextStep({ includeButton = false }) {
  const dispatch = useDispatch()
  const organisationHealthAreas = useSelector((state) => state.organisation.organisationHealthAreas)
  const userSelectedHealthAreas = useSelector((state) => state.user.selectedHealthAreas)
  const recommendationsData = useSelector((state) => state.recommendations.recommendationsData)
  const [showAssessmentModal, setShowAssessmentModal] = useState(false)
  const [tabNames, setTabs] = useState([])

  const handlehealthAreaClick = (healthArea) => {
    if (userSelectedHealthAreas.includes(healthArea)) {
      dispatch(updateUserSelectedHealthAreas(userSelectedHealthAreas.filter((item) => item !== healthArea)))
    } else {
      dispatch(updateUserSelectedHealthAreas([...userSelectedHealthAreas, healthArea]))
    }
  }

  const handleNextStepClick = async () => {
    const areasWithSurveys = Object.keys(organisationHealthAreas)
      .filter((area) => userSelectedHealthAreas.includes(area)).length > 0

    await dispatch(updateUserServiceTypes({ serviceTypes: userSelectedHealthAreas }))

    if (areasWithSurveys) setShowAssessmentModal(true)
  }

  useEffect(() => {
    const tabNames = Object.keys(recommendationsData).filter((t) => {
      if (recommendationsData[t]?.length === 0) return false
      if (t.toLowerCase() === config.companyFundedHealthAreaName) return false
      return true
    })
    setTabs(tabNames)
  }, [recommendationsData])

  if (!tabNames.length) return <LoadingWrapper><Loading /></LoadingWrapper>

  return (
    <>
      <TextWrapper>
        <Title>Discover the right health services to support your journey</Title>
      </TextWrapper>
      <TextWrapper>
        <Header>Select the areas you are interested in working on:</Header>
      </TextWrapper>
      <Grid container spacing={2} justifyContent={isMobile ? 'center' : 'flex-start'} style={{ width: '100%', margin: isMobile ? '0' : '0, auto' }}>
        {tabNames.map((healthArea) => (
          <Grid item xs={6} sm={4} key={healthArea}>
            <HealthAreaButton
              type="button"
              style={{
                backgroundColor: userSelectedHealthAreas.includes(healthArea) ? '#ffffff' : '#edeff4',
                width: '100%',
                border: userSelectedHealthAreas.includes(healthArea) ? `1px solid ${COLORS.darkNavyBlue}` : 'none',
              }}
              onClick={() => {
                handlehealthAreaClick(healthArea)
                window.hj('event', 'Health area button clicked')
              }}
            >
              <HealthAreaButtonText>{healthArea.charAt(0).toUpperCase() + healthArea.slice(1).replace(/([A-Z])/g, ' $1').trim()}</HealthAreaButtonText>
            </HealthAreaButton>
          </Grid>
        ))}
      </Grid>
      <TextWrapper>
        <Header>
          {userSelectedHealthAreas.filter((ha) => tabNames.includes(ha)).length}
          &nbsp;selected
        </Header>
      </TextWrapper>
      {includeButton && (
        <>
          {userSelectedHealthAreas.length === 0 && (
            <Button variant="primary" disabled>Next Step</Button>
          )}

          {(userSelectedHealthAreas.length > 0
          && !Object.keys(organisationHealthAreas).filter((area) => userSelectedHealthAreas.includes(area)).length > 0) && (
            <Link to="/recommendations">
              <Button
                variant="primary"
                onClick={handleNextStepClick}
              >
                Next Step
              </Button>
            </Link>
          )}

          {(userSelectedHealthAreas.length > 0
          && Object.keys(organisationHealthAreas).filter((area) => userSelectedHealthAreas.includes(area)).length > 0) && (
            <Button
              onClick={handleNextStepClick}
              variant="primary"
            >
              Next Step
            </Button>
          )}
        </>
      )}
      <br />
      <br />
      <AssessmentModal showModal={showAssessmentModal} setShowModal={setShowAssessmentModal} />
    </>
  )
}
