import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { updateCostFilterAmount } from 'reducers/filtersReducer'
import { LabelXsMedium, LabelXXS } from 'components/Typography'
import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'
import { COLORS } from 'constants'

// Create custom styles
const useStyles = makeStyles({
  thumb: {
    color: COLORS.darkNavyBlue,
    marginTop: -2,
  },
  track: {
    color: COLORS.primaryGreen,
    height: 8,
    borderRadius: '4px',
  },
  rail: {
    color: COLORS.primaryGreen,
    height: 8,
    borderRadius: '4px',
  },
})

export default function FilterServiceCost() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const costFilterAmount = useSelector((state) => state.filters.costFilterAmount)
  const [sliderValue, setSliderValue] = useState(250)

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ minWidth: '250px', marginTop: '6px', marginLeft: '24px' }}
    >
      <Grid item xs={2}>
        <LabelXsMedium>
          Price:
        </LabelXsMedium>
      </Grid>
      <Grid item xs={2}>
        <LabelXXS>FREE</LabelXXS>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          paddingRight: '12px',
          paddingLeft: '0px',
        }}
      >
        <Slider
          classes={{
            thumb: classes.thumb,
            track: classes.track,
            rail: classes.rail,
          }}
          min={0}
          max={500}
          value={sliderValue}
          onChange={(_evt, newv) => {
            dispatch(updateCostFilterAmount(newv))
            setSliderValue(newv)
          }}
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        <LabelXXS>
          {costFilterAmount ? `£${costFilterAmount}` : costFilterAmount === null ? 'Any' : 'FREE'}
        </LabelXXS>
      </Grid>
    </Grid>
  )
}
