import styled from 'styled-components'
import { COLORS } from '../../constants'

export const Header = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.34px;
  color: ${COLORS.Welcome};
  padding: 8px 0;
`

export const SubHeader = styled.div`
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: 0.28px;
  color: ${COLORS.veryDarkGray};
  padding: 8px 0;
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.25px;
  padding: 8px 0;
  color: ${({ error }) => (error ? COLORS.red : '#4e4e4e')};
`

export const SubText = styled.p`
  font-size: 12px;
  text-align: left;
  margin: 0 0 0 0;
`

export const List = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
`
