import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Link } from 'react-router-dom'
import { COLORS } from 'constants'
import styles from './CompletingAssessment.module.css'

export default function Loading() {
  return (
    <div>
      <div className={`${styles.container}`}>

        <CheckCircleIcon style={{ color: COLORS.primary, width: '50px', height: '50px' }} />

        <h1>Thank you!</h1>

        <p>Redirecting you to the self-assessment...</p>

        <Link to="/questions"><u>click here if it doesn&apos;t take you automatically</u></Link>

      </div>
    </div>
  )
}
