import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import EyeOffSvg from '../../assets/img/eye-off.svg'

const EyeOff = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 0 6px 0;
`

export default function NavbarAnonymity() {
  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      style={{
        border: '1px solid #40CE6D',
        borderRadius: '8px',
        fontSize: '12px',
        color: '#40CE6D',
        fontWeight: '400',
        margin: '16px 0px',
        padding: '12px',
      }}
    >
      <EyeOff src={EyeOffSvg} />
      Your data is safe with us! Syndi is entirely anonymous and confidential, no one in your organisation can see what you&apos;re doing!
    </Grid>
  )
}
