import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiTypography from '@material-ui/core/Typography'
import { COLORS } from '../../constants'

const Typography = withStyles({
  root: {
    zIndex: 0,
    fontFamily: 'Poppins',
    textTransform: 'none',
  },
})(({ children, ...props }) => <MuiTypography {...props}>{children}</MuiTypography>)

export const Title = withStyles({
  root: {
    color: COLORS.darkNavyBlue,
    textTransform: 'none',
    fontWeight: 'bold',
    lineHeight: '1.33',
    fontSize: '28px',
  },
})(({ children, ...props }) => <Typography align="left" {...props}>{children}</Typography>)

export const SubTitle = withStyles({
  root: {
    color: COLORS.darkNavyBlue,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: 1.42,
    textAlign: 'left',
  },
})(({ children, ...props }) => <Typography {...props}>{children}</Typography>)

export const ServiceTypeHeader = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: COLORS.textColor,
    textAlign: 'left',
  },
})(({ children, ...props }) => <Typography {...props}>{children}</Typography>)

export const DescriptionText = withStyles({
  root: {
    fontSize: '12.5px',
    lineHeight: 1.38,
    color: COLORS.darkNavyBlue,
    textAlign: 'left',
  },
})(({ children, ...props }) => <Typography {...props}>{children}</Typography>)

export const ButtonLabel = withStyles({
  root: {
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: 1.21,
    color: '#2f455c',
  },
})(({ children, ...props }) => <Typography {...props}>{children}</Typography>)

export const Header = withStyles({
  root: {
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: 1.33,
    color: COLORS.primary,
  },
})(({ children, ...props }) => <Typography {...props}>{children}</Typography>)

export const SubHeader = withStyles({
  root: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: 2.67,
    color: COLORS.darkNavyBlue,
  },
})(({ children, ...props }) => <Typography {...props}>{children}</Typography>)

export const HelperText = withStyles({
  root: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: 2.1,
    color: COLORS.grey,
  },
})(({ children, ...props }) => <Typography {...props}>{children}</Typography>)

export const ErrorMessage = withStyles({
  root: {
    textAlign: 'left',
    fontFamily: 'Poppins',
    color: '#d60505',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: 1.36,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    fontWeight: 400,
  },
})(({ children, ...rest }) => <Typography align="center" {...rest}>{children}</Typography>)

export const Content = withStyles({
  root: {
    fontFamily: 'Poppins',
    color: COLORS.darkNavyBlue,
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: 1.47,
  },
})(({ children, ...rest }) => <Typography {...rest}>{children}</Typography>)

export const StyledControlLabel = withStyles({
  root: {
    fontFamily: 'Poppins',
    textTransform: 'none',
    color: COLORS.darkNavyBlue,
    fontSize: '12px',
  },
})(({ children, ...rest }) => (
  <Typography {...rest}>
    {children}
  </Typography>
))

export const BodySmallSemibold = withStyles({
  root: {
    fontFamily: 'Poppins',
    color: COLORS.white,
    fontSize: '14px',
    lineHeight: '20px',
  },
})(({ children, ...rest }) => <Typography {...rest}>{children}</Typography>)

export const LabelXsMedium = withStyles({
  root: {
    fontFamily: 'Poppins',
    color: COLORS.darkNavyBlue,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 500,
  },
})(({ children, ...rest }) => <Typography {...rest}>{children}</Typography>)

export const LabelXXS = withStyles({
  root: {
    fontFamily: 'Poppins',
    color: COLORS.darkNavyBlue,
    fontSize: '10px',
    lineHeight: '10px',
    fontWeight: 600,
  },
})(({ children, ...rest }) => <Typography {...rest}>{children}</Typography>)
