import React, { useState } from 'react'
import { CircularProgress, Dialog } from '@material-ui/core'
import ButtonSkip from 'components/ButtonSkip'
import TextField from 'components/TextField'
import moment from 'moment'
import VerifyDialog from 'components/VerifyDialog'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { createUserCard } from 'reducers/cardReducer'
import MockPhone from '../../assets/img/SyndiPay/SyndiPayPhoneCard.png'
import { COLORS } from '../../constants'
import * as style from './CardRegisterModal.style'

export default function CardRegisterModal({ onRegisterComplete, userEmail, userPhoneNumber }) {
  const dispatch = useDispatch()

  // Local State
  const [registrationStep, setRegistrationStep] = useState('activateCard')
  const [submitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState({ errorText: '', error: false })
  const [openDialogVerify, setOpenDialogVerify] = useState(true)
  const [stringDob, setStringDob] = useState('')
  const [dobValidationError, setDobValidationError] = useState('')

  const [validCardFormData, setValidCardFormData] = useState({
    firstName: '',
    lastName: '',
    email: userEmail,
    phoneNumber: userPhoneNumber,
    dob: {
      day: null,
      month: null,
      year: null,
    },
  })

  const needsPhoneVerification = () => !userPhoneNumber || userPhoneNumber === 'unverified' || userPhoneNumber === 'deleted'

  const parseDobString = (dobString = '') => {
    const day = parseInt(dobString.split('/')[0], 10)
    const month = parseInt(dobString.split('/')[1], 10)
    const year = parseInt(dobString.split('/')[2], 10)
    return { day, month, year }
  }

  const validCardDobString = (dobString = '') => {
    const { day, month, year } = parseDobString(dobString)
    const validDay = !Number.isNaN(day) && day <= 31 && day > 0
    const validMonth = !Number.isNaN(month) && month <= 12 && month > 0
    const validYear = !Number.isNaN(year) && year <= 2020 && year > 1900

    // Check valid input for day/month/year
    if (!validDay || !validMonth || !validYear) {
      setDobValidationError('Please use the format Day / Month / Year (e.g. 14/04/1991)')
      return false
    }

    // Check over 18
    const dobDate = moment().year(year).month(month - 1).date(day)

    if (moment().diff(dobDate, 'years') < 18) {
      setDobValidationError('You must be 18+ to register')
      return false
    }

    setDobValidationError('')
    return true
  }

  const isCardFormDataValid = (cardFormData) => {
    try {
      if (!cardFormData.dob.day || !cardFormData.dob.month || !cardFormData.dob.year) {
        throw new Error('Please enter your date of birth')
      }

      if (!cardFormData.firstName || !cardFormData.lastName) {
        throw new Error('Please enter your first and last name.')
      }

      if (!cardFormData.email) {
        throw new Error('Email not found, please get in touch with the Syndi Health support team.')
      }

      if (!cardFormData.phoneNumber) {
        throw new Error('Phone number not verified, please get in touch with the Syndi Health support team.')
      }
      return true
    } catch (error) {
      setFormError({ error: true, errorText: error.message })
      return false
    }
  }

  const handleSubmit = async () => {
    if (!isCardFormDataValid(validCardFormData)) return

    setSubmitting(true)
    try {
      window.hj('event', 'Submit Syndi Card Registration')
      await dispatch(createUserCard(validCardFormData)).unwrap()
      setRegistrationStep('registrationComplete')
    } catch (error) {
      setFormError({
        error: true,
        errorText: 'We were unable to register your card with the details provided, please contact us or try again.',
      })
    }
    setSubmitting(false)
  }

  const onCloseVerifyDialog = () => {
    if (!needsPhoneVerification()) {
      setOpenDialogVerify(false)
      setRegistrationStep('cardRegistrationModal')
    } else setRegistrationStep('activateCard')
  }

  const activateCard = (
    <>
      <style.HeaderWrapper>
        <style.Header>Activate your SyndiCard</style.Header>
      </style.HeaderWrapper>
      <style.OfferContainer>
        <style.CardImage src={MockPhone} alt="two phones" />
        <style.TextWrapper>
          <style.OfferText>
            <b>Your SyndiCard is now ready to be activated!</b>
          </style.OfferText>
        </style.TextWrapper>
      </style.OfferContainer>
      <style.Body>
        <style.SubTitle>What is SyndiCard?</style.SubTitle>
        <style.DescriptionWrapper>
          <style.Tick />
          <style.Text>
            A virtual card you can use to pay for health services
          </style.Text>
        </style.DescriptionWrapper>
        <style.DescriptionWrapper>
          <style.Tick />
          <style.Text>
            There are no additional transaction fees or registration costs
          </style.Text>
        </style.DescriptionWrapper>
        <style.DescriptionWrapper>
          <style.Tick />
          <style.Text>
            Allows your employer to provide co-pay solutions for the health services most suitable to you
          </style.Text>
        </style.DescriptionWrapper>

        <style.SubTitle>What happens next?</style.SubTitle>
        <style.Text>
          We&apos;ll need to capture a few personal details in order to activate your card.
          Once your card is ready, use your company provided credit to purchase any of the health services recommended to you.
          You can also add this card to your virtual wallet to make purchasing easier.
        </style.Text>

        <style.RegisterButton
          hoverBackgroundColor={COLORS.navy}
          hoverColor={COLORS.white}
          onClick={() => {
            if (needsPhoneVerification()) {
              setRegistrationStep('verifyPhoneNumber')
            } else {
              setRegistrationStep('cardRegistrationModal')
            }
            window.hj('event', 'Register For Syndi Card Button Clicked')
          }}
        >
          <style.ButtonLabel>Register for SyndiCard</style.ButtonLabel>
        </style.RegisterButton>
        <Link to="/home">
          <ButtonSkip
            colour={COLORS.primary}
            size="13px"
            width="100%"
            margin="20px 0 20px -20px"
          >
            Back
          </ButtonSkip>
        </Link>
      </style.Body>
    </>
  )

  const verifyPhoneNumber = (
    <VerifyDialog
      openDialog={openDialogVerify}
      onCloseDialog={onCloseVerifyDialog}
      setPhoneNumberForRegistration={(validPhoneNumber) => {
        setValidCardFormData({ ...validCardFormData, phoneNumber: validPhoneNumber })
      }}
      canSkipVerify
      hideDetailsText
    />
  )

  const cardRegistrationModal = (
    <>
      <style.HeaderWrapper>
        <style.Header>SyndiCard Registration</style.Header>
      </style.HeaderWrapper>
      <style.Body>
        <style.DescriptionWrapper>
          <style.Text>
            Fill out your details to register
          </style.Text>
        </style.DescriptionWrapper>

        <style.DescriptionWrapper>
          <style.Text>
            First & Last Name
          </style.Text>
        </style.DescriptionWrapper>

        <TextField
          type="text"
          placeholder="First & Last Name"
          onChange={(value) => setValidCardFormData(
            {
              ...validCardFormData,
              firstName: value.split(' ')[0],
              lastName: value.split(' ')[1],
            },
          )}
          showError={validCardFormData?.firstName === '' || validCardFormData?.lastName === ''}
          errorMessage="You must enter a valid first and last name"
          label="First & Last Name"
        />

        <style.DescriptionWrapper>
          <style.Text>
            Date of Birth (You must be 18+ to register)
          </style.Text>
        </style.DescriptionWrapper>

        <TextField
          value={stringDob}
          minWidth="95%"
          placeholder="DD / MM / YYYY"
          onChange={(value) => {
            setStringDob(value)
            if (validCardDobString(value)) {
              setValidCardFormData({
                ...validCardFormData,
                dob: parseDobString(value),
              })
            }
          }}
          showError={stringDob !== 'DD / MM / YYYY' && dobValidationError}
          errorMessage={dobValidationError}
          label="Date of Birth (Day / Month / Year) (e.g. 14/04/1991)"
        />

        {formError.error ? (
          <style.ErrorMessage>
            {formError.errorText}
          </style.ErrorMessage>
        ) : null}

        <style.RegisterButton onClick={() => {
          if (isCardFormDataValid(validCardFormData)) {
            setRegistrationStep('registerConfirmation')
          }
        }}
        >
          <style.ButtonLabel>Next</style.ButtonLabel>
        </style.RegisterButton>
        <ButtonSkip
          colour={COLORS.primary}
          size="13px"
          width="auto"
          margin="20px 25px 20px -10px"
          onClick={() => setRegistrationStep('activateCard')}
        >
          Back
        </ButtonSkip>
      </style.Body>
    </>
  )

  const registerConfirmation = (
    <>
      <style.HeaderWrapper>
        <style.Header>Register your SyndiCard</style.Header>
      </style.HeaderWrapper>
      <style.Body>
        <style.SubTitle style={{ fontSize: '14px' }}>Check your details</style.SubTitle>
        <style.VerifyDetailsContainer>
          <style.VerifyDetailsText>
            {`Name: ${validCardFormData.firstName} ${validCardFormData.lastName}`}
          </style.VerifyDetailsText>
          <style.VerifyDetailsText>
            {`Email: ${validCardFormData.email}`}
          </style.VerifyDetailsText>
          <style.VerifyDetailsText>
            {`Phone Number: ${validCardFormData.phoneNumber}`}
          </style.VerifyDetailsText>
          <style.VerifyDetailsText>
            {`Date of Birth: ${validCardFormData.dob.day}/${validCardFormData.dob.month}/${validCardFormData.dob.year}`}
          </style.VerifyDetailsText>
        </style.VerifyDetailsContainer>

        <style.RegisterButton onClick={handleSubmit}>
          {
            submitting ? (<CircularProgress color="primary" />) : (<style.ButtonLabel>Submit</style.ButtonLabel>)
          }
        </style.RegisterButton>
        <ButtonSkip
          onClick={() => setRegistrationStep('cardRegistrationModal')}
          colour={COLORS.primary}
          size="13px"
          width="100%"
          margin="20px 0 20px -20px"
        >
          Back
        </ButtonSkip>
      </style.Body>
    </>
  )

  const registrationComplete = (
    <>
      <style.HeaderWrapper>
        <style.Header>SyndiCard Registration Complete</style.Header>
      </style.HeaderWrapper>
      <style.CompleteText>Thanks for registering for your SyndiCard!</style.CompleteText>
      <style.CompleteSubtitle>
        You can check your balance and view your card details at any time by visiting the
        {' '}
        <style.GreenBoldText>SyndiCard</style.GreenBoldText>
        {' '}
        tab.
      </style.CompleteSubtitle>
      <style.Body>
        <style.SubTitle>How to use your SyndiCard?</style.SubTitle>
        <style.DescriptionWrapper>
          <style.Tick />
          <style.Text>
            Copy and paste your card details when purchasing or subscribing to a service.
            We recommend you keep your SyndiCard tab open for reference whilist completing your purchase.
          </style.Text>
        </style.DescriptionWrapper>
        <style.DescriptionWrapper>
          <style.Tick />
          <style.Text>
            SyndiCard does not currently allow you to top up your card, we will notify you when this feature is available.
          </style.Text>
        </style.DescriptionWrapper>
        <style.DescriptionWrapper>
          <style.Tick />
          <style.Text>
            If you have any questions or issues using your card, please contact Syndi Health at support@syndi.health and we will help as soon as we can.
          </style.Text>
        </style.DescriptionWrapper>
        <style.DescriptionWrapper>
          <style.Tick />
          <style.Text>
            Your card may need up to 3 minutes to be issued in order for security checks to be carried out. You will be notified on the next screen when it is ready.
          </style.Text>
        </style.DescriptionWrapper>
        <style.CloseButton onClick={onRegisterComplete}>
          <style.ButtonLabel>Close</style.ButtonLabel>
        </style.CloseButton>
      </style.Body>
    </>
  )

  const registrationSteps = {
    activateCard,
    verifyPhoneNumber,
    cardRegistrationModal,
    registerConfirmation,
    registrationComplete,
  }

  return (
    <>
      <Dialog
        open
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <style.ModalContainer>
          {(registrationSteps[registrationStep])}
        </style.ModalContainer>
      </Dialog>
    </>
  )
}
