import React from 'react'
import {
  Container, Grid, Button, ThemeProvider, useMediaQuery,
} from '@material-ui/core'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { updatePrivacyMarketing } from 'reducers/usersReducer'
import {
  StyledControlLabel, Title, Content, ErrorMessage,
} from 'components/Typography'
import { useNavigate } from 'react-router-dom'
import { PrivacyPolicyCheckbox } from '../../components/Checkbox'
import * as style from './PrivacyPolicy.style'
import { COLORS } from '../../constants'

function PrivacyPolicy() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const classes = style.useStyles()

  const [state, setLocalState] = React.useState({
    acceptPrivacy: false,
    error: false,
    acceptMarketing: false,
  })

  const handleChangeAgree = (value) => {
    setLocalState((state) => {
      if (value && !state.error) return ({ ...state, acceptPrivacy: value, error: false })
      return ({ ...state, acceptPrivacy: value })
    })
  }

  const handleClickBegin = async () => {
    const { acceptPrivacy } = state
    if (acceptPrivacy) {
      try {
        setLocalState((state) => ({ ...state, error: false }))

        // Update user properties
        await dispatch(updatePrivacyMarketing({
          acceptedPrivacyPolicy: true,
          acceptedMarketingComms: state.acceptMarketing,
        }))

        navigate('/home')
      } catch (error) {
        console.error(error)
      }
    } else {
      setLocalState((state) => ({ ...state, error: true }))
    }
  }

  const theme = style.theme
  const mobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Container maxWidth="sm">
      <ThemeProvider theme={theme}>
        <Grid
          container
          direction="row"
          justifyContent={mobile ? 'center' : 'flex-start'}
          alignItems="flex-start"
        >
          <Grid item xs={mobile ? 9 : 10} style={{ marginBottom: '25px', marginTop: mobile ? '10px' : '50px' }}>
            <Title>
              Welcome to your personalised health & wellbeing platform.
            </Title>
          </Grid>
          <br />
          <Grid item xs={mobile ? 9 : 10}>
            <Content>
              As you navigate and benefit from the platform, rest assured that all your data remains anonymous.
              Your interactions, choices, and budget utilisation are completely private, ensuring you won’t be identified to your employer.
            </Content>
            <br />
            <Content>
              This is not a diagnostic tool, for a diagnosis, an assessment by a professional is required.
            </Content>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            direction="row"
            justifyContent="space-evenly"
            id="privacy_policy"
            style={{ marginTop: '20px', marginLeft: mobile ? '-15px' : '-40px' }}
            className={state.error ? classes.errorBorder : null}
          >
            <Grid item xs={1}>
              <PrivacyPolicyCheckbox onChange={handleChangeAgree} value={state.acceptPrivacy} />
            </Grid>

            <Grid item xs={mobile ? 8 : 10} style={{ marginLeft: mobile ? '-35px' : '-30px' }}>
              <StyledControlLabel>
                I have read and agreed to Syndi Health&apos;s
                {' '}
                <a
                  href="/privacy"
                  style={{
                    fontFamily: 'Poppins',
                    color: COLORS.darkNavyBlue,
                    fontSize: '12px',
                    maxWidth: '50px',
                  }}
                >
                  <u>Privacy Policy</u>
                </a>
                {' '}
                and
                {' '}
                <a
                  href="/terms"
                  style={{
                    fontFamily: 'Poppins',
                    color: COLORS.darkNavyBlue,
                    fontSize: '12px',
                    maxWidth: '50px',
                  }}
                >
                  <u>Terms and Conditions</u>
                </a>
                .
              </StyledControlLabel>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            direction="row"
            justifyContent="space-evenly"
            id="marketing_comms"
            style={{ marginTop: '10px', marginLeft: mobile ? '-15px' : '-40px' }}
          >
            <Grid item xs={1}>
              <PrivacyPolicyCheckbox
                onChange={() => (state.acceptMarketing ? setLocalState({ ...state, acceptMarketing: false }) : setLocalState({ ...state, acceptMarketing: true }))}
                value={state.acceptMarketing}
              />
            </Grid>

            <Grid item xs={mobile ? 8 : 10} style={{ marginLeft: mobile ? '-35px' : '-30px' }}>
              <StyledControlLabel>
                I would like to receive updates on the Syndi Health services and relevant promotions.
              </StyledControlLabel>
            </Grid>
          </Grid>
          <Grid item xs={12} className={state.error ? classes.errorMessageContainer : classes.hideItem} id="error_message">
            <ErrorMessage>You must agree to Syndi Health’s Privacy Policy before continuing.</ErrorMessage>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" style={{ marginBottom: '50px', width: mobile ? '100%' : '85%' }}>
          <Grid item id="main_button" style={{ marginTop: '5px' }}>
            <Button
              onClick={handleClickBegin}
              size="medium"
              color="primary"
              className={classes.mainButton}
              variant="contained"
            >
              <span className={classNames('MuiButton-label', classes.mainLabelButton)}>
                Start your journey
              </span>
            </Button>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Container>
  )
}

export default PrivacyPolicy
