import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLORS } from '../../constants'

const Input = styled.input`
  border-radius: 3px;
  border: ${({ error }) => (error ? 'solid 1px' : 'none')};
  font-size: 16px;
  background-color: ${COLORS.white};
  padding: 8px;
  height: 44px;
  box-shadow: 0 4px 8px 0 rgba(160, 160, 160, 0.2);
  border-color: ${({ error }) => (error ? COLORS.red : '#979797')};
`

export default function InputBox({
  onChange,
  value,
  showError,
  placeholder,
  type,
}) {
  return (
    <Input
      type={type}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      value={value}
      error={showError}
    />
  )
}

InputBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  showError: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

InputBox.defaultProps = {
  showError: false,
}
