import React from 'react'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { BodySmallSemibold } from 'components/Typography'
import Button from 'components/Button'
import { Link } from 'react-router-dom'
import { REDUX_FETCH_STATUS } from 'constants'
import CardImg from '../../assets/img/card-holder.png'

const CardHolder = styled.img`
  width: 84px;
  height: 55px;
  margin-top: 5px;
`

export default function NavbarSyndiCard() {
  const cardFetchState = useSelector((state) => state.card.cardFetchState)
  const cardStatus = useSelector((state) => state.card.status)
  const cardBalance = useSelector((state) => state.card.cardBalance)
  const cardBalanceExpiry = useSelector((state) => state.card.cardBalanceExpiry)
  const stripeConnectId = useSelector((state) => state.organisation.stripeConnectId)
  const hasCard = (cardFetchState === 'succeeded' && cardStatus)

  if (!stripeConnectId) return null

  if (cardFetchState !== REDUX_FETCH_STATUS.succeeded) return null

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      style={{
        border: '1px solid var(--Navy-500, #6974A5)',
        borderRadius: '8px',
        background: 'linear-gradient(180deg, #28387E 0%, #3E4C8B 100%)',
        margin: '16px 0px',
        padding: '12px',
      }}
    >
      <Grid item style={{ marginBottom: '16px' }}>
        <BodySmallSemibold>
          <b>Your SyndiCard</b>
        </BodySmallSemibold>
      </Grid>
      <Grid item style={{ marginBottom: '16px' }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="center"
          spacing={3}
        >
          <Grid item>
            <CardHolder src={CardImg} />
          </Grid>
          {hasCard ? (
            <Grid item>
              <BodySmallSemibold>
                <b>{cardBalance}</b>
              </BodySmallSemibold>
              <BodySmallSemibold>
                <b>{cardBalanceExpiry}</b>
              </BodySmallSemibold>
            </Grid>
          ) : (
            <Grid item>
              <BodySmallSemibold>
                <b>Use your budget to purchase health services and subscriptions!</b>
              </BodySmallSemibold>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item>
        {hasCard ? (
          <Link to="/card">
            <Button>View Card Info</Button>
          </Link>
        ) : (
          <Link to="/card">
            <Button>Activate Card</Button>
          </Link>
        )}
      </Grid>
    </Grid>
  )
}
