import { combineReducers, configureStore } from '@reduxjs/toolkit'
import cardReducer from '../reducers/cardReducer'
import organisationReducer from '../reducers/organisationReducer'
import recommendationsReducer from '../reducers/recommendationsReducer'
import filtersReducer from '../reducers/filtersReducer'
import usersReducer from '../reducers/usersReducer'
import { RESET_STORE } from './actions'

const rootReducer = {
  recommendations: recommendationsReducer,
  filters: filtersReducer,
  organisation: organisationReducer,
  user: usersReducer,
  card: cardReducer,
}

const store = configureStore({
  reducer: (state, action) => {
    if (action.type === RESET_STORE) {
      return Object.keys(rootReducer).reduce(
        (nextState, key) => ({ ...nextState, [key]: rootReducer[key](undefined, action) }), {},
      )
    }
    return combineReducers(rootReducer)(state, action)
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
})

export default store
