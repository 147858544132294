import { createTheme } from '@material-ui/core/styles'
import { COLORS } from 'constants'

const theme = createTheme({
  typography: {
    h6: {
      fontSize: '1rem',
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      color: COLORS.primaryBlue,
    },
  },
  overrides: {
    MuiListItem: {
      root: {
        borderRadius: 14,
        border: 'solid 2px #5fcd81',
        backgroundColor: 'transparent',
        '&$focusVisible': {
          backgroundColor: 'rgba(95,205,129, 0.6)',
        },
      },
      button: {
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#5fcd81',
        },
        '&:active': {
          backgroundColor: '#5fcd81',
        },
      },
    },
    MuiButtonBase: {
      root: {
        // WebkitTapHighlightColor: '#5fcd81',
        backgroundColor: 'transparent',
        color: '#292929',
        '&::-moz-focus-inner': {
          borderStyle: 'none', // Remove Firefox dotted outline.
        },
        '&$disabled': {
          pointerEvents: 'none', // Disable link interactions
          cursor: 'default',
        },
        '@media print': {
          colorAdjust: 'exact',
        },
      },
    },
    MuiButton: {
      root: {
        color: '#292929',
        borderRadius: '8px',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#5fcd81',
        },
      },
      containedPrimary: {
        color: '#292929',
        backgroundColor: '#5fcd81',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#5fcd81',
          '@media (hover: none)': {
            backgroundColor: '#5fcd81',
          },
          boxShadow: 'none',
        },
      },
      containedSecondary: {
        color: '#292929',
        backgroundColor: '#e3e3e3',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#e3e3e3',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        color: 'black',
      },
    },
  },
})

export default theme
