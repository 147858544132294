import React, { useEffect, useState } from 'react'
import { AutoscrollRunner } from '@tripetto/runner-autoscroll'
import { Export } from '@tripetto/runner'
import Loading from 'fragments/Loading'
import { submitSurvey } from 'utils'
import { TripettoStyle, COLORS } from 'constants'
import { styled, Typography } from '@material-ui/core'
import { SubTitle } from 'components/Typography'

const RunnerDiv = styled('div')({
  marginLeft: '-5px',
})

export default function TripettoSurvey({ tripettoSurveys, onComplete }) {
  const [activeSurveyIdx, setActiveSurveyIdx] = useState(0)
  const runners = tripettoSurveys.map((survey) => (

    <AutoscrollRunner
      key={activeSurveyIdx}
      definition={survey.tripettoJsonString}
      styles={TripettoStyle}
      onSubmit={async (instance) => {
        // This exports all exportable data in the form
        const exportables = Export.exportables(instance)

        // Iterate through all the fields
        const questions = []
        const results = []
        exportables.fields.forEach((field) => {
          questions.push(field.name)
          results.push(field.string)
        })

        try {
          await submitSurvey(
            survey.surveyName,
            questions,
            results,
          )
        } catch (err) {
          console.error(err?.message)
        }

        setActiveSurveyIdx(activeSurveyIdx + 1)
      }}
    />

  ))

  useEffect(() => {
    if (activeSurveyIdx > tripettoSurveys.length - 1) onComplete()
  }, [activeSurveyIdx])

  if (runners.length === 0) return <Loading />

  return (
    <>
      <SubTitle>{tripettoSurveys[activeSurveyIdx]?.surveyName}</SubTitle>
      <Typography variant="h6" id="title" align="left" style={{ color: COLORS.textColor }}>
        Part
        {' '}
        {activeSurveyIdx + 1}
        {' '}
        of
        {' '}
        {tripettoSurveys.length}
      </Typography>
      <RunnerDiv>
        {runners[activeSurveyIdx]}
      </RunnerDiv>
    </>
  )
}
