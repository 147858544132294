import React, { useEffect } from 'react'
import { userUpdate } from 'utils'
import Loading from 'fragments/Loading'
import moment from 'moment-timezone'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchUser } from 'reducers/usersReducer'

function UniDays({ university }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const verifyUnidays = async () => {
    await userUpdate({
      requiresUnidaysVerification: false,
      userTimezone: moment.tz.guess(),
      university,
    })
    navigate('/home')
  }

  useEffect(() => {
    dispatch(fetchUser())
    verifyUnidays()
  })

  return <Loading />
}

export default UniDays
