/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  freeApps: false,
  hasFiltered: false,
  conditionFilterOpen: false,
  functionalityFilterOpen: false,
  costFilterAmount: null,
  conditionFilter: {}, // { serviceType: condition[] } e.g. { selfGuided: ['Anxiety', 'Depression'] }
  functionalityFilter: {}, // { serviceType: functionality[] } e.g. { selfGuided: ['CBT', 'Mindfulness'] }
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateFreeApps: (state, action) => {
      state.freeApps = action.payload
    },
    updateCostFilterAmount: (state, action) => {
      state.costFilterAmount = action.payload
    },
    updateHasFiltered: (state, action) => {
      state.hasFiltered = action.payload
    },
    updateConditionFilterOpen: (state, action) => {
      state.conditionFilterOpen = action.payload
    },
    updateFunctionalityFilterOpen: (state, action) => {
      state.functionalityFilterOpen = action.payload
    },
    updateConditionFilter: (state, action) => {
      state.conditionFilter = {
        ...state.conditionFilter,
        ...action.payload,
      }
    },
    updateFunctionalityFilter: (state, action) => {
      state.functionalityFilter = {
        ...state.functionalityFilter,
        ...action.payload,
      }
    },
  },
})

export const {
  updateFreeApps,
  updateHasFiltered,
  updateFunctionalityFilterOpen,
  updateConditionFilterOpen,
  updateConditionFilter,
  updateFunctionalityFilter,
  updateCostFilterAmount,
} = filtersSlice.actions

export default filtersSlice.reducer
