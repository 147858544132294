import Cookies from 'js-cookie'
import { useEffect, useRef } from 'react'

/**
 * Get session in browser cookie
 */
export const getSession = () => {
  const data = Cookies.get('syndi')
  return data ? JSON.parse(data) : {}
}

/**
 * Save session in browser cookie
 */
export const saveSession = (userId, userToken) => {
  Cookies.set('syndi', { userId, userToken })
  if (!getSession()) {
    throw new Error('Cookie error, please try clearing your browser cookies and try again.')
  }
}

/**
 * Delete session in browser cookie
 */
export const deleteSession = () => {
  Cookies.remove('syndi')
  window.persistor?.purge()
}

export const isValidMobileNumber = (number) => {
  let validNo = number
  if (validNo.charAt(0) !== '0') validNo = `0${validNo}`
  const regex = /((07)|((\+|00)447)){1}[0-9]{9}\b/
  const result = regex.test(validNo)
  return result
}

export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (email && re.test(email)) {
    return true
  }
  throw new Error('Please enter a valid email address')
}

export const isValidCustomEmail = (input, blockedEmails, invalidEmailError, whitelistEmails) => {
  const emailDomain = input.toString().split('@')[1]
  const disallowedEmails = blockedEmails.filter((email) => email === emailDomain)
  const whitelistedEmails = whitelistEmails.filter((email) => email === emailDomain)

  if (whitelistEmails.length && whitelistedEmails.length > 0) {
    return true
  } if (blockedEmails.length && disallowedEmails.length === 0) {
    return true
  } if (!whitelistEmails.length && !blockedEmails.length) {
    return true
  } throw new Error(invalidEmailError)
}

export const isValidUKPostcode = (postcode) => {
  // eslint-disable-next-line max-len
  const re = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/
  if (postcode && re.test(postcode)) {
    return true
  }
  throw new Error('Please enter a valid UK postcode')
}

export const isValidUSPostcode = (postcode) => {
  const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/
  if (postcode && re.test(postcode)) {
    return true
  }
  throw new Error('Please enter a valid US ZIP code')
}

export const isValidLength = (args) => {
  const {
    input, min, max, inputName,
  } = args
  if (input.toString().length < min || input.toString().length > max) throw new Error(`Please enter a valid ${inputName}`)
}

export const isValidLengthBoolean = (args) => {
  const {
    input, min, max,
  } = args
  return (input.toString().length < min || input.toString().length > max)
}

export const isValidPostcodeBoolean = (postcode) => {
  // eslint-disable-next-line max-len
  const re = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/
  return (postcode && re.test(postcode))
}

export const isValidEmailBoolean = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return (email && re.test(email))
}

export const isValidGender = (selectedGenders = []) => {
  if (selectedGenders.length === 0) throw new Error('Please select at least one gender')
}

// Min 1 uppercase letter.
export const has1UpperCase = (password) => {
  const re = /^(?=.*[A-Z]).+$/
  return (password && re.test(password))
}

// Min 1 special character.
export const hasSpecialCharacter = (password) => {
  const re = /^(?=.*[#$£€@!%&*?])/
  return (password && re.test(password))
}

// Min 1 lowercase letter.
export const has1LowerCase = (password) => {
  const re = /^(?=.*[a-z]).+$/
  return (password && re.test(password))
}

// Min 1 number.
export const has1Number = (password) => {
  const re = /^(?=.*[0-9]).+$/
  return (password && re.test(password))
}

// Min 8 characters.
export const has8Characters = (password) => {
  const re = /^(?=.{8,})/
  return (password && re.test(password))
}

// Max 30 characters.
export const hasLessThan30Characters = (password) => (password.length > 0 && password.length < 30)

export const isValidPassword = (password = '') => {
  // Regex for password must contain at least eight characters,
  if (!has8Characters(password)) throw new Error('Password must contain at least eight characters')
  if (!has1UpperCase(password)) throw new Error('Password must contain at least one uppercase letter')
  if (!has1LowerCase(password)) throw new Error('Password must contain at least one lowercase letter')
  if (!has1Number(password)) throw new Error('Password must contain at least one number')
  if (!hasSpecialCharacter(password)) throw new Error('Password must contain at least one special character')
  if (!hasLessThan30Characters(password)) throw new Error('Password must contain at most thirty characters')
  return true
}

export const generateArrayOfYears = () => {
  const max = new Date().getFullYear() - 18
  const min = max - 72
  const year = []

  for (let i = max; i >= min; i -= 1) {
    year.push(i)
  }
  return year
}

export const usePolling = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
