/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REDUX_FETCH_STATUS } from 'constants'
import * as api from 'utils/api'

const initialState = {
  organisationId: null, // string
  orgReferralCode: null, // string
  stripeConnectId: null, // string
  organisationOnboardingSurveys: [], // { surveyType, surveyName, tripettoJsonString }[]
  organisationHealthSurveys: [],
  organisationHealthAreas: {}, // { [healthAreaName]: survey }
  organisationFetchState: REDUX_FETCH_STATUS.idle,
  featureFlags: [],
  error: null,
}

export const fetchOrganisation = createAsyncThunk('organisation/fetchOrganisation', async () => {
  // ORGANISATION REDUCER STATE
  const { organisation } = await api.getOrganisationDetails()
  if (!organisation) return {}

  const organisationSurveys = await api.getOrganisationSurveys()

  const organisationOnboardingSurveys = organisationSurveys.filter((s) => s.surveyRequired === true && s.tripettoJsonString)
  const organisationHealthSurveys = organisationSurveys.filter((s) => s.surveyRequired === false)
  const organisationHealthAreas = organisationHealthSurveys
    .reduce((agg, survey) => {
      const healthAreaName = survey?.serviceType?.serviceTypeName
      if (!healthAreaName) return agg
      if (agg[healthAreaName]) agg[healthAreaName].push(survey)
      else agg[healthAreaName] = [survey]
      return agg
    }, {})

  return {
    ...organisation,
    organisationOnboardingSurveys,
    organisationHealthSurveys,
    organisationHealthAreas,
  }
})

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    updateOrganisationId: (state, action) => {
      state.organisationId = action.payload
    },
    updateReferralCode: (state, action) => {
      state.orgReferralCode = action.payload
    },
    updateStripeConnectId: (state, action) => {
      state.stripeConnectId = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchOrganisation.pending, (state) => {
      state.organisationFetchState = REDUX_FETCH_STATUS.loading
    })
    builder.addCase(fetchOrganisation.fulfilled, (state, action) => {
      state.organisationFetchState = REDUX_FETCH_STATUS.succeeded
      state.organisationId = action.payload.organisationId
      state.orgReferralCode = action.payload.orgReferralCode
      state.stripeConnectId = action.payload.stripeConnectId
      state.organisationOnboardingSurveys = action.payload.organisationOnboardingSurveys || []
      state.organisationHealthSurveys = action.payload.organisationHealthSurveys || []
      state.organisationHealthAreas = action.payload.organisationHealthAreas || {}
      state.featureFlags = action.payload.featureFlags || []
    })
    builder.addCase(fetchOrganisation.rejected, (state, action) => {
      state.organisationFetchState = REDUX_FETCH_STATUS.failed
      state.error = action.error.message
    })
  },
})

export const {
  updateOrganisationId,
  updateReferralCode,
  updateStripeConnectId,
} = organisationSlice.actions

export default organisationSlice.reducer
