import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateRecommendationType } from 'reducers/recommendationsReducer'
import FilterDialog from 'components/FilterDialog'
import RecommendedAppCard from 'components/RecommendedAppCard'
import { isIOS, isMobile } from 'react-device-detect'
import { Grid } from '@material-ui/core'
import FilterSelection from 'components/FilterSelection'
import { COLORS, FEATURE_FLAGS } from 'constants'
import config from 'config'
import { updateFunctionalityFilterOpen, updateConditionFilterOpen } from 'reducers/filtersReducer'
import stringSimilarity from '../../utils/stringSimilarity'
import { ServiceTypeHeader, Title } from '../Typography'
import * as style from './Recommendations.style'

export default function Recommendations({
  organisationId,
  recommendationsData,
  user,
}) {
  const dispatch = useDispatch()

  // Global State
  const recommendationType = useSelector((state) => state.recommendations.recommendationType)
  const hasFiltered = useSelector((state) => state.filters.hasFiltered)
  const showFreeApps = useSelector((state) => state.filters.freeApps)
  const costFilterAmount = useSelector((state) => state.filters.costFilterAmount)
  const conditionFilter = useSelector((state) => state.filters.conditionFilter)
  const functionalityFilter = useSelector((state) => state.filters.functionalityFilter)

  // Filter State
  const conditionFilterOpen = useSelector((state) => state.filters.conditionFilterOpen)
  const functionalityFilterOpen = useSelector((state) => state.filters.functionalityFilterOpen)
  const featureFlags = useSelector((state) => state.organisation.featureFlags)
  const userSelectedHealthAreas = useSelector((state) => state.user.selectedHealthAreas)

  // Component states
  const [selectedRecommendation, setSelectedRecommendation] = useState({})
  const [recommendationsForTab, setRecommendationsForTab] = useState([])
  // const [profGuideServiceModal, setProfGuideServiceModal] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [tabs, setTabs] = useState([])
  const [activeTabIdx, setActiveTabIdx] = useState(null)
  const [filteredRecommendationsData, setFilteredRecommendationsData] = useState(recommendationsData)

  // Media and device queries
  const platform = isMobile ? (isIOS ? 'iOS' : 'android') : 'desktop'

  const sortTabs = (aTabKey, bTabKey) => {
    if (aTabKey.toLowerCase() === config.companyFundedHealthAreaName) return -1
    return aTabKey - bTabKey
  }

  const tokenisationPipeline = (text) => (text.toLowerCase().replace(/[&+,:;=?@#|'<>.^*()%!-]/g, '').split(' '))

  const userSearchInput = useSelector((state) => state.user.userSearchInput)

  // === FEATURE FLAG: Search Feature ===
  const searchForHealthServices = async () => {
    if (!userSearchInput || !recommendationsData || !Object.keys(recommendationsData)) return

    const searchWords = tokenisationPipeline(userSearchInput)
    const filteredResults = {}

    Object.keys(recommendationsData).forEach((key) => {
      recommendationsData[key].forEach((app) => {
        // Build keywords array for matching
        const appKeywords = [app.title || ''].concat(app.keyWords).reduce((agg, keyWord) => {
          if (!keyWord) return agg
          return agg.concat(tokenisationPipeline(keyWord))
        }, [])

        // Match with searchWords using similarity algorithm
        const similarityThreshold = 0.85

        let keywordsMatch = false
        if (appKeywords.length > 0) {
          keywordsMatch = appKeywords?.some((keyword) => searchWords.some((searchWord) => {
            const similarity = stringSimilarity(keyword, searchWord)
            return similarity > similarityThreshold
          }))
        }

        if (keywordsMatch && filteredResults[key]) {
          filteredResults[key].push(app)
        } else if (keywordsMatch) {
          filteredResults[key] = [app]
        }
      })
    })
    return filteredResults
  }

  useEffect(() => {
    const updateFilteredResults = async () => {
      if (userSearchInput && featureFlags?.includes(FEATURE_FLAGS.searchFeature)) {
        const filteredResults = await searchForHealthServices()
        setFilteredRecommendationsData(filteredResults)
      } else {
        setFilteredRecommendationsData(recommendationsData)
      }
    }
    updateFilteredResults()
  }, [userSearchInput, recommendationsData])

  // === END FEATURE FLAG: Search Feature ===

  useEffect(() => {
    // Set available tabs from recommendaions data
    const tabNames = Object.keys(filteredRecommendationsData).filter((t) => {
      if (filteredRecommendationsData[t]?.length === 0) return false
      if (t.toLowerCase() === config.companyFundedHealthAreaName) return true
      if (userSelectedHealthAreas?.length > 0 && !userSelectedHealthAreas.includes(t)) return false
      return true
    }).sort(sortTabs)
    setTabs(tabNames)

    // Set default recommendation type
    const tab = recommendationType && tabNames.includes(recommendationType) ? recommendationType : tabNames[0]
    setActiveTabIdx(tabNames.indexOf(tab))

    // Set the recommendations for the tab
    let tabRecommendations = filteredRecommendationsData[tab] || []

    // Recommendation Filtering
    tabRecommendations = tabRecommendations.filter(
      (app) => {
        // Ensure all variants conform to the filter
        const variantsCostMatch = app.variants.every((variant) => {
          const variantCostMatch = showFreeApps ? variant.cost === 'free' : true
          const variantCostAmountMatch = (costFilterAmount !== null && costFilterAmount >= 0) ? variant.startingCost <= costFilterAmount * 100 : true
          return variantCostMatch && variantCostAmountMatch
        })

        const conditionFilterApplied = !(conditionFilter[recommendationType]?.length)
        const functionalityFilterApplied = !(functionalityFilter[recommendationType]?.length)

        let conditionsMatch = conditionFilterApplied
        if (conditionFilter[recommendationType]?.length) {
          conditionsMatch = app.conditions.some(({ conditionName }) => conditionFilter[recommendationType].includes(conditionName))
        }

        let functionalitiesMatch = functionalityFilterApplied
        if (functionalityFilter[recommendationType]?.length) {
          functionalitiesMatch = app.functionalities.some(({ functionalityName }) => functionalityFilter[recommendationType].includes(functionalityName))
        }

        return variantsCostMatch && conditionsMatch && functionalitiesMatch
      },
    )

    setRecommendationsForTab(tabRecommendations)

    // Set the selected recommendation
    if (tabRecommendations) setSelectedRecommendation(tabRecommendations[0] || {})

    setLoaded(true)
  }, [
    recommendationsData,
    costFilterAmount,
    recommendationType,
    user,
    hasFiltered,
    showFreeApps,
    conditionFilter,
    functionalityFilter,
    filteredRecommendationsData,
  ])

  const handleAppSort = (value) => {
    const reordered = [...recommendationsForTab]
    reordered.forEach((item, idx) => {
      if (item.id === value) {
        reordered.splice(idx, 1)
        reordered.push(item)
        reordered.reverse()
      }
    })
    setRecommendationsForTab(reordered)
  }

  const handleCarouselArrow = (back = false) => {
    let recs
    if (back) {
      recs = [recommendationsForTab[recommendationsForTab.length - 1], ...recommendationsForTab.slice(0, recommendationsForTab.length - 1)]
    } else {
      recs = [...recommendationsForTab.slice(1), recommendationsForTab[0]]
    }
    window.hj('event', 'More Services Button Clicked')
    setRecommendationsForTab(recs)
    setSelectedRecommendation(recs[0])
  }

  const handleRecommendationTabChange = (value) => {
    window.hj('event', `${value} tab clicked`)
    dispatch(updateRecommendationType(value))
    setActiveTabIdx(tabs.indexOf(value))
  }

  const formatTabName = (tabKey) => {
    let tabName = (tabKey.charAt(0).toUpperCase() + tabKey.slice(1)).replace(/([A-Z])/g, ' $1').trim()
    if (tabName.length > 31) {
      tabName = `${tabName.slice(0, 28)}...`
    }
    return tabName
  }

  const getTabLabel = (tabKey) => (
    <style.TabTitle>{formatTabName(tabKey)}</style.TabTitle>
  )

  return (
    <>

      <div style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center',
        marginBottom: '20px',
        marginTop: '0px',
      }}
      >
        <Title>
          Your Health & Wellbeing services
        </Title>
        <a href="https://forms.gle/37jbFUTzxLGgAtgk7" target="_blank">
          <style.RequestNewServiceButton hoverColor={COLORS.navy} hoverBackgroundColor={COLORS.primaryGreen} type="button">
            Request a service
          </style.RequestNewServiceButton>
        </a>
      </div>
      <style.TabsContainer id="tabs-container">
        {tabs.map((tabKey, idx) => (
          <style.Tab
            key={idx}
            value={tabKey}
            hoverBackgroundColor={COLORS.darkerGrey}
            selected={tabs[activeTabIdx] === tabKey}
            onClick={() => handleRecommendationTabChange(tabKey)}
          >
            {getTabLabel(tabKey)}
          </style.Tab>
        ))}
      </style.TabsContainer>
      <FilterDialog
        filterType="condition"
        openDialog={conditionFilterOpen}
        onCloseDialog={() => dispatch(updateConditionFilterOpen(false))}
        recommendationsForTab={recommendationsForTab}
      />
      <FilterDialog
        filterType="functionality"
        openDialog={functionalityFilterOpen}
        onCloseDialog={() => dispatch(updateFunctionalityFilterOpen(false))}
        recommendationsForTab={recommendationsForTab}
      />

      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <ServiceTypeHeader style={{
            fontSize: '18px',
            color: COLORS.textColor,
            marginTop: '18px',
          }}
          >
            {tabs[activeTabIdx]?.charAt(0).toUpperCase() + tabs[activeTabIdx]?.slice(1) || ''}
          </ServiceTypeHeader>
        </Grid>
        <Grid item style={{ marginLeft: isMobile ? '0px' : '40px' }}>
          <FilterSelection />
        </Grid>
      </Grid>

      {(!isMobile && recommendationsForTab?.length > 3) && (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10} />
        <Grid item xs={1}>
          <style.CarouselButton onClick={() => handleCarouselArrow(true)}>
            &lt; Prev
          </style.CarouselButton>
        </Grid>
        <Grid item xs={1}>
          <style.CarouselButton onClick={() => handleCarouselArrow()}>
            Next &gt;
          </style.CarouselButton>
        </Grid>
      </Grid>
      )}

      {!loaded && (
        <style.RecommendationsContainer>
          <Grid container />
        </style.RecommendationsContainer>
      )}

      {loaded && recommendationsForTab?.length === 0 && (
        <style.RecommendationsContainer>
          <p>
            No recommendations were found for your selected filters. Please choose a
            different filtering option and try again.
          </p>
        </style.RecommendationsContainer>
      )}

      {/* up to 600px width viewport */}
      {(isMobile && recommendationsForTab?.length > 0) && (
        <style.RecommendationsContainer>
          <Grid container>
            <style.IconCarousel>
              {recommendationsForTab && (recommendationsForTab?.slice(0, 3).map((r) => (
                <style.AppWrapper
                  key={r.id}
                  selected={selectedRecommendation?.id === r.id}
                >
                  <style.AppIcons
                    src={r.imageUrl}
                    alt={r.title}
                    selected={selectedRecommendation?.id === r.id}
                    onClick={() => {
                      setSelectedRecommendation(r)
                      handleAppSort(r.id)
                    }}
                  />
                  <style.AppName
                    selected={selectedRecommendation?.id === r.id}
                  >
                    {r.title}
                  </style.AppName>
                </style.AppWrapper>
              )))}
            </style.IconCarousel>

            <Grid item xs={12}>
              {selectedRecommendation && (
                <RecommendedAppCard
                  id="recommended_app_card"
                  organisationId={organisationId}
                  userId={user?.userId}
                  item={{
                    ...selectedRecommendation,
                    functionalities: selectedRecommendation.functionalities.map((f) => f.name || f),
                  }}
                />
              )}
            </Grid>
          </Grid>
        </style.RecommendationsContainer>
      )}

      {/* larger than 600px width viewport */}
      {(!isMobile && recommendationsForTab?.length > 0) && (
        <style.RecommendationsContainer>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={0}
          >
            {recommendationsForTab.length && (recommendationsForTab?.slice(0, 3).map((r) => (
              <Grid item xs={4} key={r.id}>
                <RecommendedAppCard
                  organisationId={organisationId}
                  item={{
                    ...r,
                    functionalities: r.functionalities.map((f) => f.name || f),
                  }}
                  platform={platform}
                />
              </Grid>
            )))}
          </Grid>
        </style.RecommendationsContainer>
      )}
      <br />
    </>
  )
}
