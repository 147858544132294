import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Recommendations from 'components/Recommendations'
import {
  Grid, Container, ThemeProvider,
} from '@material-ui/core'
import { getTopHealthServices } from 'utils/api'
import VerifyDialog from 'components/VerifyDialog'
import BarChart from 'components/CommunityBarChart'
import { REDUX_FETCH_STATUS, FEATURE_FLAGS } from 'constants'
import { fetchRecommendations, fetchCrisisContent } from 'reducers/recommendationsReducer'
import { fetchOrganisation } from 'reducers/organisationReducer'
import { fetchUser } from 'reducers/usersReducer'
import Loading from 'fragments/Loading/Loading'
import * as style from './Dashboard.style'

export default function Dashboard() {
  // Redux State Management
  const dispatch = useDispatch()

  // Organisation State
  const organisationFetchState = useSelector((state) => state.organisation.organisationFetchState)
  const organisationId = useSelector((state) => state.organisation.organisationId)
  const featureFlags = useSelector((state) => state.organisation.featureFlags)

  // Recommendations State
  const recommendationsDataFetchStatus = useSelector((state) => state.recommendations.recommendationsDataFetchStatus)
  const recommendationsData = useSelector((state) => state.recommendations.recommendationsData)
  const crisisContentFetchStatus = useSelector((state) => state.recommendations.crisisContentFetchStatus)

  // User State
  const user = useSelector((state) => state.user)
  const userFetchState = useSelector((state) => state.user.userFetchState)

  // Component State
  const [openDialogVerify, setOpenDialogVerify] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState()

  // Component Local Functions
  const onCloseVerifyDialog = () => setOpenDialogVerify(() => false)

  const removeExtraHealthServices = (data) => {
    data.sort((healthServiceA, healthServiceB) => healthServiceB.users - healthServiceA.users)
    data.filter((healthService) => healthService.appName !== 'undefined')
    const topFive = data.slice(0, 5)
    return topFive
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await getTopHealthServices(undefined, organisationId)
      if (!result) return
      const mappedResult = Object.entries(result).map(([uuid, clicks]) => ({
        appName: uuid,
        users: clicks,
      }))
      setData(removeExtraHealthServices(mappedResult))
    }
    if (featureFlags?.includes(FEATURE_FLAGS.communityFeature) && organisationId) {
      fetchData()
    }
  }, [organisationId])

  useEffect(() => {
    window.hj('event', 'viewed page syndi.cloud/recommendations')
  }, [])

  useEffect(() => {
    if (userFetchState === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchUser())
    }
    if (organisationFetchState === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchOrganisation())
    }
    if (recommendationsDataFetchStatus === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchRecommendations())
    }
    if (crisisContentFetchStatus === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchCrisisContent())
    }
    if (userFetchState === REDUX_FETCH_STATUS.succeeded
      && organisationFetchState === REDUX_FETCH_STATUS.succeeded
      && recommendationsDataFetchStatus === REDUX_FETCH_STATUS.succeeded
    ) {
      setLoaded(true)
    }
  }, [userFetchState, organisationFetchState, recommendationsDataFetchStatus, crisisContentFetchStatus, loaded])

  if (!loaded) return <Loading />

  return (
    <Container maxWidth="md" style={{ marginTop: '41px' }}>
      <Grid
        id="wrapper_dashboard"
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <ThemeProvider theme={style.dashboardTheme}>
          <Grid item xs={12}>
            <Recommendations
              organisationId={organisationId}
              recommendationsData={recommendationsData}
              user={user}
            />
          </Grid>
          {featureFlags?.includes(FEATURE_FLAGS.communityFeature) && (
            <Grid item xs={12}>
              <style.CommunityContainer>
                <style.TitleStyle>Your community&apos;s recommendations</style.TitleStyle>
                <style.BarChartContainer>
                  <style.ChartTitleStyle>Most popular health services</style.ChartTitleStyle>
                  <BarChart data={data || []} />
                </style.BarChartContainer>
              </style.CommunityContainer>
            </Grid>
          )}

          <VerifyDialog
            onCloseDialog={onCloseVerifyDialog}
            openDialog={openDialogVerify}
          />
        </ThemeProvider>
      </Grid>
    </Container>
  )
}
