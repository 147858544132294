import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  font-weight: 600;
`

const CostContainer = styled.div`
  border-radius: 16px;
  border: 2px solid #edeff4;
  background-color: #edeff4;
  color: #717c8a;
  text-transform: uppercase;
  font-size: 10px;
  padding: 1px 6px 1px 6px;
  margin: 2px 4px 4px 0px;
`
const StartingCostContainer = styled.div`
  border-radius: 16px;
  border: 2px solid #edeff4;
  background-color: #edeff4;
  color: #717c8a;
  text-transform: uppercase;
  font-size: 10px;
  padding: 1px 6px 1px 6px;
  display: ${({ startingCost }) => (startingCost === 0 ? 'none' : 'flex')};
  margin: 2px 4px 4px 0px;
  width: fit-content;
`

const formatCost = (startingCost, country) => {
  const currency = country === 'US' ? '$' : '£'
  const costInDollars = (startingCost / 100).toFixed(2)
  return `${currency}${costInDollars}`
}

const costMap = {
  'free-with-in-app-purchases': ['in-app-purchases'],
  free: ['free'],
  'payment-required': ['paid'],
}

export default function Price({
  cost, startingCost, country,
}) {
  return (
    <Container>
      {costMap[cost]?.map((c) => (
        <CostContainer key="">
          {c}
        </CostContainer>
      ))}
      <StartingCostContainer startingCost={startingCost}>
        From&nbsp;
        {formatCost(startingCost, country)}
      </StartingCostContainer>
    </Container>
  )
}

Price.propTypes = {
  cost: PropTypes.string.isRequired,
  startingCost: PropTypes.number.isRequired,
  country: PropTypes.string.isRequired,
}
