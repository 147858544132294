import React, { useState } from 'react'
import {
  Grid,
  TextField,
} from '@material-ui/core'
import { COLORS } from 'constants'
import { Link, useNavigate } from 'react-router-dom'
import { SubHeader, HelperText } from 'components/Typography'
import ErrorText from 'components/ErrorText'
import Button from 'components/Button'
import SyndiLogo from 'components/SyndiLogo'
import {
  has1LowerCase, has1Number, has1UpperCase, has8Characters, hasLessThan30Characters, hasSpecialCharacter, isValidPassword, userUpdate,
} from '../../utils'

export default function ResetPassword() {
  const navigate = useNavigate()

  // Local State
  const [password, setPassword] = useState('')
  const [confirmPw, setConfirmPw] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleResetPassword = async () => {
    try {
      const update = {}
      if (isValidPassword(password) && (password === confirmPw)) {
        update.password = password

        await userUpdate(update)

        navigate('/signout')
      }
    } catch (error) {
      console.error(error)
      setErrorMessage(error?.message)
    }
  }

  const handleSubmit = async () => {
    try {
      if (password !== confirmPw) throw new Error('Passwords must match!')
      isValidPassword(password)
      await handleResetPassword()
    } catch (err) {
      setErrorMessage(err?.message)
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: '100vh',
        paddingTop: '32px',
        backgroundColor: COLORS.babyBlue,
      }}
    >
      <Grid item xs={10} md={6}>
        <Grid
          container
          direction="column"
          alignItems="stretch"
          justifyContent="center"
          spacing={2}
          style={{
            borderRadius: '8px',
            background: '#F7FBFE',
            boxShadow: '0px 20px 70px 0px rgba(40, 56, 126, 0.14)',
            padding: '32px',
          }}
        >
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="flex-end"
            style={{ padding: '42px 40px 40px' }}
          >
            <Grid item>
              <SyndiLogo />
            </Grid>
            <Grid item>
              <SubHeader>Enter your new password below</SubHeader>
            </Grid>

            <Grid item>
              <TextField
                InputLabelProps={{ }}
                InputProps={{ }}
                variant="outlined"
                placeholder="Set your password"
                label="Set Password"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value)
                  setErrorMessage('')
                }}
              />
            </Grid>
            {password && (
              <Grid
                item
                style={{
                  borderRadius: '4px',
                  border: '1px solid #ABB4BB',
                  width: '215px',
                  marginLeft: '8px',
                }}
              >
                <HelperText style={{ padding: '8px' }}>
                  Your password must contain:
                  <HelperText style={{ color: has8Characters(password) ? '#00A343' : '#FF0000' }}>
                    {has8Characters(password) ? '✅' : '❌'}
                    {' '}
                    At least 8 characters
                  </HelperText>
                  <HelperText style={{ color: hasLessThan30Characters(password) ? '#00A343' : '#FF0000' }}>
                    {hasLessThan30Characters(password) ? '✅' : '❌'}
                    {' '}
                    Less than 30 characters
                  </HelperText>
                  <HelperText style={{ color: has1UpperCase(password) ? '#00A343' : '#FF0000' }}>
                    {has1UpperCase(password) ? '✅' : '❌'}
                    {' '}
                    1 uppercase letter
                  </HelperText>
                  <HelperText style={{ color: has1LowerCase(password) ? '#00A343' : '#FF0000' }}>
                    {has1LowerCase(password) ? '✅' : '❌'}
                    {' '}
                    1 lowercase letter
                  </HelperText>
                  <HelperText style={{ color: has1Number(password) ? '#00A343' : '#FF0000' }}>
                    {has1Number(password) ? '✅' : '❌'}
                    {' '}
                    1 number
                  </HelperText>
                  <HelperText style={{ color: hasSpecialCharacter(password) ? '#00A343' : '#FF0000' }}>
                    {hasSpecialCharacter(password) ? '✅' : '❌'}
                    {' '}
                    1 special character
                  </HelperText>
                </HelperText>
              </Grid>
            )}

            <Grid item>
              <TextField
                InputLabelProps={{ }}
                InputProps={{ }}
                variant="outlined"
                placeholder="Confirm your password"
                label="Confirm Password"
                type="password"
                onChange={(e) => {
                  setConfirmPw(e.target.value)
                  setErrorMessage('')
                }}
              />
            </Grid>

            <Grid item>
              <Button onClick={() => handleSubmit()} type="submit">
                Sign up now!
              </Button>
            </Grid>

            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

            <Grid item>
              <HelperText>
                Remembered your password?
                {' '}
                <Link
                  to={{ pathname: '/signin' }}
                  style={{
                    fontSize: '10px',
                    textDecoration: 'underline',
                  }}
                >
                  Sign in here
                </Link>
              </HelperText>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}
