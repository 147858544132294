/* eslint-disable max-len */
/**
 * Utils: Back-end
 */
import { isIOS, isMobile } from 'react-device-detect'
import config from '../config'
import { getSession } from './helpers'

if (!config.domains.api) throw new Error('Error: Missing API Domain')
if (!config.domains.web) throw new Error('Error: Missing Web Domain')

const platform = isMobile ? (isIOS ? 'iOS' : 'android') : 'desktop'

/**
  * Api Error Handling
  */
const handleApiError = (response = {}) => {
  const error = response.error || (response.errors || [])[0] || response
  let errorMessage = error.message || error.msg

  if (response.status === 401) return window.location.replace('/signout')

  switch (error.code) {
    case 'BODYERR02':
      throw new Error(errorMessage)
    case 'INVALIDPASS00':
      throw new Error(errorMessage)
    case 'PASSRECOV01':
      throw new Error(errorMessage)
    case 'UNKREF00':
      errorMessage = 'It looks like your organisation\'s access to Syndi Health is no longer available. Please contact us at hello@syndi.health for more support or get in touch with the organisation who referred you to Syndi Health.'
      throw new Error(errorMessage)
    case 'UNKNOWN':
      throw new Error(errorMessage)
    case 'REGFAIL01':
    case 'REGFAIL03':
      errorMessage = 'This email address is already registered to a Syndi Health Account. Please log in or create an account with a new email address.'
      throw new Error(errorMessage)
    case 'REGFAIL02':
    case 'REGFAIL04':
      errorMessage = 'This phone number is already registered to a Syndi Health Account. Please log in or create an account with a new phone number.'
      throw new Error(errorMessage)
    case 'REGFAIL05':
    case 'FORGOTFAIL02':
      errorMessage = 'Oops! Something went wrong! Please contact us at hello@syndi.health for support.'
      throw new Error(errorMessage)
    case 'FORGOTFAIL01':
      throw new Error(errorMessage)
    case 'LOGINFAIL00':
      throw new Error(errorMessage)
    case 'LOGINFAIL01':
      throw new Error(errorMessage)
    case 'LOGINFAIL05':
      errorMessage = 'The verification code you have entered is not recognised. Please double check it\'s correct or request a new code.'
      throw new Error(errorMessage)
    case 'PHONENUM03':
      errorMessage = 'This phone number is already registered to a Syndi Health Account. Please check that the number is correct, otherwise contact us at hello@syndi.health'
      throw new Error(errorMessage)
    case 'REGFAIL00':
    case 'REGFAIL08':
      errorMessage = 'Email domain is not whitelisted for this organisation'
      throw new Error(errorMessage)
    case 'REGFAIL06':
    case 'REGFAIL07':
    case 'LOGINFAIL02':
    case 'LOGINFAIL03':
    case 'LOGINFAIL04':
    case 'LOGINFAIL06':
    case 'VERIFYFAIL00':
    case 'VERIFYFAIL01':
    case 'VERIFYFAIL02':
    case 'VERIFYFAIL03':
    case 'VERIFYFAIL04':
    case 'VERIFYFAIL05':
    case 'FORGOTFAIL00':
    case 'AUTH03':
    case 'SERVERERR02':
      throw new Error(errorMessage)
    default:
      throw new Error(errorMessage)
  }
}

/**
  * API request to call the backend
  */
export const requestApi = async (
  path = '',
  method = 'GET',
  data = null,
  headers = {},
) => {
  const response = await fetch(`${config.domains.api}${path}`, {
    method: method.toUpperCase(),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: data ? JSON.stringify(data) : null,
  })

  let resp = await response.text()
  try {
    resp = JSON.parse(resp)
  } catch (e) {
    // Not JSON
  }

  if (response.status < 200 || response.status >= 300) handleApiError(resp)

  return resp
}

/**
  * Returns a users country based on their cloudfront requrest headers on the api backend, defaulting to the US
  */
export const getCountry = async () => {
  const resp = await requestApi('/country-detect', 'GET', null, {})
  return resp
}

/**
 * Get all recommendations without making 3 API calls *
 */
export const getAllRecommendations = async (token = getSession()?.userToken) => {
  const recommendationsData = await requestApi('/app-recommendations', 'POST', {
    platform,
  }, {
    Authorization: `Bearer ${token}`,
  })
  return recommendationsData
}

export const qrClick = (userId = getSession()?.userId, resourceUrl, identityId, resourceId, title) => requestApi('/qr-click', 'POST', {
  userId, resourceUrl, identityId, resourceId, title,
})

/**
  * Get organisation details
  */
export const getOrganisationDetails = (token = getSession()?.userToken) => requestApi('/organisations/details', 'GET', null, {
  Authorization: `Bearer ${token}`,
})
// {
//   stripeConnectId: found.stripeConnectId,
//   organisationId: found.organisationId,
//   referralCode: found.referralCode,
//   customEmailWhitelist: found.customEmailWhitelist,
// }

/**
  * Get a users completed surveys
  */
export const getSurveys = (token = getSession()?.userToken) => requestApi('/surveys/', 'GET', null, {
  Authorization: `Bearer ${token}`,
})

/**
  * Get organisation surveys
  */
export const getOrganisationSurveys = (token = getSession()?.userToken) => requestApi('/surveys/organisationSurveys', 'GET', null, {
  Authorization: `Bearer ${token}`,
})

/**
  * Submit a custom survey
  */
export const submitSurvey = (surveyName, questions, results) => requestApi(
  '/surveys/submitSurvey',
  'POST',
  { surveyName, questions, results },
  {
    Authorization: `Bearer ${getSession()?.userToken}`,
  },
)

/**
  * Retrieve the authenticated user
  */
export const getUser = async () => {
  const resp = await requestApi(`/users/${getSession()?.userId}`, 'GET', null, {
    Authorization: `Bearer ${getSession()?.userToken}`,
  })
  return resp.user
}

/**
  * Register a new user V2 whilst converting old landing pages to new
  */
export const userRegisterV2 = (token, referralCode, email, phoneNumber, password, meta = []) => {
  const payload = {}

  if (email) payload.email = email
  if (phoneNumber) payload.phoneNumber = phoneNumber
  if (password) payload.password = password
  if (meta) payload.meta = meta
  if (referralCode) payload.referralCode = referralCode

  return requestApi('/users/userRegister', 'POST', payload, {
    Authorization: `Bearer ${token}`,
  })
}

/**
  * Uses the SES client class to send an email verification code
  */
export const userVerifyV2 = (email, verificationCode) => requestApi('/users/userVerify', 'POST', {
  email,
  verificationCode,
})

/**
  * Registers a new user without a phonenumber in an unverified state
  */
export const userRegisterUnverified = (referralCode, meta = [], email = null) => {
  const payload = {}

  if (referralCode) payload.referralCode = referralCode
  if (email) payload.email = email
  if (meta) payload.meta = meta

  return requestApi('/users/registerUnverified', 'POST', payload)
}

/**
  * Verify Phone Number
  */
export const sendPhonenumberVerification = (phoneNumber) => requestApi(
  '/users/sendPhonenumberVerification', 'POST', { phoneNumber }, {
    Authorization: `Bearer ${getSession()?.userToken}`,
  },
)

export const sendEmailVerification = (email) => requestApi(
  '/users/sendEmailVerification', 'POST', { email }, {
    Authorization: `Bearer ${getSession()?.userToken}`,
  },
)

export const verifyPhoneNumber = (
  token = getSession()?.userToken, phoneNumber, verificationCode, returnIfExisting = false,
) => requestApi(
  '/users/verifyPhoneNumber', 'POST', {
    phoneNumber,
    verificationCode,
    returnIfExisting,
  }, {
    Authorization: `Bearer ${token}`,
  },
)

/**
  * Login a user V2
  */
export const userLoginV2 = (token, email, phoneNumber, password, verificationCode, otp) => {
  const payload = {}

  if (email) payload.email = email
  if (phoneNumber) payload.phoneNumber = phoneNumber
  if (password) payload.password = password
  if (verificationCode) payload.verificationCode = verificationCode
  if (otp) payload.otp = otp

  return requestApi('/users/userLogin', 'POST', payload, {
    Authorization: `Bearer ${token}`,
  })
}

/**
  * Resetting password for a userV2
  */
export const userForgotPassword = (email) => requestApi(
  '/users/userforgotpassword', 'POST', { email },
)

/**
  * Update a user
  */
export const userUpdate = (update) => requestApi(`/users/${getSession()?.userId}`, 'POST', update, {
  Authorization: `Bearer ${getSession()?.userToken}`,
})

/**
  * Deletes a user
  */
export const deleteUser = (userId) => requestApi(`/users/${userId}`, 'DELETE', null, {
  Authorization: `Bearer ${getSession()?.userToken}`,
})

/**
  * Submit schedule
  */
export const submitSchedule = (token = getSession()?.userToken, checkupTime, reminderFrequency, preferredReminder) => requestApi(
  '/checkups/setSchedule',
  'POST',
  {
    checkupTime,
    reminderFrequency,
    preferredReminder,
  },
  {
    Authorization: `Bearer ${token}`,
  },
)

/**
  * Get current schedule
  */
export const getCurrentSchedule = async (token = getSession()?.userToken) => {
  try {
    const { checkups } = await requestApi(
      '/checkups/getCurrentSchedule',
      'GET',
      null,
      {
        Authorization: `Bearer ${token}`,
      },
    )
    // TODO: Update to allow for various checkups (e.g. sms and push)
    return (checkups && checkups[0]) ? checkups[0] : null
  } catch (err) {
    return {}
  }
}

/**
  * Get reminder options
  */
export const getReminderOptions = (token = getSession()?.userToken) => requestApi(
  '/checkups/getReminderOptions',
  'GET',
  null,
  {
    Authorization: `Bearer ${token}`,
  },
)

/**
  * Clear schedule
  */
export const clearSchedule = (token = getSession()?.userToken) => requestApi(
  '/checkups/clearSchedule',
  'DELETE',
  null,
  {
    Authorization: `Bearer ${token}`,
  },
)

/**
  * Clear schedule
  */
export const getCrisisContent = () => requestApi(
  '/crisis-support',
  'GET',
  null,
)

/**
  * Create a card for a user
  */
export const userCreateCard = (userInfo) => requestApi(`/users/${getSession()?.userId}/createCard`, 'POST', userInfo, {
  Authorization: `Bearer ${getSession()?.userToken}`,
})

/**
  * Get a card for a user
  */
export const userGetCard = (token = getSession()?.userToken, userId = getSession()?.userId) => requestApi(`/users/${userId}/getCard`, 'GET', null, {
  Authorization: `Bearer ${token}`,
})

/**
  * Get popular app data
  */
export const getTopHealthServices = (token = getSession()?.userToken, organisationId) => requestApi(`/community/getTopHealthServices/${organisationId}`, 'GET', null, {
  Authorization: `Bearer ${token}`,
})
