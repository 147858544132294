import React, { useEffect, useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import {
  HelperText, Title, ErrorMessage, SubHeader,
} from 'components/Typography'
import SyndiLogo from 'components/SyndiLogo'
import { COLORS } from 'constants'
import Button from 'components/Button'
import { isValidEmailBoolean, userForgotPassword } from '../../utils'

export default function ForgotPassword({ email }) {
  const [userEmail, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [sentPasswordReset, setSentPasswordReset] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (email) setEmail(email)
  }, [email])

  const handleEmailCheck = async () => {
    setErrorMessage('')
    try {
      if (!userEmail) {
        setEmailError('Please enter your email address')
      }
      if (!isValidEmailBoolean(userEmail)) {
        setEmailError('Please enter a valid email address')
      }
      if (userEmail && isValidEmailBoolean(userEmail)) {
        await userForgotPassword(userEmail)
        setSentPasswordReset(true)
      }
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', backgroundColor: COLORS.babyBlue }}
    >
      <Grid item xs={10} md={6}>
        <Grid
          container
          direction="column"
          alignItems="stretch"
          justifyContent="center"
          spacing={2}
          style={{
            borderRadius: '8px',
            background: '#F7FBFE',
            boxShadow: '0px 20px 70px 0px rgba(40, 56, 126, 0.14)',
            padding: '32px',
          }}
        >
          <Grid item>
            <SyndiLogo />
          </Grid>
          <Grid item>
            <Title>Forgotten your password?</Title>
            <SubHeader>Enter the email address you used to sign-up to set a new password</SubHeader>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              placeholder="Email address"
              label={email ? '' : 'Email address'}
              disabled={email?.length > 0}
              value={userEmail}
              error={!!emailError}
              helperText={emailError}
              onChange={(e) => {
                setErrorMessage('')
                setEmailError('')
                setEmail(e.target.value)
              }}
            />
          </Grid>
          {
            sentPasswordReset === false ? (
              <Grid item>
                <Button
                  variant="primary"
                  onClick={handleEmailCheck}
                  disabled={!!emailError || !userEmail}
                >
                  Continue
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  variant="primary"
                  onClick={handleEmailCheck}
                  disabled
                >
                  Sent!
                </Button>
              </Grid>
            )
          }
          <Grid item>
            <HelperText>
              Alternatively, you can
              {' '}
              <a
                href="mailto:hello@syndi.health"
                style={{
                  fontSize: '10px',
                  textDecoration: 'underline',
                }}
              >
                contact us
              </a>
            </HelperText>
          </Grid>
          {errorMessage && (
            <Grid item>
              <ErrorMessage style={{
                maxWidth: '350px',
              }}
              >
                {errorMessage}
              </ErrorMessage>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
