import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const styles = {
  grid: {
    margin: '0 0 !important',
    width: 'unset',
  },
}

const useStyles = makeStyles(styles)

export default function GridContainer({ children, ...rest }) {
  const classes = useStyles()

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Grid container {...rest} className={classes.grid}>
      {children}
    </Grid>
  )
}

GridContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
}
