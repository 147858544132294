import React, { useState, useEffect } from 'react'
import {
  Grid, ThemeProvider, IconButton, DialogContent, Dialog, DialogTitle, Modal, useMediaQuery, Select, MenuItem,
} from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { clearSchedule, submitSchedule } from 'utils'
import { ModalClose, ModalDiv } from 'pages/Profile/Profile.style'
import {
  DescriptionText, Content, SubTitle,
} from 'components/Typography'
import ErrorText from 'components/ErrorText'
import MomentUtils from '@date-io/moment'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import TimezoneSelector from 'components/TimezoneSelector'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import VerifyDialog from 'components/VerifyDialog'
import Button from 'components/Button'
import { themeOptions, useStyles } from './RemindersDialog.style'

function RemindersDialog({
  onCloseDialog,
  openDialog,
}) {
  // Global State
  const {
    phoneNumber, checkupTime, preferredReminder, reminderFrequency: userReminderFrequency, reminderOptions,
  } = useSelector((state) => state.user)

  // Local state
  const [openVerificationModal, setOpenVerificationModal] = useState(false)
  const [formError, setFormError] = useState(null)
  const [scheduleFeedback, setScheduleFeedback] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [preferenceState, setPreferenceState] = useState('email')
  const [reminderFrequency, setReminderFrequency] = useState(userReminderFrequency)
  const [dateTime, setDateTime] = useState(
    moment()
      .add(1, 'day')
      .minutes(0)
      .seconds(0)
      .milliseconds(0),
  )
  const [timezone, setTimezone] = useState(moment.tz.guess())
  const [utcTime, setUtcTime] = useState(
    moment.utc()
      .add(1, 'day')
      .minutes(0)
      .seconds(0)
      .milliseconds(0),
  )

  useEffect(() => {
    setFormError(null)
    setScheduleFeedback(null)
    if (preferredReminder) setPreferenceState(preferredReminder)
    if (checkupTime) setDateTime(moment(checkupTime).local(true))
  }, [modalOpen])

  const closeModal = () => setModalOpen(() => false)

  const handleClickOpenModal = () => setModalOpen(() => true)

  const closeAllModals = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return handleClickOpenModal()
    }
    closeModal()
    onCloseDialog()
  }

  const onScheduleSubmit = async () => {
    setFormError(null)

    if (!preferenceState) setFormError('Please select how you would like to be reminded.')

    else if (!utcTime) setFormError('Please select the date and hour of your first reminder.')

    else if (!reminderFrequency) setFormError('Please select the frequency at which you would like to be reminded.')

    else if (preferenceState === 'sms' && !phoneNumber) {
      setOpenVerificationModal(true)
    } else {
      try {
        await submitSchedule(
          undefined,
          utcTime, // checkupTime,
          reminderFrequency.toLowerCase(), // ReminderFrequency,
          preferenceState.toLowerCase(), // preferredReminder,
        )
        closeAllModals()
      } catch (err) {
        setFormError(err.message)
      }
    }
  }

  const onClearSchedule = async () => {
    try {
      const result = await clearSchedule()
      setScheduleFeedback(() => (result || {}).message)
      setPreferenceState('')
    } catch (error) {
      console.error(error)
    }
  }

  const selectPreferenceOption = (option) => {
    if (option) setPreferenceState(option)
  }

  const handleDateTimeChange = (value) => {
    const removedTz = value.format('YYYY-MM-DDTHH:mm:ss')
    setDateTime(removedTz)

    const utcTime = moment(removedTz).tz(timezone).utc().toISOString()
    setUtcTime(utcTime)
  }

  const theme = createTheme(themeOptions)

  const classes = useStyles()

  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <VerifyDialog
        canSkipVerify
        onCloseDialog={() => setOpenVerificationModal(false)}
        openDialog={openVerificationModal}
      />
      <Dialog maxWidth="xs" fullWidth open={openDialog} onClose={closeAllModals}>
        <DialogTitle>
          <IconButton
            style={{
              position: 'absolute',
              right: '0px',
              top: '0px',
            }}
            onClick={handleClickOpenModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ThemeProvider theme={theme}>
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item xs={12} className={classes.titleText}>
                <SubTitle>
                  Set Assessment Reminder
                </SubTitle>
              </Grid>
              <Grid item xs={12} className={classes.contentText}>
                <Content>
                  Receive a friendly reminder to complete your next Syndi Health self-assessment questionnaire and indicate your preferred frequency for further assessments.
                  This is the best way to track your mental health trends.
                </Content>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SubTitle>
                By
              </SubTitle>
              {reminderOptions.map((option, index) => (option.type === preferenceState && (
                <Grid key={index} item xs={mobile ? 12 : 4}>
                  <Button
                    onClick={() => selectPreferenceOption(option.type)}
                    variant="primary"
                    disabled
                    value={option.type}
                  >
                    {option.name}
                  </Button>
                </Grid>
              )
              ))}
              <br />
            </Grid>

            <Grid item>
              <SubTitle>
                On
              </SubTitle>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
              >
                <DateTimePicker
                  inputVariant="outlined"
                  disablePast
                  openTo="date"
                  views={['date', 'hours']}
                  format="MMMM Do ha"
                  value={dateTime}
                  minutesStep={60}
                  minDate={moment().add(1, 'day').toDate()}
                  onChange={handleDateTimeChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <br />
            <Grid item xs={12}>
              <SubTitle>
                Timezone
              </SubTitle>
              <TimezoneSelector onChange={(value) => { setTimezone(value) }} />
            </Grid>
            <br />
            <Grid item xs={12}>
              <SubTitle>
                How often would you like to be reminded?
              </SubTitle>
            </Grid>
            <Grid item>
              <Select
                labelId="freq-simple-select-label"
                id="freq-simple-select"
                variant="outlined"
                value={reminderFrequency}
                onChange={(event) => setReminderFrequency(event.target.value.toLowerCase())}
              >
                {['weekly', 'fortnightly', 'monthly'].map((option, idx) => (
                  <MenuItem value={option} key={`Option ${idx}`}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
              style={{
                marginTop: '24px',
              }}
            >
              <Grid item>
                <Button onClick={onScheduleSubmit}>
                  Set reminder
                </Button>
              </Grid>
              <Grid item>
                <Button variant="secondary" onClick={onClearSchedule}>
                  {scheduleFeedback === null ? ('Turn off reminders') : (`${scheduleFeedback}`)}
                </Button>
              </Grid>
            </Grid>
            {formError && (
              <Grid item>
                <ErrorText>{formError}</ErrorText>
              </Grid>
            )}
            <br />

            <Modal
              open={modalOpen}
              onClose={closeModal}
              aria-labelledby="simple-modal-title"
            >
              <ModalDiv>
                <ModalClose>
                  <IconButton size="small" onClick={closeModal}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </ModalClose>
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  spacing={3}
                >
                  <Grid item>
                    <SubTitle>
                      <b>Skip assessment reminder?</b>
                    </SubTitle>

                    <DescriptionText>
                      We won&#39;t be able to send you the recommended self-asssessment reminders to reflect your progress.
                    </DescriptionText>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      spacing={3}
                    >
                      <Grid item>
                        <Button
                          fullWidth
                          onClick={closeModal}
                          variant="text"
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button onClick={closeAllModals}>
                          Skip
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ModalDiv>
            </Modal>
          </ThemeProvider>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default RemindersDialog
