import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from '../../constants'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(11),
  },
  contentText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  titleText: {
    marginTop: theme.spacing(1),
  },
  setButton: {
    color: COLORS.veryDarkGray,
    backgroundColor: COLORS.primary,
    width: '300px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    '@media only screen and (min-width: 600px)': {
      width: '260px',
    },
  },
  labelSetButton: {
    fontSize: '15px',
    fontWeight: 'bold',
    textTransform: 'none',
    fontFamily: 'Poppins',
    color: COLORS.veryDarkGray,
  },
  optionButton: {
    width: '270px',
    backgroundColor: 'transparent',
    border: `2px solid ${COLORS.primary}`,
    borderRadius: '10px',
    boxShadow: 'none',
    margin: '0 0 8px 0',
  },
  labelOptionButton: {
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: 1.21,
  },
  selectButton: {
    margin: '0 0 8px 0',
    width: '270px',
    backgroundColor: COLORS.primary,
    border: `2px solid ${COLORS.primary}`,
    borderRadius: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: COLORS.primary,
      border: `2px solid ${COLORS.primary}`,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: COLORS.primary,
      },
    },
  },
  labelSelectButton: {
    fontWeight: 600,
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: 1.21,
  },
  closeModal: {
    color: COLORS.alternateBlack,
    backgroundColor: 'transparent',
    minWidth: '125px',
    marginTop: theme.spacing(3),
    fontSize: '12px',
  },
  labelCloseModal: {
    fontSize: '15px',
    fontWeight: 'bold',
    textTransform: 'none',
    fontFamily: 'Poppins',
    color: COLORS.veryDarkGray,
  },
  backButton: {
    color: COLORS.alternateBlack,
    backgroundColor: '#e3e3e3',
    minWidth: '125px',
    marginTop: theme.spacing(3),
    fontSize: '12px',
    borderRadius: '10px',
  },
  labelBackButton: {
    fontSize: '15px',
    fontWeight: 'bold',
    textTransform: 'none',
    fontFamily: 'Poppins',
    color: COLORS.veryDarkGray,
  },
  hideButton: {
    display: 'none',
  },
}))

export const themeOptions = {
  palette: {
    primary: {
      main: COLORS.primary,
    },
  },
  overrides: {
    MuiTypography: {
      h3: {
        fontSize: '40px',
      },
      h4: {
        fontSize: '35px',
      },
      subtitle1: {
        fontWeight: 600,
      },
    },
    MuiInputBase: {
      input: {
        '@media only screen and (max-width: 600px)': {
          width: '200px',
        },
        '@media only screen and (min-width: 600px)': {
          width: '250px',
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: COLORS.primaryBlue,
        width: '100%',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontWeight: 600,
        minWidth: '90px !important',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#292929',
        backgroundColor: COLORS.primary,
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: COLORS.primary,
          '@media (hover: none)': {
            backgroundColor: COLORS.primary,
          },
          boxShadow: 'none',
        },
      },
      textPrimary: {
        color: COLORS.veryDarkGray,
        backgroundColor: 'transparent',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
          boxShadow: 'none',
        },
      },
    },
  },
}
