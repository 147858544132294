/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CURRENCY_SYMBOLS, REDUX_FETCH_STATUS } from 'constants'
import * as api from 'utils/api'

// https://redux.js.org/tutorials/essentials/part-5-async-logic

const initialState = {
  // Updated by fetchCard Thunk

  // Calculated fields
  cardBalance: '',
  cardBalanceExpiry: '',

  // Stripe Card Props
  object: '',
  brand: '',
  cancellation_reason: null,
  created: null, // number
  currency: '',
  cvc: '',
  exp_month: null,
  exp_year: null, // number
  last4: '',
  livemode: false,
  metadata: {},
  number: '',
  pin: null,
  replaced_by: null,
  replacement_for: null,
  replacement_reason: null,
  shipping: null,
  cardholder: {
    name: '',
    billing: {
      address: {
        city: null,
        line1: null, // address
        postal_code: null,
        state: null,
        country: null,
      },
    },
    spending_controls: {
      allowed_categories: null,
      blocked_categories: null,
      spending_limits: [
        {
          amount: null, // number
          categories: [],
          interval: '',
        },
      ],
      spending_limits_currency: '',
    },
  },
  status: '',
  type: '',
  wallets: {
    apple_pay: {
      eligible: true,
      ineligible_reason: null,
    },
    google_pay: {
      eligible: true,
      ineligible_reason: null,
    },
    primary_account_identifier: null,
  },
  // Additional props
  cardFetchState: REDUX_FETCH_STATUS.idle,
  error: '', // string
  transactions: [],
}

export const createUserCard = createAsyncThunk('/card/createUserCard', (card) => api.userCreateCard(card))

export const fetchCard = createAsyncThunk('/card/fetchCard', async () => api.userGetCard())

export const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    updateCardFetchState: (state, action) => {
      state.cardFetchState = action.payload
    },
  },
  extraReducers(builder) {
    // fetchCard
    builder
      .addCase(fetchCard.pending, (state) => {
        state.cardFetchState = REDUX_FETCH_STATUS.loading
      })
      .addCase(fetchCard.fulfilled, (state, action) => {
        const { card = {}, transactions = [] } = action.payload

        // Get the current card "Balance"
        const thisMonthsTransactions = transactions.filter((t) => {
          const transactionDate = new Date()
          transactionDate.setTime(t.created * 1000)
          const currentDate = new Date()
          return transactionDate.getMonth() === currentDate.getMonth()
          && transactionDate.getFullYear() === currentDate.getFullYear()
        })
        const spentSoFar = thisMonthsTransactions.reduce((acc, curr) => acc + curr.amount, 0)
        const activeSpendingLimit = card.cardholder?.spending_controls?.spending_limits[0]
        const balance = activeSpendingLimit ? activeSpendingLimit.amount + spentSoFar : null
        const currency = card.cardholder?.spending_controls?.spending_limits_currency || ''
        const cardBalance = balance === null ? '' : `${CURRENCY_SYMBOLS[currency] || currency}${(balance / 100).toFixed(2)}`

        Object.assign(
          state,
          {
            ...card,
            transactions,
            cardBalance,
            cardFetchState: REDUX_FETCH_STATUS.succeeded,
            cardBalanceExpiry: activeSpendingLimit?.interval,
          },
        )
      })
      .addCase(fetchCard.rejected, (state, action) => {
        console.error(action.error.message)
        state.cardFetchState = REDUX_FETCH_STATUS.failed
        state.error = action.error.message
      })
  },
})

export const {
  updateCardFetchState,
} = cardSlice.actions

export default cardSlice.reducer
