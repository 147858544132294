import React, { useEffect, useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { Link, useNavigate } from 'react-router-dom'
import { HelperText, Title, ErrorMessage } from 'components/Typography'
import {
  userLoginV2, isValidEmailBoolean, saveSession, userRegisterUnverified, deleteSession,
} from 'utils'
import SyndiLogo from 'components/SyndiLogo'
import Button from 'components/Button'
import { COLORS } from 'constants'
import GoogleSignInButton from '../../assets/img/icons/googleSignIn.png'
import config from '../../config'

export default function SignIn({ errorParam }) {
  const navigate = useNavigate()

  // Local state
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')

  const referralCode = config.appReferralCode

  const handleEmailLogin = async () => {
    try {
      if (!email) {
        setEmailError('Please enter your email address')
      }
      if (!isValidEmailBoolean(email)) {
        setEmailError('Please enter a valid email address')
      }
      if (!password) {
        setPasswordError('Please enter a password')
      }
      if (email && password && isValidEmailBoolean(email)) {
        const { token: unverifiedToken } = await userRegisterUnverified()
        const {
          userId, token, organisationId,
        } = await userLoginV2(unverifiedToken, email, null, password)

        saveSession(userId, token)

        // Identify for HotJar recording
        window.hj('identify', userId, { organisationId, email })

        navigate('/home')
      }
    } catch (error) {
      deleteSession()
      setErrorMessage(error.message)
    }
  }

  useEffect(() => {
    if (errorParam === 'google') {
      setErrorMessage('Sign in with Google Failed. You may be using an email that is not whitelisted by your organisation admin.')
    }
  }, [errorParam])

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: '100vh',
        backgroundColor: COLORS.babyBlue,
      }}
    >
      <Grid
        item
        xs={10}
        md={6}
      >
        <Grid
          container
          direction="column"
          alignItems="stretch"
          justifyContent="center"
          spacing={2}
          style={{
            borderRadius: '8px',
            background: '#F7FBFE',
            boxShadow: '0px 20px 70px 0px rgba(40, 56, 126, 0.14)',
            padding: '32px',
          }}
        >
          <Grid item>
            <SyndiLogo />
          </Grid>
          <Grid item>
            <Title>Welcome back 👋</Title>
          </Grid>
          <Grid item>
            <TextField
              InputLabelProps={{ }}
              InputProps={{ }}
              variant="outlined"
              placeholder="Email Address"
              label="Email Address"
              error={!!emailError}
              helperText={emailError}
              onChange={(e) => {
                setEmailError('')
                setEmail(e.target.value)
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              InputLabelProps={{ }}
              InputProps={{ }}
              variant="outlined"
              placeholder="Password"
              type="password"
              label="Password"
              error={!!passwordError}
              helperText={passwordError}
              onChange={(e) => {
                setPasswordError('')
                setPassword(e.target.value)
              }}
            />
            <HelperText>
              Forgotten password?
              {' '}
              <Link
                to={{ pathname: '/forgot-password' }}
                style={{
                  fontSize: '10px',
                  textDecoration: 'underline',
                }}
              >
                Click here
              </Link>
            </HelperText>
          </Grid>
          <Grid item>
            <Button variant="primary" onClick={handleEmailLogin}>Continue</Button>
          </Grid>
          <Grid item>
            <a
              href={`${config.domains.api}/auth/login/google/${referralCode}`}
            >
              <img
                alt="Sign in with Google"
                style={{
                  marginTop: '20px',
                  marginBottom: '0px',
                  height: '50px',
                }}
                src={GoogleSignInButton}
              />
            </a>
          </Grid>
          {errorMessage && (
            <Grid item>
              <ErrorMessage style={{
                maxWidth: '250px',
              }}
              >
                {errorMessage}
              </ErrorMessage>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
