import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  FiLifeBuoy,
  FiBarChart2, FiCreditCard, FiEdit, FiHeart, FiUsers,
  FiUser,
} from 'react-icons/fi'
import { MdOutlineEmail } from 'react-icons/md'
import NavbarMobile from 'components/NavbarMobile'
import NavbarDesktop from 'components/NavbarDesktop'

export default function Navbar({ mobile }) {
  const location = useLocation()

  // Global State Selectors
  const organisationHealthSurveys = useSelector((state) => state.organisation.organisationHealthSurveys)
  const stripeConnectId = useSelector((state) => state.organisation.stripeConnectId)
  const recommendationsData = useSelector((state) => state.recommendations.recommendationsData)

  // Which Navbar Tabs To Show
  const showHealthTrends = organisationHealthSurveys.length > 0
  const showRecommendations = Object.keys(recommendationsData || {}).length > 0
  const showBudgetTab = !!stripeConnectId
  const showHealthEducation = true

  const selectNavItem = (path) => {
    window.hj('event', `navbar for ${path} clicked`)
  }

  const NavMenuTop = [
    {
      name: 'Home Page',
      icon: <FiEdit />,
      id: 1,
      link: '/home',
      onClick: () => selectNavItem('/home'),
    },
    showHealthTrends ? {
      name: 'Health Trends',
      icon: <FiBarChart2 />,
      id: 2,
      link: '/insights',
      onClick: () => selectNavItem('/insights'),
    } : null,
    showHealthEducation ? {
      name: 'Health Education',
      icon: <FiUsers />,
      id: 3,
      link: '/health-education',
      onClick: () => selectNavItem('-education'),
    } : null,
    showRecommendations ? {
      name: 'Your Health Services',
      icon: <FiHeart />,
      id: 4,
      link: '/recommendations',
      onClick: () => selectNavItem('/recommendations'),
    } : null,
    showBudgetTab ? {
      name: 'My Budget',
      icon: <FiCreditCard />,
      id: 4,
      link: '/card',
      onClick: () => selectNavItem('/card'),
    } : null].filter((i) => i)

  const NavMenuBottom = [
    {
      name: 'Profile',
      icon: <FiUser />,
      id: 1,
      link: '/profile',
      onClick: () => selectNavItem('/profile'),
    }, {
      name: 'Contact Us',
      icon: mobile ? <FiLifeBuoy /> : <MdOutlineEmail />,
      id: 2,
      link: 'https://forms.gle/pp81Q57ny8jS4FEE6',
      onClick: () => selectNavItem('/contact-us'),
    }]

  return mobile
    ? (
      <NavbarMobile
        locationPathName={location.pathname}
        selectNavItem={selectNavItem}
        primaryNavMenu={NavMenuTop}
        secondaryNavMenu={NavMenuBottom.reverse()}
      />
    )
    : (
      <NavbarDesktop
        locationPathName={location.pathname}
        selectNavItem={selectNavItem}
        primaryNavMenu={NavMenuTop}
        secondaryNavMenu={NavMenuBottom}
      />
    )
}
