import React, { useEffect, useState } from 'react'
import styled from 'styled-components'/* eslint-disable no-param-reassign */
import { updateUserSearchInput } from 'reducers/usersReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OpenAILogo from '../../assets/img/logos/OpenAI.svg'
import { COLORS } from '../../constants'

const SearchAreaContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  justify-content: center;
`

const HeaderText = styled.h1`
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${COLORS.darkNavyBlue};
  @media (max-width: 600px) {
    font-size: 24px;
  }
`

const SearchBar = styled.input`
  width: 488px;
  height: 50px;
  border-radius: 16px;
  background-color: ${({ isTyping }) => (isTyping ? COLORS.darkerGrey : COLORS.altGrey)};
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 600px) {
    width: 90%;
    font-size: 12px;
  }
  ::placeholder {
    color: #a6afd6;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #a6afd6;
  }

  ::-ms-input-placeholder {
    color: #a6afd6;
  }
`

const SubHeaderText = styled.h3`
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: normal;
  color: ${COLORS.darkNavyBlue};
`

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 30px auto 10px auto;
  flex-wrap: wrap;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const Tab = styled.button`
  background-color: ${({ selected }) => (selected ? '#ebfff1' : 'white')};
  border: ${({ selected }) => (selected ? '1px dashed #65f091' : '1px dashed #5161aa')};
  height: 58px;
  padding: 8px 24px;
  border-radius: 8px;
  margin-right: 5px;
  margin-top: 7px;
  font-size: 14px;
  font-family: Poppins;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #5161aa;
  cursor: pointer;
  white-space: normal;
  &:hover {
    background-color: #ebfff1;
    border: 1px dashed #65f091
  }
`

export default function SearchArea() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [inputValue, setInputValue] = useState('')
  const userSearchInput = useSelector((state) => state.user.userSearchInput)
  const providedRecommendations = [
    'I want to get some good sleep',
    'I want to be fitter to improve my self-confidence',
    'How can I improve my diet?',
    'I need to speak to an online GP',
    'What can I do to relieve my back pain?',
    'I\'m feeling stressed and anxious',
  ]

  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateUserSearchInput(inputValue))
    navigate('/recommendations')
  }

  const handleProvidedRecommendationsClick = (text) => {
    dispatch(updateUserSearchInput(text))
    navigate('/recommendations')
  }

  useEffect(() => {
    if (userSearchInput !== '') {
      setInputValue('')
      dispatch(updateUserSearchInput(''))
    }
  }, [])

  return (
    <>
      <SearchAreaContainer>
        <HeaderText>What can we help you with?</HeaderText>
        <form onSubmit={submitHandler}>
          <SearchBar
            type="text"
            isTyping={inputValue !== ''}
            placeholder="Ask Syndi Everything"
            value={inputValue}
            onChange={handleChange}
          />
        </form>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 5px',
          gap: '10px',
        }}
        >
          <SubHeaderText>Powered by</SubHeaderText>
          <img src={OpenAILogo} alt="OpenAI Logo" style={{ marginTop: '15px' }} />
        </div>
      </SearchAreaContainer>
      <br />
      <div>
        <SubHeaderText>Or try these...</SubHeaderText>
        <TabsContainer>
          {providedRecommendations.map((text) => (
            <Tab
              key={text}
              onClick={() => handleProvidedRecommendationsClick(text)}
              selected={inputValue.includes(text)}
            >
              {text}
            </Tab>
          ))}
        </TabsContainer>
      </div>
    </>
  )
}
