const style = {
  overrides: {
    MuiCardActions: {
      root: {
        padding: 0,
      },
    },
    MuiAccordion: {
      root: {
        '&$expanded': {
          margin: '0px 0',
        },
      },
    },
    MuiButton: {
      root: {
        color: '#292929',
        borderRadius: '8px',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#5fcd81',
        },
      },
      outlinedPrimary: {
        color: '#2f455c',
        border: 'solid 2px #2f455c',
        height: '47px',
        '&:hover': {
          backgroundColor: 'transparent',
          border: 'solid 2px #2f455c',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      containedPrimary: {
        color: '#292929',
        backgroundColor: '#5fcd81',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#5fcd81',
          '@media (hover: none)': {
            backgroundColor: '#5fcd81',
          },
          boxShadow: 'none',
        },
      },
      contained: {
        color: '#292929',
        height: '47px',
        backgroundColor: '#5fcd81',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#5fcd81',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      textSecondary: {
        color: '#383838',
        height: '47px',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
          boxShadow: 'none',
        },
      },
    },
  },
}

export default style
