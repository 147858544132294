import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getSession, deleteSession } from 'utils'

export default function SignOut() {
  const navigate = useNavigate()

  useEffect(() => {
    if (getSession()) deleteSession()
    navigate('/signin')
  }, [])

  return (
    <></>
  )
}
