import React, { useEffect, useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { Link, useNavigate } from 'react-router-dom'
import {
  SubHeader, HelperText, Title,
} from 'components/Typography'
import ErrorText from 'components/ErrorText'
import config from 'config'
import SyndiLogo from 'components/SyndiLogo'
import Button from 'components/Button'
import { COLORS } from 'constants'
import {
  has1LowerCase,
  has1Number,
  has1UpperCase,
  hasLessThan30Characters,
  has8Characters,
  hasSpecialCharacter,
  isValidPassword, saveSession, userLoginV2, userRegisterUnverified, userRegisterV2, isValidEmail,
} from '../../utils'

export default function SignUp({ refCode, email }) {
  const navigate = useNavigate()

  // Local State
  const [referralCodeInput, setReferralCodeInput] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [emailFieldDisabled, setEmailFieldDisabled] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPw, setConfirmPw] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [customLandingPageData, setData] = useState({
    primaryColor: '',
    secondaryColor: '',
    logoUrl: '',
    headerText: '',
    descriptionText: '',
    imageUrl: '',
    logoCentered: false,
    logoRedirectUrl: '',
  })

  const handleCreateNewUser = async (email, referralCode, password) => {
    const { token: unverifiedToken } = await userRegisterUnverified()

    // Register the user
    await userRegisterV2(unverifiedToken, referralCode, email, null, password)
    const { userId, token } = await userLoginV2(unverifiedToken, email, null, password)
    saveSession(userId, token)
    navigate('/verification', { state: { email } })
  }

  const handleSubmit = async () => {
    try {
      isValidEmail(userEmail)
      isValidPassword(password)
      if (password !== confirmPw) throw new Error('Passwords must match!')
      await handleCreateNewUser(userEmail, referralCode, password)
    } catch (error) {
      console.error(error)
      setErrorMessage(error?.message)
    }
  }

  const setCustomLandingPageData = async (referralCode) => {
    const response = await fetch(`${config?.landingPageBaseUrl}${referralCode}/config.json`)
    if (response.status !== 200) throw new Error('Could not retrieve landing page config!')
    const landingPageData = await response.json()
    return landingPageData
  }

  useEffect(() => {
    if (email) {
      setUserEmail(email)
      setEmailFieldDisabled(true)
    }
    if (refCode) setReferralCode(refCode)
  }, [])

  useEffect(() => {
    if (!referralCode) return
    (async function fetchData() {
      setData(await setCustomLandingPageData(referralCode))
    }())
  }, [referralCode])

  if (!referralCode) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: '100vh',
          backgroundColor: COLORS.babyBlue,
        }}
      >
        <Grid
          item
          xs={10}
          md={4}
          container
          spacing={2}
          direction="column"
          style={{
            borderRadius: '8px',
            background: '#F7FBFE',
            boxShadow: '0px 20px 70px 0px rgba(40, 56, 126, 0.14)',
            padding: '64px',
          }}
        >
          <Grid item>
            <SyndiLogo />
          </Grid>
          <Grid item>
            <Title>Enter your company’s referral code</Title>
          </Grid>
          <Grid item>
            <TextField
              InputLabelProps={{ }}
              InputProps={{ }}
              variant="outlined"
              placeholder="Referral code"
              label="Referral code"
              onChange={(e) => {
                setReferralCodeInput(e.target.value)
              }}
            />
          </Grid>
          <Grid item>
            <Button onClick={() => setReferralCode(referralCodeInput)} type="submit">
              Continue
            </Button>
          </Grid>
          <Grid item>
            <HelperText>
              Don’t remember your comppany’s referral code?
              {' '}
              <Link
                to="/signin"
                style={{
                  fontSize: '10px',
                  textDecoration: 'underline',
                }}
              >
                Click here!
              </Link>
            </HelperText>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: '100vh',
        paddingTop: '32px',
        backgroundColor: COLORS.babyBlue,
      }}
    >
      <Grid
        item
        xs={10}
        md={6}
        container
        direction="column"
        style={{
          paddingTop: '32px',
        }}
      >
        { customLandingPageData.logoUrl ? (
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundColor: customLandingPageData.secondaryColor,
              height: '135px',
              borderRadius: '8px',
              animation: 'fadeIn 3s ease',
            }}
          >
            <Grid item>
              {customLandingPageData.logoUrl ? (
                <img
                  height="50px"
                  alt="the word syndi in blue"
                  src={customLandingPageData.logoUrl}
                />
              ) : (<SyndiLogo />)}
            </Grid>
          </Grid>
        ) : null}

        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          style={{
            borderRadius: '8px',
            marginTop: '32px',
            boxShadow: '0px 20px 70px 0px rgba(40, 56, 126, 0.14)',
          }}
        >
          {/* Left hand side */}
          <Grid
            item
            md={6}
            container
            direction="column"
            style={{
              padding: '62px 40px 40px 40px',
              backgroundColor: '#F7FBFE',
            }}
          >

            <Grid item>
              <SyndiLogo />
              <Title>Welcome to Syndi 👋</Title>
            </Grid>

            <Grid item>
              <SubHeader>✅ Health check-ups, swift & sound</SubHeader>
              <HelperText>Quick, expert-backed questionnaires to keep your health & wellbeing in check.</HelperText>
            </Grid>

            <Grid item>
              <SubHeader>👌 Tailored health & wellbeing picks</SubHeader>
              <HelperText>Cut through the clutter with health & wellbeing solutions that match your pace and your preferences.</HelperText>
            </Grid>

            <Grid item>
              <SubHeader>😎 Your employers treat</SubHeader>
              <HelperText>Dive into a range of wellness services, funded by your employer.</HelperText>
            </Grid>

            <Grid item>
              <SubHeader>🥷 Privacy, non-negotiable</SubHeader>
              <HelperText>Your details stay under wraps. That’s our pact with you.</HelperText>
            </Grid>
          </Grid>

          {/* Right hand side */}
          <Grid
            item
            md={6}
          >
            <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="flex-end"
              style={{ padding: '42px 40px 40px' }}
            >
              <Grid item>
                <SubHeader>Start your journey by creating your account</SubHeader>
              </Grid>

              <Grid item>
                <TextField
                  variant="outlined"
                  placeholder="Email Address"
                  label="Email Address"
                  value={userEmail || ''}
                  disabled={emailFieldDisabled}
                  onChange={(e) => {
                    setUserEmail(e.target.value)
                    setErrorMessage('')
                  }}
                />
              </Grid>

              <Grid item>
                <TextField
                  InputLabelProps={{ }}
                  InputProps={{ }}
                  variant="outlined"
                  placeholder="Set your password"
                  label="Set Password"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value)
                    setErrorMessage('')
                  }}
                />
              </Grid>
              {password && (
              <Grid
                item
                style={{
                  borderRadius: '4px',
                  border: '1px solid #ABB4BB',
                  width: '215px',
                  marginLeft: '8px',
                }}
              >
                <HelperText style={{ padding: '8px' }}>
                  Your password must contain:
                  <HelperText style={{ color: has8Characters(password) ? '#00A343' : '#FF0000' }}>
                    {has8Characters(password) ? '✅' : '❌'}
                    {' '}
                    At least 8 characters
                  </HelperText>
                  <HelperText style={{ color: hasLessThan30Characters(password) ? '#00A343' : '#FF0000' }}>
                    {hasLessThan30Characters(password) ? '✅' : '❌'}
                    {' '}
                    Less than 30 characters
                  </HelperText>
                  <HelperText style={{ color: has1UpperCase(password) ? '#00A343' : '#FF0000' }}>
                    {has1UpperCase(password) ? '✅' : '❌'}
                    {' '}
                    1 uppercase letter
                  </HelperText>
                  <HelperText style={{ color: has1LowerCase(password) ? '#00A343' : '#FF0000' }}>
                    {has1LowerCase(password) ? '✅' : '❌'}
                    {' '}
                    1 lowercase letter
                  </HelperText>
                  <HelperText style={{ color: has1Number(password) ? '#00A343' : '#FF0000' }}>
                    {has1Number(password) ? '✅' : '❌'}
                    {' '}
                    1 number
                  </HelperText>
                  <HelperText style={{ color: hasSpecialCharacter(password) ? '#00A343' : '#FF0000' }}>
                    {hasSpecialCharacter(password) ? '✅' : '❌'}
                    {' '}
                    1 special character
                  </HelperText>
                </HelperText>
              </Grid>
              )}

              <Grid item>
                <TextField
                  InputLabelProps={{ }}
                  InputProps={{ }}
                  variant="outlined"
                  placeholder="Confirm your password"
                  label="Confirm Password"
                  type="password"
                  onChange={(e) => {
                    setConfirmPw(e.target.value)
                    setErrorMessage('')
                  }}
                />
              </Grid>

              <Grid item>
                <Button onClick={handleSubmit} type="submit">
                  Sign up now!
                </Button>
              </Grid>

              {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

              <Grid item>
                <HelperText>
                  Already have an account?
                  {' '}
                  <Link
                    to={{ pathname: '/signin' }}
                    style={{
                      fontSize: '10px',
                      textDecoration: 'underline',
                    }}
                  >
                    Sign in
                  </Link>
                </HelperText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  )
}
