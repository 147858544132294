import React from 'react'
import {
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  AreaChart,
} from 'recharts'
import styled from 'styled-components'
import './styles.css'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { COLORS } from '../../constants'

const CustomTooltipDiv = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
  padding: 8px;
  max-width: 100px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(117, 117, 117, 0.5);
  background-color: ${COLORS.darkNavyBlue};
`
const CustomTooltipP = styled.p`
  color: ${COLORS.white};
  font-size: 10pt;
  margin: 1px 0 0 0;
  overflow-wrap: break-word;
`

const CustomTooltipH = styled.p`
  color: ${COLORS.white};
  margin: 0px;
  font-size: 10pt;
  font-weight: bold;
  overflow-wrap: break-word;
`

const CustomTooltipDate = styled.p`
  color: ${COLORS.white};
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10pt;
  text-align: center;
`

const MainContainer = styled.div`
  height: 100%;
  padding-top: 30px;
`

// const Ytick = styled.text`
//   font-size: 12px;
//   font-family: Poppins;
// `

const capitalise = (text = '') => text.charAt(0).toUpperCase() + text.slice(1)

const CustomTooltip = (obj) => (
  <CustomTooltipDiv>
    <CustomTooltipDate>
      {moment(obj.label).format('MMM DD YYYY')}
    </CustomTooltipDate>
    {(obj.payload || []).map((p, idx) => (
      <div key={`ka:${idx + 1}`}>
        <CustomTooltipH className="label">
          {`${capitalise(p.payload.name || '')}`}
        </CustomTooltipH>
        <CustomTooltipP className="label">
          {capitalise(p.payload.level)}
        </CustomTooltipP>
      </div>
    ))}
  </CustomTooltipDiv>
)

export default function LineChartContainer({
  health,
  negativeScoreBoundary = 0,
  positiveScoreBoundary = 100,
}) {
  const data = health

  // The timestamp should be converted and pushed pach in the object (map)
  const containerWidth = () => {
    if (isMobile && health.length < 4) {
      return '90%'
    } if (!isMobile && health.length < 6) {
      return '90%'
    }
    return '90%'
  }

  const height = 200
  const colorId = Math.random().toString(36).substring(7)
  const reversed = (negativeScoreBoundary > positiveScoreBoundary)
  const startColor = reversed ? COLORS.graphRed : COLORS.altLightGreen
  const endColor = reversed ? COLORS.altLightGreen : COLORS.graphRed

  return (
    <MainContainer>
      <ResponsiveContainer width={containerWidth()} height={height}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id={colorId} x1="0" y1="0" x2="0" y2="1">
              <stop offset="20%" stopColor={startColor} stopOpacity={1} />
              <stop offset="80%" stopColor={endColor} stopOpacity={1} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="score"
            fillOpacity={1}
            fill={`url(#${colorId})`}
            stroke={COLORS.darkerGrey}
            strokeWidth={2}
            dot={{
              strokeWidth: 3,
              stroke: COLORS.darkNavyBlue,
            }}
            activeDot
          />
          <XAxis
            tickLine={false}
            dataKey="date"
            axisLine={{
              stroke: COLORS.darkerGrey,
              strokeWidth: 2,
            }}
            style={{
              fontSize: '10px',
              letterSpacing: '0.42px',
              fontFamily: 'Poppins',
            }}
            type="category"
            tickFormatter={(dat) => moment(dat).format('MMM DD')}
            domain={['auto', 'auto']}
          />
          <YAxis
            axisLine={{
              stroke: COLORS.darkerGrey,
              strokeWidth: 1,
            }}
            style={{
              fontSize: '10px',
              letterSpacing: '0.42px',
              fontFamily: 'Poppins',
            }}
            tickLine={false}
            domain={[0, Math.max(negativeScoreBoundary, positiveScoreBoundary)]}
            // domain={domain}
            // tick={renderCustomizedYTick}
            // tickCount={2}
          />
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid
            vertical
            strokeWidth={1}
            stroke={COLORS.darkerGrey}
            strokeDasharray="5 5"
            // horizontalPoints={[height / 4, (2 * height) / 4, (3 * height) / 4, 1]}
            // horizontalFill={['none', '#F8E3E3', '#fcf4f4', '#f0fbf4', '#DFF5E6']}
          />
        </AreaChart>
      </ResponsiveContainer>
    </MainContainer>
  )
}
