import React from 'react'
import Navbar from 'components/Navbar'
import { isMobile } from 'react-device-detect'
import { Grid } from '@material-ui/core'
import { COLORS } from 'constants'

export default function Layout({ children, hideNavbar }) {
  const navbarDesktopWidth = '280px'

  return (
    <Grid
      container
      direction={isMobile ? 'column' : 'row'}
      style={{ backgroundColor: COLORS.darkerNavyBlue }}
    >
      {!hideNavbar && (
        <Grid
          item
          style={{
            backgroundColor: COLORS.darkerNavyBlue,
            zIndex: 100,
            padding: '16px',
            width: isMobile ? '100%' : navbarDesktopWidth,
            minHeight: isMobile ? '72px' : '100vh',
            overflow: 'auto',
            position: 'absolute',
          }}
        >
          <Navbar mobile={isMobile} />
        </Grid>
      )}

      <Grid
        item
        style={{
          width: '100%',
          backgroundColor: COLORS.white,
          marginLeft: hideNavbar ? '0px' : isMobile ? '0px' : navbarDesktopWidth,
          marginTop: hideNavbar ? '0px' : isMobile ? '72px' : '0px',
          padding: hideNavbar ? '0px' : isMobile ? '8px' : '64px',
          transition: 'all 0.2s ease',
        }}
      >
        {children}
      </Grid>
    </Grid>
  )
}
