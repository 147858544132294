import React from 'react'
import NavbarAnonymity from 'components/NavbarAnonymity'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { COLORS } from 'constants'
import styled from 'styled-components'
import NavbarSyndiCard from 'components/NavbarSyndiCard'
import SyndiLogo from '../../assets/img/logos/syndi-logo-white.webp'

const Logo = styled.img`
  width: 70px;
  height: 34px;
  margin-top: 5px;
`

const Icon = styled.span`
  color: ${COLORS.white};
  height: 18px;
  width: 18px;
  margin: 0px 15px 8px 5px;
`

const NavText = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: ${({ selected }) => (selected ? '600' : '500')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${COLORS.white};

  &:hover {
    color: ${COLORS.white};
    font-weight: 600;
  }

  &:hover ${Icon} {
    color: ${COLORS.white};
  }
`

const NavButton = styled.button`
  width: 248px;
  padding: 16px 24px 16px 24px;
  border: none;
  border-radius: 4px;
  background-color: ${({ selected }) => (selected ? COLORS.darkPurple : 'transparent')};
  cursor: pointer;
`

export default function NavbarDesktop({
  locationPathName, primaryNavMenu, secondaryNavMenu,
}) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Grid item>
        <Link to="/home">
          <Logo src={SyndiLogo} />
        </Link>

        <Grid
          container
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
        >
          {primaryNavMenu.map((item, idx) => (
            <Link
              key={`${idx}u`}
              to={item.link}
            >
              <NavButton
                selected={locationPathName === item.link}
                onClick={item.onClick}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Icon>{item.icon}</Icon>
                  <NavText>{item.name}</NavText>
                </Grid>
              </NavButton>
            </Link>
          ))}
        </Grid>
      </Grid>

      <Grid item>
        <NavbarSyndiCard />
      </Grid>

      <Grid item>
        <NavbarAnonymity />
      </Grid>

      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          style={{ marginBottom: '16px' }}
        >
          {secondaryNavMenu.map((item, idx) => (
            <Link
              to={item.link}
              key={`${idx}u`}
            >
              <NavButton
                selected={locationPathName === item.link}
                onClick={item.onClick}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Icon>{item.icon}</Icon>
                  <NavText>{item.name}</NavText>
                </Grid>
              </NavButton>
            </Link>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
