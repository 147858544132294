import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BsExclamationTriangle } from 'react-icons/bs'
import LineChartContainer from 'components/LineChart'
import ButtonPrimary from 'components/Button'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import { useSelector } from 'react-redux'
import RemindersDialog from 'components/RemindersDialog'
import { COLORS } from '../../constants'

const ButtonWrapper = styled.div`
    margin: 0 0 -10px 0;
`

const NoDataWrapper = styled.div`
    background-color: rgb(47, 69, 92, 0.5);
    z-index: 1;
    width: 370px;
    height: 300px;
    margin: 0 0px 0 -640px;
    padding: 80px 122px 0 150px;
    border-radius: 20px;

    // mobile
    @media only screen and (max-width: 600px) {
      width: 84%;
      height: 300px;
      margin: -375px 0 0 0px;
      z-index: 1;
      padding: 75px 0 0 70px;
    }

    // tablet
    @media only screen and (min-width: 600px and max-width: 992px) {
      width: 78.5%;
      height: 300px;
      z-index: 1;
      padding: 75px 0 0 70px;
    }
`

const NoData = styled.div`
    width: 320px;
    height: 165px;
    display: flex;
    opacity: 1;
    flex-flow: column;
    align-items: center;
    margin: 0;
    z-index: 2;
    padding: 20px 24.1px 25px 8.9px;
    border-radius: 10px;
    background-color: ${COLORS.primaryBlue};

    // mobile
    @media only screen and (max-width: 600px) {
      width: 160px;
      z-index: 2;
      padding: 20px 25px 0 20px;
      margin-right: 20px;
    }
`

const ModalHeader = styled.h1`
    height: 17px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.primary};

    // mobile
    @media only screen and (max-width: 600px) {
      font-size: 9px;
    }
`

const ModalText = styled.p`
    width: auto;
    height: 42px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.white};

    // mobile
    @media only screen and (max-width: 600px) {
        font-size: 10px;
    }
`

export default function ProgressChart({
  userHealth = [],
  negativeScoreBoundary = 0,
  positiveScoreBoundary = 100,
}) {
  const [displayDate, setDisplayDate] = useState()
  const currentSchedule = useSelector((state) => state.user.currentSchedule)
  const isVerified = useSelector((state) => state.user.verified)
  const firstTimeUser = useSelector((state) => state.user.firstTimeUser)

  const [openDialogReminders, setOpenDialogReminders] = useState(false)

  useEffect(() => {
    if (currentSchedule?.checkupTime) {
      setDisplayDate(
        moment(currentSchedule.checkupTime)
          .format('dddd MMMM Do'),
      )
    }
  }, [currentSchedule])

  const formatTime = () => {
    if (!currentSchedule?.checkupTime) return

    return moment(currentSchedule.checkupTime).format('ha')
  }

  if (!firstTimeUser) {
    return (
      <LineChartContainer
        health={userHealth}
        negativeScoreBoundary={negativeScoreBoundary}
        positiveScoreBoundary={positiveScoreBoundary}
      />
    )
  }

  return (
    <NoDataWrapper>
      <NoData>
        <ModalHeader>
          <BsExclamationTriangle style={{ margin: '0 8.4px -1.5px 0' }} />
          { currentSchedule?.checkupTime && isVerified ? 'Two assessments are required for trend data' : 'No trend data available' }
        </ModalHeader>
        {currentSchedule?.checkupTime && isVerified
          ? (
            <ModalText style={{ maxWidth: '270px' }}>
              Your next assessment is set for
              {' '}
              <b>
                {formatTime()}
                {' '}
                on
                {' '}
                {currentSchedule ? displayDate : null}
              </b>
            </ModalText>
          ) : (
            <ModalText style={{ fontWeight: 600 }}>
              You&apos;ll need to complete another self assessment to track progress over time
            </ModalText>
          )}
        <ButtonWrapper>
          { currentSchedule?.checkupTime ? (
            <Link to="/questions">
              <ButtonPrimary
                color={COLORS.white}
                bgColor={COLORS.textColor}
                hoverBackgroundColor={COLORS.primaryGreen}
                hoverColor={COLORS.navy}
                border="transparent"
                radius="25px"
                width={isMobile ? '170px' : 'auto'}
                margin="0"
                fontSize={isMobile ? '9px' : '14px'}
                height={isMobile ? '30px' : null}
                padding={isMobile ? '0 10px' : null}
                onClick={() => { window.hj('event', 'Insight - Progress Chart - Start new assessment button clicked') }}
              >
                {' '}
                Start New Assessment Now
              </ButtonPrimary>
            </Link>
          ) : (
            <ButtonPrimary
              color={COLORS.white}
              bgColor={COLORS.textColor}
              hoverBackgroundColor={COLORS.primaryGreen}
              hoverColor={COLORS.navy}
              border="transparent"
              radius="25px"
              width={isMobile ? '170px' : 'auto'}
              margin="0 0 0 10px"
              fontSize={isMobile ? '9px' : '14px'}
              height={isMobile ? '30px' : null}
              padding={isMobile ? '0 10px' : null}
              onClick={() => setOpenDialogReminders(() => true)}
            >
              Set a reminder
            </ButtonPrimary>
          )}
          <RemindersDialog
            openDialog={openDialogReminders}
            onCloseDialog={() => setOpenDialogReminders(false)}
          />
        </ButtonWrapper>
      </NoData>
    </NoDataWrapper>
  )
}
