import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@material-ui/core/Modal'
import { Grid } from '@material-ui/core'
import InputBox from 'components/InputBox'
import ErrorText from 'components/ErrorText'
import { Text, SubText } from 'components/Welcome/index'
import { ReactComponent as WindowCloseSvg } from 'assets/img/window-close.svg'
import Button from 'components/Button'
import RemindersDialog from 'components/RemindersDialog'
import { fetchUser, updateUserPassword, deleteUser } from 'reducers/usersReducer'
import { Title } from 'components/Typography'
import { useNavigate } from 'react-router-dom'
import Loading from 'fragments/Loading'
import { COLORS, REDUX_FETCH_STATUS } from '../../constants'
import * as style from './Profile.style'

export default function Profile() {
  // Global Redux State
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    userId, userFetchState, organisationId,
    email, phoneNumber, checkupTime, preferredReminder,
    reminderFrequency,
  } = useSelector((state) => state.user)

  // Local Component State
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [formError, setFormError] = useState(null)
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false)
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [currentSchedule, setCurrentSchedule] = useState({})
  const [openDialogReminders, setOpenDialogReminders] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const onCloseRemindersDialog = () => setOpenDialogReminders(() => false)

  /**
   * Handles form submission
   * @param {object} evt
   */
  const updatePassword = async (evt) => {
    evt.preventDefault()

    if (!password) {
      setFormError('Please enter a password!')
    } else if (!password || password !== passwordConfirmation) {
      setFormError('Password mismatch!')
    } else if (password.length < 7) {
      setFormError('Password must be greater than 6 characters!')
    } else if (!/\d/.test(password)) {
      setFormError('Password must include a digit!')
    } else if (!/\D/.test(password)) {
      setFormError('Password must include a letter!')
    } else {
      try {
        await dispatch(updateUserPassword({ userId, password })).unwrap()
        navigate('/home')
      } catch (error) {
        if (error.message) setFormError(error.message)
      }
    }
  }

  const logOut = () => {
    window.hj('event', 'logout on /profile clicked')
    navigate('/signout')
  }

  const deleteAccountClick = async () => {
    window.hj('event', 'delete account on /profile clicked')
    await dispatch(deleteUser({ userId })).unwrap()
    setDeleteAccountModalOpen(false)
    navigate('/signout')
  }

  useEffect(() => {
    window.hj('event', 'viewed page syndi.cloud/profile')
  }, [])

  useEffect(() => {
    if (userFetchState === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchUser())
    }
    if (userFetchState !== REDUX_FETCH_STATUS.succeeded) return
    setCurrentSchedule(() => ({
      checkupTime,
      preferredReminder,
      reminderFrequency,
    }))
    setLoaded(true)
  }, [userFetchState, organisationId, email, phoneNumber, checkupTime, preferredReminder, reminderFrequency])

  if (!loaded) return <Loading />

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
    >
      <style.HeaderContainer>
        <Title>Manage your account</Title>
      </style.HeaderContainer>
      {currentSchedule && (
        <Grid item>
          <Button
            variant="tertiary"
            onClick={() => {
              setOpenDialogReminders(() => true)
              window.hj('event', 'manage reminder on /profile clicked')
            }}
          >
            Manage Reminder
          </Button>
        </Grid>
      )}

      <Grid item>
        <Button
          variant="tertiary"
          onClick={() => {
            window.hj('event', 'contact us on /profile clicked')
            window.open('https://forms.gle/pp81Q57ny8jS4FEE6')
          }}
        >
          Contact Us
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="tertiary"
          onClick={() => {
            window.hj('event', 'privacy policy on /profile clicked')
            navigate('/privacy')
          }}
        >
          Privacy Policy
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="tertiary"
          onClick={() => {
            window.hj('event', 'T&Cs on /profile clicked')
            navigate('/terms')
          }}
        >
          T&Cs
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={logOut}
          variant="tertiary"
        >
          Log out
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => navigate('/reset-password')}
          variant="tertiary"
        >
          Change Password
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={(() => setDeleteAccountModalOpen(!deleteAccountModalOpen))}
          variant="tertiary"
        >
          Delete Account
        </Button>
      </Grid>
      <Grid item />
      <RemindersDialog
        openDialog={openDialogReminders}
        onCloseDialog={onCloseRemindersDialog}
      />
      <Modal
        open={Boolean(passwordModalOpen)}
        aria-labelledby="simple-modal-title"
      >
        <style.ModalDiv>
          <style.ModalClose>
            <WindowCloseSvg onClick={(() => setPasswordModalOpen(false))} />
          </style.ModalClose>

          <form onSubmit={updatePassword}>
            <style.InputArea>
              <Text>Update Your Password</Text>
              <SubText>In order to protect your account, make sure your password:</SubText>
              <SubText>• Is longer than 8 characters</SubText>
              <SubText>• Includes at least 1 number</SubText>
              <SubText>• Is not a common password</SubText>
              <style.InputBoxLabel>New Password</style.InputBoxLabel>
              <InputBox
                type="password"
                placeholder="Enter a new password"
                value={password}
                onChange={setPassword}
              />
            </style.InputArea>
            <style.InputArea>
              <style.InputBoxLabel>Confirm Password</style.InputBoxLabel>
              <InputBox
                type="password"
                placeholder="Confirm the new password"
                value={passwordConfirmation}
                onChange={setPasswordConfirmation}
              />
            </style.InputArea>
            {formError && <ErrorText>{formError}</ErrorText>}
          </form>
          <Button
            selectedColor={COLORS.primary}
            unSelectedColor={COLORS.primary}
            onClick={updatePassword}
          >
            Update
          </Button>

        </style.ModalDiv>
      </Modal>
      <Modal
        open={Boolean(deleteAccountModalOpen)}
        aria-labelledby="simple-modal-title"
      >
        <style.ModalDiv>
          <style.ModalClose>
            <WindowCloseSvg
              onClick={(() => setDeleteAccountModalOpen(!deleteAccountModalOpen))}
            />
          </style.ModalClose>

          <Text>Are you sure?</Text>
          <SubText>
            After deletion, you will no longer receive reminders or be able
            to recover any of your wellbeing scores.
          </SubText>
          <Button
            minWidth="100px"
            selectedColor={COLORS.red}
            unSelectedColor={COLORS.red}
            onClick={deleteAccountClick}
          >
            Confirm
          </Button>

        </style.ModalDiv>
      </Modal>
    </Grid>
  )
}
