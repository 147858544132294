import React from 'react'
import styled from 'styled-components'
import { Dialog } from '@material-ui/core'
import MockPhone from '../../assets/img/SyndiPay/SyndiPayPhoneCard.png'
import { COLORS } from '../../constants'

// import 'react-credit-cards/es/styles-compiled.css'
// import Cards from 'react-credit-cards'
// import QRCode from 'qrcode.react'
//
//   <style.CardDisplay>
//     <Cards
//       number={cardNumber}
//       expiry={`${`0${cardExpiryMonth}`.slice(-2)}/${cardExpiryYear}`}
//       cvc={cardCvc}
//       name={cardHolderName}
//       focused="number"
//       issuer="visa"
//     />
//     <QRCode
//       value="shoebox://"
//       size={165}
//     />
//   </style.CardDisplay>
//

const ModalContainer = styled.div`
    width: 371px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    background-color: ${COLORS.darkBlue};

    // mobile
    @media only screen and (max-width: 600px) {
        max-width: 300px;
        height: auto;
    }
`

const HeaderWrapper = styled.div`
    border-radius: 10px 10px 0 0;
    background-color: ${COLORS.primaryBlue};
    margin-top: -12px;

    // mobile
    @media only screen and (max-width: 600px) {
        width: auto;
    }
`

const Header = styled.h1`
    width: 311px;
    height: 20px;
    padding: 25px 20px 25px 40px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.white};

    // mobile
    @media only screen and (max-width: 600px) {
        width: auto;
        font-size: 14px;
    }
`

const Body = styled.div`
    display: flex;
    flex-flow: column;
    margin: 0 0 0 40px;

    // mobile
    @media only screen and (max-width: 600px) {
        padding: 0;
        margin: 0 0 0 30px;
    }
`

const OfferContainer = styled.div`
    display: flex;
    margin-left: 10px;

    // mobile
    @media only screen and (max-width: 600px) {
        margin-left: 25px;
    }
`

const CardImage = styled.img`
    width: 140px;
    height: 150px;

    // mobile
    @media only screen and (max-width: 600px) {
        width: 25%;
        height: 25%;
    }
`

const DescriptionWrapper = styled.div`
    display: flex;

    // mobile
    @media only screen and (max-width: 600px) {
        width: 250px;
    }
`

const SubTitle = styled.h3`
    width: 200px;
    height: 15px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.primary};
`

const Text = styled.p`
    width: 294px;
    height: 35px;
    margin: 0 0 12px 0px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.white};

    // mobile
    @media only screen and (max-width: 600px) {
        width: 250px;
        height: 20px;
        margin-bottom: 35px;
    }
`

const Instructions = styled.ol`
    width: 294px;
    height: auto;
    margin: 0 0 0 -25px;
    padding-right: 50px;

    @media only screen and (max-width: 600px) {
        padding-right: 20px;
    }
`

const ListItem = styled.li`
    margin: 0 0 12px 0px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.white};
`

const CloseButton = styled.button`
    width: 284.6px;
    height: 50.8px;
    margin: 40px 0;
    padding: 0;
    border-radius: 10px;
    border: transparent;
    background-color: ${COLORS.primaryBlue};
    cursor: pointer;

    // mobile
    @media only screen and (max-width: 600px) {
        width: 220px;
        height: 20px;
        padding: 20px;
        margin: 20px 0 30px 10px;
    }
`

const ButtonLabel = styled.p`
    height: 20px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.white};

    '&:hover,&:focus': {
        color: ${COLORS.white};
    }

    // mobile
    @media only screen and (max-width: 600px) {
        margin-top: -6px;
    }
`

export default function AddToWalletModal({
  showModal,
  closeModal,
  platform,
}) {
  return (
    <>
      <Dialog
        open={showModal}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <ModalContainer>
          <HeaderWrapper>
            <Header>
              Add to
              {' '}
              {platform}
              {' '}
              Wallet Instructions
            </Header>
          </HeaderWrapper>
          <OfferContainer>
            <CardImage src={MockPhone} alt="two phones" />
          </OfferContainer>
          <Body>
            <SubTitle>
              {platform}
              {' '}
              Wallet
            </SubTitle>
            <DescriptionWrapper>
              {platform === 'Apple' ? (
                <Instructions>
                  <ListItem>Open the Apple Wallet App on your iPhone.</ListItem>
                  <ListItem>In the top right corner, tap + to add a new card.</ListItem>
                  <ListItem>
                    Tap
                    {' '}
                    <strong>Debit or Credit Card</strong>
                    {' '}
                    and then continue.
                  </ListItem>
                  <ListItem>
                    Tap
                    {' '}
                    <strong>Enter Card Details Manually</strong>
                    {' '}
                    and enter your SyndiCard details.
                  </ListItem>
                  <ListItem>
                    {' '}
                    Apple will take a moment to verify your information with your bank, and then your card will appear within the
                    {' '}
                    <strong>Apple Wallet</strong>
                    {' '}
                    App.
                  </ListItem>
                </Instructions>
              )
                : (
                  <Instructions>
                    <ListItem>Open the Google Wallet app.</ListItem>
                    <ListItem>At the bottom, tap Add to Wallet +.</ListItem>
                    <ListItem>
                      Tap
                      {' '}
                      <strong>Payment Card.</strong>
                    </ListItem>
                    <ListItem>
                      Tap
                      {' '}
                      <strong>New credit or debit card</strong>
                      {' '}
                      and enter your SyndiCard details.
                    </ListItem>
                    <ListItem>
                      At the bottom, tap
                      {' '}
                      <strong>Save.</strong>
                    </ListItem>
                    <ListItem>
                      Read the Issuer Terms and tap
                      {' '}
                      <strong>Accept.</strong>
                    </ListItem>
                    <ListItem>
                      If you&apos;re asked to verify your payment method, choose an option from the list.
                    </ListItem>
                  </Instructions>
                )}
            </DescriptionWrapper>

            <SubTitle>What happens next?</SubTitle>
            <Text>You should now be able to download and pay for Syndi Health recommended health services using your SyndiCard.</Text>

            <CloseButton onClick={() => closeModal()}>
              <ButtonLabel>Close</ButtonLabel>
            </CloseButton>
          </Body>
        </ModalContainer>
      </Dialog>
    </>
  )
}
