import { createTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import HelpIcon from '@material-ui/icons/HelpOutline'
import { COLORS } from '../../constants'

export const CommunityContainer = styled.div`
  background-color: #f4f5f7;
  padding: 20px;
  margin: 10px auto;
  height: 375px;
  width: 100%;
  border-radius: 15px;
  @media (max-width: 600px) {
    padding: 5px;
    width: 97%;
    height: 400px;
  }
`

export const BarChartContainer = styled.div`
  background-color: white;
  padding: 10px;
  height: 275px;
  margin: 0 auto;
  width: 90%;
  border-radius: 15px;
`

export const TitleStyle = styled.h2`
  font-weight: bold;
  @media (max-width: 600px) {
    margin-left: 10px;
  }
`

export const ChartTitleStyle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
`

export const SubText = styled.p`
    cursor: pointer;
    width: 221px;
    height: auto;
    margin: 0 0 5px 0;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    opacity: 0.5;
    color: ${COLORS.primaryBlue};
`

export const HelperIcon = styled(HelpIcon)`
  color: ${COLORS.primaryBlue};
  height: 10px;
  width: 10px;
  margin-left: -3px;
`

export const dashboardTheme = createTheme({
  overrides: {
    MuiCardActions: {
      root: {
        padding: 0,
      },
    },
    MuiAccordion: {
      root: {
        '&$expanded': {
          margin: '0px 0',
        },
      },
    },
    MuiButton: {
      root: {
        color: '#292929',
        borderRadius: '8px',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: COLORS.primary,
        },
      },
      outlinedPrimary: {
        color: COLORS.primaryBlue,
        border: `solid 2px ${COLORS.primaryBlue}`,
        height: '47px',
        '&:hover': {
          backgroundColor: 'transparent',
          border: `solid 2px ${COLORS.primaryBlue}`,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      containedPrimary: {
        color: '#292929',
        backgroundColor: COLORS.primary,
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: COLORS.primary,
          '@media (hover: none)': {
            backgroundColor: COLORS.primary,
          },
          boxShadow: 'none',
        },
      },
      contained: {
        color: '#292929',
        height: '47px',
        backgroundColor: COLORS.primary,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: COLORS.primary,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      textSecondary: {
        color: '#383838',
        height: '47px',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
          boxShadow: 'none',
        },
      },
    },
  },
})
