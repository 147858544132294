/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { isIOS, isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import Price from 'components/Price'
import {
  withStyles, Modal, Typography, Grid, Card, CardMedia,
  CardActionArea, Dialog, DialogContent, DialogTitle, useMediaQuery,
} from '@material-ui/core'
import { ModalDiv } from 'pages/Profile/Profile.style'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import { Title } from 'components/Typography'
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { BiMobile } from 'react-icons/bi'
import { AiFillAndroid, AiFillApple } from 'react-icons/ai'
import { FaDesktop } from 'react-icons/fa'
import InfoDialog from 'components/Dialog'
import MuiIconButton from '@material-ui/core/IconButton'
import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { qrClick } from 'utils'
import DownloadButton from 'components/DownloadButton'
import device from 'device-uuid'
import Button from 'components/Button'
import { DescriptionText, SubTitle } from '../Typography'
import config from '../../config'
import { COLORS } from '../../constants'
import AppBrowserLink from './AppBrowserLink'

const devicePlatform = isMobile ? (isIOS ? 'iOS' : 'android') : 'desktop'

const IconButton = withStyles({
  root: {
    borderRadius: '20%',
  },
})(({ children, ...rest }) => <MuiIconButton {...rest}>{children}</MuiIconButton>)

const useStyles = makeStyles((theme) => ({
  expand: {
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    padding: 0,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    padding: 0,
  },
  discountButton: {
    border: `2px solid ${COLORS.primary}`,
    backgroundColor: COLORS.primary,
    height: '29px',
    color: '#383838',
    borderRadius: '10px',
    marginTop: theme.spacing(1.5),
  },
  downloadButton: {
    marginTop: theme.spacing(),
    color: '#2F455C',
    borderRadius: '10px',
  },
  card: {
    width: '87px',
    borderRadius: '16px',
    boxShadow: '0 2px 4px 0 rgba(117, 117, 117, 0.5)',
    backgroundColor: 'transparent',
  },
  modalCard: {
    width: '80px',
    height: '80px',
    marginRight: '20px',
    borderRadius: '16px',
    boxShadow: '0 2px 4px 0 rgba(117, 117, 117, 0.5)',
    backgroundColor: 'transparent',
  },
  media: {
    height: '87px',
  },
  progressbarLabel: {
    fontFamily: 'Poppins',
    fontSize: '10px',
    color: '#383838',
  },
  dialogBottomSection: {
    backgroundColor: COLORS.lightGrey,
    minWidth: '260px',
  },
  platformIconsWrapper: {
    minWidth: '35px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const dialogTheme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: '10px',
        width: '330px',
      },
    },
  },
})

const AppName = styled.div`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
`

const AppDescription = styled.div`
  font-size: 13px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: ${({ clamp }) => (clamp)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const DownloadLabel = styled(Typography)`
  font-size: 15px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 1.5;

  // mobile
  @media only screen and (max-width: 600px) {
    line-height: 1.5;
  }

  // tablet
  @media only screen and (min-width: 600px) {
    line-height: 1;
  }

  // desktop
  @media only screen and (min-width: 777px) {
    line-height: 1.5;
  }
`

const fadeIn = keyframes`
  from {
    // transform: scale(.25);
    opacity: 0;
  }

  to {
    // transform: scale(1);
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    // transform: scale(1);
    opacity: 0;
  }

  to {
    // transform: scale(.25);
    opacity: 1;
  }
`

const AppCardContainer = styled.div`
  display: inline-block;
  height: 400px;
  margin: 10px 10px 10px 0;
  box-shadow: 0 3px 36px 0 rgba(40, 56, 126, 0.13);
  border-radius: 16px;
  padding: 18px 23px 16px 18px;
  visibility: ${(props) => (props.out ? 'hidden' : 'visible')};
  animation: ${(props) => (props.out ? fadeOut : fadeIn)} 1s linear;
  transition: visibility 1s linear;
`

const DiscountContainer = styled.div`
  border-radius: 16px;
  border: 2px solid #d4ffe0;
  background-color: #d4ffe0;
  color: #5161aa;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 800;
  padding: 1px 6px 1px 6px;
  margin: 2px 4px 4px 0px;
  cursor: pointer;
  width: fit-content;
`

export default function RecommendedAppCard({
  userId,
  organisationId,
  item: {
    id: resourceId, title,
    variants = [],
  },
}) {
  // detection of the correct variant to show
  const appVariant = variants[0] || {}

  // Record an app the user clicks on
  const registerUrlClick = (url) => {
    const identityId = new device.DeviceUUID().get()
    return qrClick(undefined, url, identityId, resourceId, title)
  }

  // all variants (used for download links)
  const iOSAppVariant = variants.filter((variant) => variant.platform === 'iOS')[0]
  const androidAppVariant = variants.filter((variant) => variant.platform === 'android')[0]
  const desktopAppVariant = variants.filter((variant) => variant.platform === 'desktop')[0]

  // default to empty string allowing qr to device detect properly
  const iOSUrl = iOSAppVariant?.url || ''
  const androidUrl = androidAppVariant?.url || ''
  const desktopUrl = desktopAppVariant?.url || ''

  const [descriptionModal, setDescriptionModal] = useState(false)
  const max600px = useMediaQuery('(max-width:600px)')
  const classes = useStyles()

  const { t: defaultT } = useTranslation('common')
  const { t: organisationT } = useTranslation(organisationId)
  let t = organisationT
  if (t('translation') !== 'active') t = defaultT

  const [state, setState] = useState({
    open: false,
    openDiscountDialog: false,
    openDownloadDialog: false,
    url: '',
  })

  const handleCloseDiscountDialog = () => setState((s) => ({ ...s, openDiscountDialog: false }))
  const handleCloseDownloadDialog = () => setState((s) => ({ ...s, openDownloadDialog: false }))

  const handleClose = () => setState((s) => ({ ...s, open: false }))

  const qrCodeTrackerUrl = (userId, iOSUrl, androidUrl, title, resourceId) => {
    const urlParams = `iOSUrl=${encodeURIComponent(iOSUrl)}&androidUrl=${encodeURIComponent(androidUrl)}&userId=${userId}`
      + `&title=${encodeURIComponent(title)}&resourceId=${resourceId}`
    const url = `https://${config.domains.web}/qr-code?${urlParams}&${urlParams}`
    return url
  }

  const [copy, setCopy] = useState({ copied: false })

  const handleCopy = () => {
    navigator.clipboard.writeText(`${appVariant.discountCode}`)
    window.hj('event', 'Discount Offer clicked')
    setCopy({ copied: true })
  }

  const discountAutoApplied = appVariant.discountCode === '-'

  return (
    <AppCardContainer>
      <InfoDialog
        open={state.open}
        onClose={handleClose}
        title={title}
        text={appVariant.description}
        appIconUrl={appVariant.imageUrl}
      />

      <Grid
        container
        direction="column"
      >
        <Grid item style={{ width: '100%' }}>
          <Grid container justifyContent="flex-start" direction="column" alignItems="flex-start">
            {!max600px && (
            <>
              <Grid item style={{ margin: '15px 0', display: 'flex' }}>
                <Card className={classes.card}>
                  <CardActionArea
                    onClick={() => {
                      if (devicePlatform === 'iOS') registerUrlClick(iOSUrl)
                      if (devicePlatform === 'android') registerUrlClick(androidUrl)
                      else setState((s) => ({ ...s, openDownloadDialog: true }))
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      component="img"
                      className={classes.media}
                      image={appVariant.imageUrl}
                      title={title}
                      alt={title}
                    />
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item>
                {/**
              * title app here
              */}
                <SubTitle>{title}</SubTitle>
              </Grid>
            </>
            )}

            <Grid item>
              {/**
            * price app here
            */}
              <Price
                cost={appVariant.cost}
                startingCost={appVariant.startingCost}
                country={appVariant.country}
              />
              <DiscountContainer
                style={{ display: appVariant?.discountCode?.length === 0 ? 'none' : 'block' }}
                onClick={() => setState((s) => ({ ...s, openDiscountDialog: true }))}
              >
                DISCOUNT OFFER
              </DiscountContainer>
            </Grid>
            <Grid item style={{ marginBottom: '12px', marginTop: '10px' }}>
              <DescriptionText gutterBottom>
                {appVariant.discountCode ? appVariant?.description?.toString().slice(0, 133) : appVariant?.description?.toString().slice(0, 250)}
                {' '}
                ...
                <a
                  style={{
                    fontFamily: 'Poppins',
                    color: COLORS.darkNavyBlue,
                    fontSize: '13px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => setState((s) => ({
                    ...s,
                    open: true,
                  }))}
                >
                  More
                </a>
              </DescriptionText>
            </Grid>

            {/* <div style={{ width: '100%' }}>
              <Card elevation={0}>
                <CardContent style={{ padding: 0 }}>
                  <Row>
                    <CategoriesContainer>
                      {appCategories.slice(0, 5)}
                    </CategoriesContainer>
                  </Row>
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Row>
                    <CategoriesContainer>
                      {appCategories.slice(5)}
                    </CategoriesContainer>
                  </Row>
                </Collapse>
                <CardActions disableSpacing>
                  {appCategories.length > 5 && (
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  )}
                </CardActions>
              </Card>
            </div> */}
          </Grid>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          {
            appVariant.discountCode
            && (
              <DownloadButton
                onClick={() => {
                  window.hj('event', 'View Discount Offer clicked')
                  setState((s) => ({ ...s, openDiscountDialog: true }))
                }}
                id="discount_button"
                width="190px"
                display="block"
                backgroundColor="#edeff4"
                fontSize="12px"
              >
                <DownloadLabel>
                  See Discount
                </DownloadLabel>
              </DownloadButton>
            )
          }
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <DownloadButton
            onClick={() => {
              setState((s) => ({ ...s, openDownloadDialog: true }))
              window.hj('event', 'Access Service clicked')
            }}
            width="190px"
            display="block"
            fontSize="12px"
            style={{ justifyContent: 'space-around' }}
            hoverBackgroundColor={COLORS.navy}
            hoverColor={COLORS.white}
          >
            <DownloadLabel>
              Access Service
            </DownloadLabel>
          </DownloadButton>
        </Grid>

        {/**
         * DOWNLOAD APP ON DESKTOP - it shows qr code to read with own mobile
         */}
        <ThemeProvider theme={dialogTheme}>
          <Dialog
            open={state.openDownloadDialog}
            onClose={handleCloseDownloadDialog}
          >
            <DialogTitle>
              <IconButton
                style={{
                  position: 'absolute',
                  right: '8px',
                  top: '8px',
                }}
                onClick={handleCloseDownloadDialog}
              >
                <CloseIcon />
              </IconButton>
              <Grid container direction="row" style={{ padding: '10px' }}>
                <Card className={classes.modalCard}>
                  <CardMedia
                    component="img"
                    image={appVariant.imageUrl}
                    title={title}
                    alt={title}
                  />
                </Card>
                <Grid>
                  <Title style={{ maxWidth: '120px' }}>
                    {title}
                  </Title>
                  <DescriptionText style={{ color: 'black' }}>
                    Available for
                  </DescriptionText>
                  {(devicePlatform === 'iOS' || devicePlatform === 'desktop') && iOSUrl && (
                  <AiFillApple style={{ paddingRight: '10px', paddingTop: '10px' }} />
                  )}
                  {(devicePlatform === 'android' || devicePlatform === 'desktop') && androidUrl && (
                  <AiFillAndroid />
                  )}
                  {(devicePlatform === 'desktop') && desktopUrl && (
                  <FaDesktop />
                  )}
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent className={classes.dialogBottomSection}>
              <DescriptionText style={{ fontSize: '12px', margin: '16px 0 0 8px', color: 'black' }}>
                Service Access Options
              </DescriptionText>

              <br />

              {/* Browser link for iOS */}
              {(devicePlatform === 'iOS') && desktopUrl && (
                <>
                  <AppBrowserLink onClick={registerUrlClick} variant={desktopAppVariant} />
                  <br />
                </>
              )}

              {(devicePlatform === 'iOS') && iOSUrl && (
                <>
                  <AppBrowserLink onClick={registerUrlClick} variant={iOSAppVariant} />
                  <br />
                </>
              )}

              {(devicePlatform === 'android') && desktopUrl && (
                <>
                  <AppBrowserLink onClick={registerUrlClick} variant={desktopAppVariant} />
                  <br />
                </>
              )}

              {/* Browser link for Android */}
              {(devicePlatform === 'android') && androidUrl && (
                <>
                  <AppBrowserLink onClick={registerUrlClick} variant={androidAppVariant} />
                  <br />
                </>
              )}

              {/* Access via QR on Desktop */}
              {devicePlatform === 'desktop' && desktopUrl && (<AppBrowserLink onClick={registerUrlClick} variant={desktopAppVariant} />)}
              {devicePlatform === 'desktop' && (androidAppVariant || iOSAppVariant) && (
              <Grid container direction="row" justifyContent="space-evenly">
                <BiMobile />
                <Grid>
                  <DescriptionText style={{
                    fontSize: '12px',
                    fontWeight: 600,
                    paddingBottom: '5px',
                    color: 'black',
                  }}
                  >
                    Access via App Store
                  </DescriptionText>
                  <DescriptionText style={{ maxWidth: '180px', fontSize: '11px', color: 'black' }}>
                    Use the camera on your mobile device and point at the QR code below to
                    {' '}
                    <b>access the service</b>
                    .
                  </DescriptionText>

                  <Card
                    style={{
                      maxWidth: '165px',
                      backgroundColor: 'transparent',
                      margin: '14px 0',
                    }}
                    elevation={0}
                  >
                    <QRCode
                      value={qrCodeTrackerUrl(userId, iOSUrl, androidUrl, title, resourceId)}
                      size={165}
                    />
                  </Card>
                </Grid>
                <Grid className={classes.platformIconsWrapper}>
                  {iOSUrl && <AiFillApple />}
                  {androidUrl && <AiFillAndroid />}
                </Grid>
              </Grid>
              )}
            </DialogContent>

          </Dialog>
        </ThemeProvider>

        {/**
         * DISCOUNT CODE MODAL
        */}
        <Dialog fullWidth maxWidth="xs" open={state.openDiscountDialog} onClose={handleCloseDiscountDialog}>
          <DialogTitle style={{ margin: 0 }} disableTypography>

            <IconButton
              style={{
                position: 'absolute',
                right: '8px',
                top: '8px',
              }}
              onClick={handleCloseDiscountDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ margin: '27px' }}>
            <Card className={classes.card}>
              <CardMedia
                component="img"
                image={appVariant.imageUrl}
                title={title}
                alt={title}
              />
            </Card>
            <Title>
              {title}
              {' '}
              <br />
            </Title>

            {discountAutoApplied && (
              <div style={{ fontSize: '16px' }}>
                {appVariant.discountDescription}
                <br />
                <br />
              </div>
            )}

            {!discountAutoApplied && (
              <>
                <div style={{ fontSize: '16px' }}>
                  {appVariant.discountDescription}
                  <br />
                  <br />
                  <b>Code:</b>
                </div>
                <Button
                  disableRipple
                  disableTouchRipple
                  variant="contained"
                  size="large"
                  onClick={handleCopy}
                  startIcon={<LoyaltyIcon style={{ color: '#5fcd81' }} fontSize="large" />}
                  style={{ backgroundColor: '#f2f2f2', marginBottom: '13px', marginTop: '13px' }}
                >
                  <span style={{
                    fontFamily: 'Poppins', lineHeight: 1.2, fontSize: '15px', fontWeight: 'bold',
                  }}
                  >
                    {(appVariant.discountCode)}
                  </span>
                </Button>
              </>
            )}
            {!discountAutoApplied && (
              <Button
                onClick={handleCopy}
              >
                {copy.copied ? 'Copied to clipboard!' : 'Copy to clipboard' }
              </Button>
            )}
            {!discountAutoApplied ? (
              <div style={{ fontSize: '14px' }}>
                <b>Enter this code after downloading the app</b>
                {' '}
                (the code is case sensitive).
                <br />
                <br />
                This code will give you access to your exclusive discount when activated.
              </div>
            ) : (
              <div style={{ fontSize: '14px' }}>
                <b>Your discount will be automatically applied.</b>
                <br />
                <br />
                Navigating to &ldquo;Access Service&rdquo; will give you access to your exclusive discount.
              </div>
            )}

          </DialogContent>

        </Dialog>

        <Modal
          open={descriptionModal}
          aria-labelledby="simple-modal-title"
        >
          <ModalDiv style={{
            overflow: 'scroll',
            height: '400px',
          }}
          >
            <AppName>Description</AppName>

            <AppDescription>
              {appVariant.description}
            </AppDescription>

            <Button
              maxWidth="125px"
              onClick={() => setDescriptionModal(false)}
              color={COLORS.veryDarkGray}
              selected
            >
              <b>Close</b>
            </Button>
          </ModalDiv>
        </Modal>
      </Grid>
    </AppCardContainer>
  )
}

RecommendedAppCard.propTypes = {
  organisationId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    platform: PropTypes.string,
    title: PropTypes.string,
    conditions: PropTypes.arrayOf(PropTypes.object),
    functionalities: PropTypes.arrayOf(PropTypes.object),
    labels: PropTypes.arrayOf(PropTypes.string),
    variants: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}
