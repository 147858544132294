import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'constants'

// From Syndi StyleGuide
// https://app.zeplin.io/project/6268132a95330920d8bc41b1/screen/6513eec7b01d2e6702c78256

const StyledButton = styled.button`
  color: ${({ variant }) => (
    variant === 'primary' ? COLORS.darkNavyBlue
      : variant === 'secondary' ? COLORS.white
        : variant === 'tertiary' ? COLORS.darkNavyBlue : COLORS.darkNavyBlue
  )};
  background-color: ${({ variant }) => (
    variant === 'primary' ? COLORS.primaryGreen
      : variant === 'secondary' ? COLORS.darkNavyBlue
        : variant === 'tertiary' ? COLORS.altGrey : COLORS.altGrey
  )};

  &:hover:enabled, &:active, &:focus:not(enabled) {
    color: ${({ variant }) => (
    variant === 'primary' ? COLORS.white
      : variant === 'secondary' ? COLORS.darkNavyBlue
        : variant === 'tertiary' ? COLORS.darkNavyBlue : COLORS.darkNavyBlue
  )};
    background-color: ${({ variant }) => (
    variant === 'primary' ? COLORS.darkNavyBlue
      : variant === 'secondary' ? COLORS.primaryGreen
        : variant === 'tertiary' ? COLORS.darkerGrey : COLORS.darkerGrey
  )};
  }

  &:focus:enabled {
    color: ${({ variant }) => (
    variant === 'primary' ? COLORS.white
      : variant === 'secondary' ? COLORS.darkNavyBlue
        : variant === 'tertiary' ? COLORS.darkNavyBlue : COLORS.darkNavyBlue
  )};
      background-color: ${({ variant }) => (
    variant === 'primary' ? COLORS.lightNavy
      : variant === 'secondary' ? COLORS.altLightGreen
        : variant === 'tertiary' ? COLORS.altGrey : COLORS.altGrey
  )};
  }

  &:disabled {
    cursor: default;
    color: ${({ variant }) => (
    variant === 'primary' ? COLORS.duskyBlue
      : variant === 'secondary' ? COLORS.white
        : variant === 'tertiary' ? COLORS.lightDuskyBlue : COLORS.lightDuskyBlue
  )};
        background-color: ${({ variant }) => (
    variant === 'primary' ? COLORS.altLightGreen
      : variant === 'secondary' ? COLORS.darkerGrey
        : variant === 'tertiary' ? COLORS.altGrey : COLORS.altGrey
  )};
  }
  cursor: pointer;
  opacity: 1;
  border: transparent;
  border-radius: 16.5px;
  width: auto;
  height: auto;
  margin: 0;
  padding: 8px 24px;
  line-height: 1.33;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
`

export default function Button({
  children,
  onClick = () => {},
  disabled = false,
  variant = 'primary', // primary / secondary / tertiary
}) {
  return (
    <StyledButton onClick={onClick} disabled={disabled} variant={variant}>
      {children}
    </StyledButton>
  )
}
