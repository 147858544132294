import React from 'react'
import { Grid } from '@material-ui/core'
import { FaFilter } from 'react-icons/fa'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { updateFunctionalityFilterOpen, updateConditionFilterOpen } from 'reducers/filtersReducer'
import FilterServiceCost from 'components/FilterServiceCost'
import { COLORS } from '../../constants'

const FilterButton = styled.button`
  background-color: ${({ selected }) => (selected ? COLORS.white : '#edeff3')};
  border: ${({ selected }) => (selected ? `2px solid ${COLORS.darkNavyBlue}` : 'none')};
  font-family: Poppins;
  font-size: 12px;
  color: ${COLORS.navy};
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${({ hoverBackgroundColor, selected }) => (!selected ? hoverBackgroundColor : '')};
    color: ${({ hoverColor }) => hoverColor || ''};
  }
`

export default function FilterSelection() {
  const dispatch = useDispatch()

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <a onClick={() => {
          dispatch(updateConditionFilterOpen(true))
          window.hj('event', 'filter recommendations button for conditions clicked')
        }}
        >
          <FilterButton hoverBackgroundColor={COLORS.darkerGrey}>
            <FaFilter />
            {' '}
            Filter By condition
          </FilterButton>
        </a>
      </Grid>
      <Grid item>
        <a onClick={() => {
          dispatch(updateFunctionalityFilterOpen(true))
          window.hj('event', 'filter recommendations button for conditions and functionalities clicked')
        }}
        >
          <FilterButton hoverBackgroundColor={COLORS.darkerGrey}>
            <FaFilter />
            {' '}
            Filter By functionality
          </FilterButton>
        </a>
      </Grid>
      <Grid item>
        <FilterServiceCost />
      </Grid>
    </Grid>
  )
}
