/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REDUX_FETCH_STATUS } from 'constants'
import { isIOS, isMobile } from 'react-device-detect'
import * as api from 'utils/api'

// https://redux.js.org/tutorials/essentials/part-5-async-logic

const initialState = {
  // Updated by fetchRecommendations Thunk
  recommendationsData: {}, // { selfGuided, professionallyGuided, organisationGuided }
  recommendationsDataFetchStatus: REDUX_FETCH_STATUS.idle,

  // Updated by fetchCrisisContent Thunk
  crisisContent: [],
  crisisContentFetchStatus: REDUX_FETCH_STATUS.idle,

  recommendationType: '', // string
  error: '', // string
}

export const fetchRecommendations = createAsyncThunk('recommendations/fetchRecommendations', async () => {
  const allRecommendations = await api.getAllRecommendations()

  // 1. Grouping & Parsing Recommendations
  const uniqueRecommendations = [
    ...new Map(allRecommendations.map((item) => [item.appName, item])).values(),
  ]

  const pgLabels = [
    'Online Therapy/Counseling',
    'Online Psychiatry',
    'Online Coaching',
  ]

  const variantLatestDiscount = (app) => app?.discount?.find((discount) => (
    discount.isActive
    && (new Date(discount.discountEndDate).getTime() > new Date().getTime())
  ))

  const costType = (app) => {
    let costType
    if (app.cost > 0) costType = 'payment-required'
    else if (app.inAppPurchases) costType = 'free-with-in-app-purchases'
    else costType = 'free'
    return costType
  }

  const parseVariantFromRecommendation = (recommendation) => ({
    url: recommendation.url,
    platform: recommendation.platform,
    format: recommendation.format || '',
    country: recommendation.region,
    discountCode: variantLatestDiscount(recommendation)?.discountCode || '',
    discountDescription: variantLatestDiscount(recommendation)?.discountDescription || '',
    imageUrl: recommendation.iconUrl,
    description: recommendation.description,
    labels: recommendation.labels || [],
    cost: costType(recommendation),
    startingCost: recommendation.cost,
    keyWords: recommendation.keyWords,
  })

  const parseRecommendation = (app, variants) => ({
    id: app.uuid,
    name: app.uuid,
    title: app.appName,
    conditions: app.conditions,
    functionalities: app.functionalities,
    serviceTypes: app.serviceTypes,
    partner: true,
    description: app.description,
    labels: app.labels || [],
    imageUrl: app.iconUrl,
    path: app.uuid,
    credibility: app.ompgRating?.credibility,
    userExperience: app.ompgRating?.userExperience,
    variants,
    syndiPayEnabled: 'true',
    cost: costType(app),
    startingCost: app.cost,
    discountCode: variantLatestDiscount(app) || {},
    keyWords: app.keyWords,
  })

  const devicePlatform = isMobile ? (isIOS ? 'iOS' : 'android') : 'desktop'
  const recommendations = uniqueRecommendations.map((uniqueRecommendation) => {
    let variants = allRecommendations?.filter((recommendation) => {
      const sameAppName = uniqueRecommendation.appName === recommendation.appName
      const platformMatch = recommendation.platform === 'desktop' || recommendation.platform === devicePlatform || devicePlatform === 'desktop'
      return sameAppName && platformMatch
    })
    variants = variants.map(((variant) => parseVariantFromRecommendation(variant)))
    return parseRecommendation(uniqueRecommendation, variants)
  })?.filter((r) => r.variants.length > 0)

  // 2. Building RecommendationsData, Conditions & Functionalities object
  const conditions = {}
  const functionalities = {}
  const recommendationsData = {}
  recommendations?.forEach((recommendation) => {
    // Building RecommendationsData
    const serviceTypeList = recommendation?.serviceTypes?.filter((serviceType) => !pgLabels.includes(serviceType.serviceTypeName))
    serviceTypeList?.forEach((serviceType) => {
      if (!recommendationsData[serviceType.serviceTypeName]) recommendationsData[serviceType.serviceTypeName] = [recommendation]
      else recommendationsData[serviceType.serviceTypeName].push(recommendation)
    })

    // Building Conditions & Functionalities
    recommendation?.conditions?.forEach(({ conditionName }) => conditions[conditionName] = true)
    recommendation?.functionalities?.forEach(({ functionalityName }) => functionalities[functionalityName] = true)
  })

  return {
    recommendationsData,
  }
})

export const fetchCrisisContent = createAsyncThunk('recommendations/fetchCrisisContent', async () => {
  const crisisContent = await api.getCrisisContent()
  return crisisContent
})

export const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {
    updateRecommendationType: (state, action) => {
      state.recommendationType = action.payload
    },
    updateCrisisContent: (state, action) => {
      state.crisisContent = action.payload
    },
  },
  extraReducers(builder) {
    // fetchRecommendations
    builder
      .addCase(fetchRecommendations.pending, (state) => {
        state.recommendationsDataFetchStatus = REDUX_FETCH_STATUS.loading
      })
      .addCase(fetchRecommendations.fulfilled, (state, action) => {
        state.recommendationsDataFetchStatus = REDUX_FETCH_STATUS.succeeded
        const { recommendationsData } = action.payload
        state.recommendationsData = recommendationsData
      })
      .addCase(fetchRecommendations.rejected, (state, action) => {
        console.error(action.error.message)
        state.recommendationsDataFetchStatus = REDUX_FETCH_STATUS.failed
        state.error = action.error.message
      })
    // fetchCrisisContent
    builder
      .addCase(fetchCrisisContent.pending, (state) => {
        state.crisisContentFetchStatus = REDUX_FETCH_STATUS.loading
      })
      .addCase(fetchCrisisContent.fulfilled, (state, action) => {
        state.crisisContentFetchStatus = REDUX_FETCH_STATUS.succeeded
        state.crisisContent = action.payload
      })
      .addCase(fetchCrisisContent.rejected, (state, action) => {
        state.crisisContentFetchStatus = REDUX_FETCH_STATUS.failed
        state.error = action.error.message
      })
  },
})

export const {
  updateRecommendationType,
  updateCrisisContent,
} = recommendationsSlice.actions

export default recommendationsSlice.reducer
