import React from 'react'
import styled from 'styled-components'
import syndiName from '../../assets/img/syndi.svg'
import syndiCross from '../../assets/img/logo.svg'

const SyndiLogo = styled.img`
  width: 68px;
`

const SyndiCross = styled.img`
  width: 13px;
`

export default () => (
  <div style={{
    width: '100%',
    position: 'relative',
    paddingTop: '15px',
  }}
  >
    <SyndiLogo src={syndiName} alt="Syndi Logo" />
    <div style={{
      left: 64,
      top: -1,
      position: 'absolute',
    }}
    >
      <SyndiCross src={syndiCross} />
    </div>
  </div>
)
