import React from 'react'
import {
  Button,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, withStyles } from '@material-ui/core/styles'

export const useDesktopStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(5),
    borderRadius: '10px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 106,
    // padding: '6px 0px 7px 20px'
  },
  code: {
    width: 93,
    padding: '6px 6px 6px 0px',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    fontFamily: 'Poppins',
    color: '#383838',
    lineHeight: 0.94,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginTop: theme.spacing(3),
  },
  sosIcon: {
    height: '64px',
    width: '64px',
  },
  text: {
    fontFamily: 'Poppins',
    lineHeight: 1.4,
    fontSize: '15px',
    color: '#000',
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
  },
  appName: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: 1.21,
    color: '#000',
  },
  appDesc: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: 1.21,
    color: '#000',
  },
}))

export const useMobileStyles = makeStyles((theme) => ({
  text: {
    fontFamily: 'Poppins',
    lineHeight: 1.21,
    color: '#000',
    paddingTop: theme.spacing(1),
  },
  title: {
    fontFamily: 'Poppins',
    color: '#383838',
    lineHeight: 0.94,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  sosName: {
    maxWidth: '125px',
  },
  sosLogo: {
    height: '40px',
    width: '40px',
    margin: '0px 0px 0px -5px',
  },
  sosContainer: {
    lineHeight: 0.5,
  },
}))

export const primaryStyles = () => ({
  primary: {
    fontFamily: 'Poppins',
    lineHeight: '0.5 !important', // won't go any smaller than 1.9
    textTransform: 'capitalize',
    color: '#000',
  },
})

export const PrimaryTypography = withStyles(primaryStyles)(
  ({ children, classes, ...other }) => (
    <Typography variant="caption" {...other} className={classes.primary}>
      {children}
    </Typography>
  ),
)

export const actionButtonStyle = () => ({
  button: {
    borderRadius: '10px',
    border: '2px solid',
    borderColor: '#5fcd81',
    backgroundColor: '#5fcd81',
    minWidth: 60,
    height: '35px',
    margin: '0px -15px 3px 0px',
  },
  label: {
    fontFamily: 'Poppins',
    color: '#383838',
    lineHeight: 1.21,
    fontWeight: 'bold',
    textTransform: 'capitalize',

  },
})

export const ActionButton = withStyles(actionButtonStyle)(
  ({ children, classes, ...other }) => (
    <Button variant="contained" className={classes.button} {...other}>
      <Typography variant="caption" className={classes.label}>
        {children}
      </Typography>
    </Button>
  ),
)

export const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
})

export const StyledDialogTittle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      {/* <Typography variant="h6">{children}</Typography> */}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

export const StyledDialogContent = withStyles(() => ({
  root: {
    // paddingTop: '35px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
}))(DialogContent)
