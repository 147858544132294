import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog, DialogContent, Container,
} from '@material-ui/core'
import Cards from 'react-credit-cards'
import styled from 'styled-components'
import { COLORS } from '../../constants'

const HeaderText = styled.h2`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.darkNavyBlue};
`

const TitleText = styled.h2`
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.darkNavyBlue};
`

const Text = styled.p`
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #5161aa;
`

const CloseButton = styled.button`
  width: 82px;
  height: 33px;
  padding: 8px 24px;
  border-radius: 16.5px;
  background-color: ${COLORS.darkNavyBlue};
  border: none;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  cursor: pointer;
`

export default function cardDetailsDialog({
  openDialog,
  onCloseDialog,
  cardCvc,
  cardNumber,
  cardExpiryMonth,
  cardExpiryYear,
  cardHolderName,
  userAddressInfo,
  userPhoneNumber,
}) {
  return (
    <Dialog
      open={openDialog}
      maxWidth="xs"
      onClose={onCloseDialog}
      PaperProps={{
        style: {
          borderRadius: '20px',
          padding: '20px 0',
        },
      }}
    >
      <DialogContent>
        <Container maxWidth="xs">
          <TitleText>Card Details</TitleText>
          <HeaderText style={{ marginBottom: '-13px' }}>CVC</HeaderText>
          <br />
          <Cards
            number={cardNumber}
            expiry={`${`0${cardExpiryMonth}`.slice(-2)}/${cardExpiryYear}`}
            cvc={cardCvc}
            name={cardHolderName}
            focused="cvc"
            issuer="visa"
          />
          <br />
          <HeaderText>Card registration information</HeaderText>
          <Text>{cardHolderName}</Text>
          <Text>{userPhoneNumber}</Text>
          <Text>{userAddressInfo.line1 || ''}</Text>
          <Text>{userAddressInfo.line2 || ''}</Text>
          <Text>{userAddressInfo.city}</Text>
          <Text>{userAddressInfo.postal_code}</Text>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CloseButton onClick={onCloseDialog}>Close</CloseButton>
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

cardDetailsDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  cardCvc: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  cardHolderName: PropTypes.string.isRequired,
}
