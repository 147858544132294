import React, { useEffect, useState } from 'react'
import './Card.css'
import { Grid, Tooltip, useMediaQuery } from '@material-ui/core'
import { isAndroid, isDesktop, isIOS } from 'react-device-detect'
import { BsCreditCard } from 'react-icons/bs'
import Cards from 'react-credit-cards'
import { MdContentCopy } from 'react-icons/md'
import Button from 'components/Button'
import Transactions from 'components/Transactions'
import CardRegisterModal from 'components/CardRegisterModal'
import AddToWalletModal from 'components/AddToWalletModal'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCard, updateCardFetchState } from 'reducers/cardReducer'
import Loading from 'fragments/Loading/Loading'
import { fetchUser } from 'reducers/usersReducer'
import CardDetailsDialog from 'components/CardDetailsDialog'
import SyndiLogo from '../../assets/img/SyndiCardLogo.png'
import AddToGoogle from '../../assets/img/icons/AddToGoogle.png'
import AddToApple from '../../assets/img/icons/AddToApple.png'
import { COLORS, REDUX_FETCH_STATUS } from '../../constants'
import * as style from './SyndiCard.style'

export default function SyndiCard() {
  const dispatch = useDispatch()

  // Device queries
  const gridRowLimit = useMediaQuery('(max-width:1400px)')

  // Card Redux state
  const cardFetchState = useSelector((state) => state.card.cardFetchState)
  const cardNumber = useSelector((state) => state.card.number)
  const cardCvc = useSelector((state) => state.card.cvc)
  const cardExpiryMonth = useSelector((state) => state.card.exp_month)
  const cardExpiryYear = useSelector((state) => state.card.exp_year)
  const cardHolderName = useSelector((state) => state.card.cardholder?.name)
  const cardBalance = useSelector((state) => state.card.cardBalance)
  const cardTransactions = useSelector((state) => state.card.transactions)
  const userAddressInfo = useSelector((state) => state.card.cardholder.billing.address)
  const cardUserPhoneNumber = useSelector((state) => state.card.cardholder.phone_number)

  // User Redux state
  const userFetchState = useSelector((state) => state.user.userFetchState)
  const userEmail = useSelector((state) => state.user.email)
  const userPhoneNumber = useSelector((state) => state.user.phoneNumber)

  // Component Local state
  const [showCardRegisterModal, setShowCardRegisterModal] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [updateAddToWallet, setUpdateAddToWallet] = useState({ showInstructions: false, platform: '' })
  const [openTooltip, setOpenToolTip] = useState(false)
  const [cardDetailsDialogOpen, setcardDetailsDialogOpen] = useState(false)

  const handleTooltipClose = () => setOpenToolTip(false)
  const handleCardDetailsDialogClose = () => setcardDetailsDialogOpen(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(`${cardNumber}`.split(' ').join(''))
    window.hj('event', 'Card details copied to clipboard')
    setOpenToolTip(true)
  }

  const onCardRegisterComplete = () => {
    dispatch(updateCardFetchState(REDUX_FETCH_STATUS.idle))
    setShowCardRegisterModal(false)
  }

  useEffect(() => {
    window.hj('event', 'viewed page syndi.cloud/card')
  }, [])

  useEffect(() => {
    if (cardFetchState === REDUX_FETCH_STATUS.idle) dispatch(fetchCard())
    if (userFetchState === REDUX_FETCH_STATUS.idle) dispatch(fetchUser())
    if (cardFetchState === REDUX_FETCH_STATUS.failed
      && userFetchState === REDUX_FETCH_STATUS.succeeded
    ) {
      setShowCardRegisterModal(true)
      setLoaded(true)
    }
    if (
      cardFetchState === REDUX_FETCH_STATUS.succeeded
      && cardNumber === ''
      && userFetchState === REDUX_FETCH_STATUS.succeeded
    ) {
      setShowCardRegisterModal(true)
      setLoaded(true)
    }
    if (cardFetchState === REDUX_FETCH_STATUS.succeeded && cardNumber?.length > 0) {
      setShowCardRegisterModal(false)
      setLoaded(true)
    }
  }, [cardFetchState, userFetchState])

  useEffect(() => {
    if (loaded && showCardRegisterModal) {
      window.hj('event', 'Activate Syndi Card Modal Opened')
    }
  }, [loaded])

  if (!loaded) return <Loading />

  return (
    <>
      {showCardRegisterModal && (
        <CardRegisterModal
          onRegisterComplete={onCardRegisterComplete}
          userEmail={userEmail}
          userPhoneNumber={userPhoneNumber}
        />
      )}
      {!!updateAddToWallet.showInstructions && (
        <AddToWalletModal
          showModal={updateAddToWallet.showInstructions}
          platform={updateAddToWallet.platform}
          closeModal={() => setUpdateAddToWallet({ ...updateAddToWallet, showInstructions: false, platform: '' })}
        />
      )}
      <Grid
        container
        direction={gridRowLimit ? 'column' : 'row'}
        style={{ zIndex: 5 }}
      >
        <Grid item xs={4}>
          <style.CardContainer>
            <style.GreenBlock>
              <style.Logo src={SyndiLogo} alt="logo showing the words SyndiCard with the SyndiHealth icon" />
            </style.GreenBlock>
            <style.GreyBlock>
              {cardBalance && (
                <style.CardDetailContainer>
                  <style.SubTitle>
                    <BsCreditCard style={{ height: '15px', width: '15px', margin: '0 5px -2px 0' }} />
                    Balance
                  </style.SubTitle>
                  <style.Balance>
                    {cardBalance}
                  </style.Balance>
                </style.CardDetailContainer>
              )}
              <br />
              <style.CardDetailContainer>
                <style.SubTitle>
                  <BsCreditCard style={{ height: '15px', width: '15px', margin: '0 5px -2px 0' }} />
                  Card Details
                </style.SubTitle>
                <Cards
                  number={cardNumber}
                  expiry={`${`0${cardExpiryMonth}`.slice(-2)}/${cardExpiryYear}`}
                  cvc={cardCvc}
                  name={cardHolderName}
                  focused="number"
                  issuer="visa"
                />
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: '10px' }}
                >
                  <Button onClick={() => setcardDetailsDialogOpen(true)}>
                    View Card Details
                  </Button>
                </Grid>
                <Tooltip
                  title="Card Number Copied"
                  leaveDelay={500}
                  onClose={handleTooltipClose}
                  open={openTooltip}
                >
                  <style.CopyButton>
                    <MdContentCopy style={{ transform: 'rotateY(180deg)', color: COLORS.primary }} onClick={handleCopy} />
                  </style.CopyButton>
                </Tooltip>
              </style.CardDetailContainer>
              <CardDetailsDialog
                openDialog={cardDetailsDialogOpen}
                onCloseDialog={handleCardDetailsDialogClose}
                cardCvc={cardCvc}
                cardNumber={cardNumber}
                cardExpiryMonth={cardExpiryMonth}
                cardExpiryYear={cardExpiryYear}
                cardHolderName={cardHolderName}
                userAddressInfo={userAddressInfo}
                userPhoneNumber={cardUserPhoneNumber}
              />
              <br />
              <style.SubTitle>
                <BsCreditCard style={{ height: '15px', width: '15px', margin: '0 5px -2px 0' }} />
                Click below for instructions on how to add to your smartphone wallet
              </style.SubTitle>
              <style.ButtonWrapper>
                <style.AddtoWalletContainer>
                  {(isDesktop || isIOS) && (
                    <style.AddToWallet
                      src={AddToApple}
                      alt="Add to Apple button"
                      onClick={() => {
                        setUpdateAddToWallet({ ...updateAddToWallet, showInstructions: true, platform: 'Apple' })
                        window.hj('event', 'Add to Apple Wallet clicked')
                      }}
                    />
                  )}
                  {(isDesktop || isAndroid) && (
                    <style.AddToWallet
                      src={AddToGoogle}
                      alt="Add to Google button"
                      onClick={() => {
                        setUpdateAddToWallet({ ...updateAddToWallet, showInstructions: true, platform: 'Google' })
                        window.hj('event', 'Add to Google Wallet clicked')
                      }}
                    />
                  )}
                </style.AddtoWalletContainer>
              </style.ButtonWrapper>
            </style.GreyBlock>

            <style.ContactButtonWrapper>
              Having any issues with your SyndiCard?

              <Button onClick={() => window.open('https://forms.gle/pp81Q57ny8jS4FEE6')}>
                Contact Syndi Health
              </Button>
            </style.ContactButtonWrapper>
          </style.CardContainer>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Transactions transactionData={cardTransactions} />
        </Grid>
      </Grid>
    </>
  )
}
