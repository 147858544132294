import React from 'react'
import styled from 'styled-components'
import ButtonPrimary from 'components/Button'
import Cards from 'react-credit-cards'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { isMobile } from 'react-device-detect'
import { BsCreditCard } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import './Card.css'
import SyndiCardIllustration from '../../assets/img/SyndiCardIllustration.png'
import { COLORS } from '../../constants'
import { ReactComponent as SyndiCardLogo } from '../../assets/img/icons/LogoSyndiCard.svg'

const MainContainer = styled.div`
    display: flex;
    background-color: transparent;
    // mobile
    @media only screen and (max-width: 600px) {
      display: block;
    }
`

const TextWrapper = styled.div`
    display: flex;
    flex-flow: column;
    width: auto;

    // mobile
    @media only screen and (max-width: 600px) {
      padding: 20px 10px 0 10px;
    }
`

const Title = styled.h1`
    width: auto;
    height: 46px;
    margin: 0 0 7.5px 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.primary};
`

const Header = styled.h2`
    width: auto;
    height: auto;
    margin: 7.5px 115px 10.3px 0;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.textColor};


    // mobile
    @media only screen and (max-width: 600px) {
      margin: 12px 0 30px 0;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-flow: row;

    // mobile
    @media only screen and (max-width: 600px) {
      margin: 45px 0 35px 14.5px;
    }
`
export const CardDisplay = styled.div`
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 6;
  transform: translate(-50%, -50%);
`

export const FadedBackground = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 4;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
`

export const CardContainer = styled.div`
    z-index: 5;
    margin: 0px 50px 0px 70px;
    width: 440px;
    @media (max-width: 600px) {
      margin: 0px 0px 0px 18px;
      width: 80vw;
    }
`

export const GreenBlock = styled.div`
    height: 25px;
    border-radius: 30px 30px 0 0;
    background-color: #68db8d;
    padding: 25px;
    display: flex;
    justify-content: right;
`

export const GreyBlock = styled.div`
    padding: 10px 40px 0 40px;
    border-radius: 0 0 30px 30px;
    background-color: ${COLORS.white};
`

export const Logo = styled.img`
    height: 60px;
    width: 230px;
`

export const Icon = styled.img`
    width: 40px;
    height: 40px;
`

export const SubTitle = styled.div`
    width: auto;
    height: 21px;
    margin: 5px 0 30px 0;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: ${COLORS.grey};
`

export const Currency = styled.span`
    width: 16px;
    height: 35px;
    margin: 21px 2px 7px 0;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.68;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.primary};
`

export const Balance = styled.p`
    width: auto;
    height: 63px;
    margin: 0 0 -40px 2px;
    font-family: Poppins;
    font-size: 50px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.38;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.textColor};
`

export const ContactButtonWrapper = styled.div`
    width: 230px;
    font-family: Poppins;
    font-size: 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${COLORS.grey};
    z-index: 5;
`

export const Status = styled.p`
    width: auto;
    height: auto;
    font-family: Poppins;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: ${({ isProcessing }) => (isProcessing ? COLORS.primary : COLORS.primaryRed)};
`

export const StatusInfo = styled.p`
    width: 320px;
    height: 68px;
    margin: -10px 0 -50px 0;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.black};
`

export const CardDetailContainer = styled.div`
    text-align: left;
`

export const Text = styled.p`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin: 20px 5px 5px 5px;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.textColor};
`

export const GreyLine = styled.div`
    // width: 280px;
    border: 0.25px #8a96a2 solid;
`

export const Detail = styled.p`
    font-family: Poppins;
    margin: 5px;
    font-size: 14px;
    font-weight: 550;
    font-stretch: normal;
    font-style: normal;
    text-decoration: underline;
    line-height: 0.5;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.textColor};
`

export const CopyButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
`

export const AddtoWalletContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 25px;
    margin: 10px 25px 10px 0px;
`

export const AddToWallet = styled.img`
    width: 172.9px;
    height: 52.2px;
    cursor: pointer;
`

export default function SyndiCardHome({
  cardNumber,
  cardBalance,
  cardExpiryMonth,
  cardExpiryYear,
  cardCvc,
  cardHolderName,
}) {
  const buttonText = cardNumber ? 'View my budget' : 'Activate my SyndiCard'
  const descriptionText = cardNumber
    ? 'Your budget will be renewed at the end of each month.'
    : 'To get started, simply activate your SyndiCard and explore your recommended Health Services to make your initial transaction.'
  const shownCardNumber = cardNumber
  const shownCardCvv = cardCvc
  const shownCardExpiryMonth = cardExpiryMonth
  const shownCardExpiryYear = cardExpiryYear
  const shownCardHolderName = cardHolderName

  return (
    <MainContainer>
      <TextWrapper>
        <Title>SyndiCard</Title>
        <Header>Use your SyndiCard to pay for your Health Services</Header>
        <Text>
          Your employer is now providing you with a monthly budget to utilize for purchasing the Health Services that fit your needs.
          <br />
          <br />
          {descriptionText}
        </Text>
        <br />
        <Link
          to={{ pathname: '/card' }}
          onClick={() => window.hj('event', 'Active my SyndiCard button clicked')}
        >
          <ButtonWrapper>
            <ButtonPrimary
              color={COLORS.white}
              bgColor={COLORS.textColor}
              hoverBackgroundColor={COLORS.primaryGreen}
              hoverColor={COLORS.navy}
              border="transparent"
              radius="25px"
              width="auto"
              fontSize="14px"
            >
              {buttonText}
              <ArrowForwardIcon style={{ margin: '0 0 -6px 8px', height: '20px', width: '20px' }} />
            </ButtonPrimary>
          </ButtonWrapper>
        </Link>
      </TextWrapper>
      {cardNumber ? (
        <CardContainer>
          <GreenBlock>
            <SyndiCardLogo style={{ margin: '10px' }} />
            <Icon src="https://syndi-public-assets.s3.eu-west-2.amazonaws.com/Syndi-logo.png" />
          </GreenBlock>
          <GreyBlock>
            <CardDetailContainer>
              <SubTitle>
                <BsCreditCard style={{ height: '15px', width: '15px', margin: '0 5px -2px 0' }} />
                Balance
              </SubTitle>
              <Balance>
                {cardBalance}
              </Balance>
            </CardDetailContainer>
            <br />
            <CardDetailContainer>
              <Cards
                number={shownCardNumber}
                expiry={`${`0${shownCardExpiryMonth}`.slice(-2)}/${shownCardExpiryYear}`}
                cvc={shownCardCvv}
                name={shownCardHolderName}
                focused="number"
                issuer="visa"
              />
            </CardDetailContainer>
            <br />
          </GreyBlock>
          <br />
        </CardContainer>
      ) : (
        <div style={{ padding: '30px' }}>
          <img src={SyndiCardIllustration} alt="SyndiCard" style={{ width: isMobile ? '100%' : '400px' }} />
        </div>
      )}
    </MainContainer>
  )
}
