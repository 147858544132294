import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { DescriptionText } from 'components/Typography'
import { AiFillAndroid, AiFillApple } from 'react-icons/ai'
import { FiArrowRightCircle } from 'react-icons/fi'
import { FaDesktop } from 'react-icons/fa'
import { COLORS } from 'constants'

const useStyles = makeStyles(() => ({
  hyperlink: {
    color: COLORS.primaryBlue,
    fontSize: '11px',
    textDecoration: 'underline',
  },
  hyperlinkGrid: {
    marginBottom: '10px',
  },
}))

export default function AppBrowserLink({ variant, onClick }) {
  const { format, url, platform } = variant
  const classes = useStyles()

  const generateLinkIcon = ((platform) => {
    if (platform === 'iOS') return <AiFillApple />
    if (platform === 'android') return <AiFillAndroid />
    return <FaDesktop />
  })

  const generateLinkText = ((format) => {
    if (format === 'iOS') return 'Access via App Store'
    if (format === 'android') return 'Access via Play Store'
    return 'Access via Web Browser'
  })

  const generateLinkHyperText = ((format) => {
    if (format === 'iOS') return 'Download from the App Store'
    if (format === 'android') return 'Download from the Play Store'
    return 'Open website in new tab'
  })

  return (
    <Grid container direction="row" justifyContent="space-evenly" className={classes.hyperlinkGrid}>
      {generateLinkIcon(platform)}
      <Grid>
        <DescriptionText style={{
          fontSize: '12px',
          fontWeight: 600,
          width: '190px',
          color: 'black',
        }}
        >
          {generateLinkText(format)}
        </DescriptionText>
        <a href={url} onClick={() => onClick(url)} target="_blank" className={classes.hyperlink}>
          {generateLinkHyperText(format)}
        </a>
      </Grid>
      <a href={url} onClick={() => onClick(url)} target="_blank">
        <FiArrowRightCircle style={{ color: COLORS.primaryBlue, width: '25px', height: '25px' }} />
      </a>
    </Grid>
  )
}
