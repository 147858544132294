import React from 'react'
import styled from 'styled-components'
import GaugeComponent from 'react-gauge-component'
import { COLORS } from '../../constants'

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const GaugeContainer = styled.div`
`

const GaugeLabels = styled.div`
  margin-top: -55px;
`

export default function HealthScoreGauge({
  level = '',
  score = 4,
  negativeScoreBoundary = 0,
  positiveScoreBoundary = 100,
}) {
  const reversed = (negativeScoreBoundary > positiveScoreBoundary)
  const minValue = reversed ? positiveScoreBoundary : negativeScoreBoundary
  const maxValue = reversed ? negativeScoreBoundary : positiveScoreBoundary

  return (
    <ScoreContainer>
      <GaugeContainer
        item
      >
        <GaugeComponent
          style={{
            width: '90%',
            height: '90%',
            minWidth: '200px',
          }}
          type="semicircle"
          minValue={minValue}
          maxValue={maxValue}
          arc={{
            width: 0.3,
            cornerRadius: 0,
            padding: 0,
            gradient: false,
            subArcs: [
              {
                limit: 0,
                color: COLORS.altGrey,
                showTick: false,
              },
              {
                limit: score <= 0 ? 0.1 : score >= maxValue ? maxValue - 0.1 : score,
                color: reversed ? score > maxValue / 2 ? COLORS.graphRed : COLORS.lightGreenGauge : score > maxValue / 2 ? COLORS.lightGreenGauge : COLORS.graphRed,
                showTick: false,
              },
              {
                color: COLORS.altGrey,
                showTick: false,
              },
            ],
          }}
          pointer={{ width: 0, type: 'arrow', animationDelay: 0 }}
          value={score}
          labels={{
            valueLabel: {
              hide: true,
              formatTextValue: () => level.charAt(0).toUpperCase() + level.slice(1),
              style: {
                fontSize: '50px',
                fontFamily: 'Poppins',
                fill: COLORS.darkNavyBlue,
                textWrap: 'wrap',
                fontWeight: '600',
                textAlign: 'center',
                textShadow: 'none',
              },
            },
            tickLabels: {
              type: 'outer',
              hideMinMax: true,
              ticks: [],
              defaultTickValueConfig: {
                formatTextValue: (l) => (l === 0 ? 'Low' : 'High'),
                style: {
                  fontSize: '10px',
                  textShadow: 'none',
                  fill: COLORS.darkNavyBlue,
                },
              },
              defaultTickLineConfig: {
                hide: true,
                char: '',
              },
            },
          }}
        />
      </GaugeContainer>
      <GaugeLabels>
        <p style={{
          fontSize: '10px',
          color: COLORS.darkNavyBlue,
          // margin: '0px -4px 0px 10px',
        }}
        >
          {/* {reversed ? '😊' : '🙁' } */}
        </p>
        <p style={{
          fontSize: '12px',
          color: COLORS.darkNavyBlue,
          // margin: '0px 0px 0px 0px',
          textAlign: 'center',
          textWrap: 'wrap',
          width: '80px',
        }}
        >
          <b>{level.charAt(0).toUpperCase() + level.slice(1)}</b>
        </p>
      </GaugeLabels>
    </ScoreContainer>
  )
}
