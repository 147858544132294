import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser } from 'reducers/usersReducer'
import Loading from 'fragments/Loading/Loading'
import { REDUX_FETCH_STATUS } from '../../constants'
import HealthilyLogo from '../../assets/img/icons/Healthily.svg'

export default function HealthEducation() {
  const dispatch = useDispatch()
  const [firstUserCondition, setFirstUserCondition] = useState('"Wellbeing"')

  // Global State Selectors
  // User State
  const userFetchState = useSelector((state) => state.user.userFetchState)
  const userConditions = useSelector((state) => state.user.conditions)

  // Component State
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    window.hj('event', 'viewed page syndi.cloud/health-education')
  }, [])

  useEffect(() => {
    if (userFetchState === REDUX_FETCH_STATUS.idle) {
      dispatch(fetchUser())
    }
    if (userFetchState === REDUX_FETCH_STATUS.succeeded
    ) {
      setLoaded(true)
    }
    if (userConditions?.length > 0) {
      setFirstUserCondition(encodeURIComponent(userConditions[0]))
    }
  }, [userFetchState, userConditions])

  const IFrameContainer = styled.div`
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
    position: relative
  `

  const PartnershipText = styled.span`
    margin-left: 300px;
    font-size: 14px;
    line-height: 0px;
    color: #34445a;
    @media (max-width: 1400px) {
      margin-left: 20px;
    }
  `

  if (!loaded) return <Loading />

  return (
    <div style={{ height: '100vh' }}>
      <div style={{
        height: '20px',
        backgroundColor: 'white',
        marginTop: '-25px',
        padding: '35px 0 30px 0',
      }}
      >
        <PartnershipText>
          In partnership with
        </PartnershipText>
        <img
          src={HealthilyLogo}
          alt="Healthily Logo"
          style={{
            height: '25px',
            marginLeft: '15px',
            position: 'absolute',
            marginTop: '2px',
          }}
        />
      </div>

      <IFrameContainer>
        <iframe
          height="100%"
          width="100%"
          src={`https://www.livehealthily.com/search?search-term=${firstUserCondition}`}
          title="Healthily content"
          style={{
            marginTop: '-80px',
            border: 'none',
          }}
          data-hj-allow-iframe
        />
      </IFrameContainer>
    </div>
  )
}
