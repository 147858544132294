import React from 'react'
import styled from 'styled-components'
import styles from './Loading.module.css'

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  // mobile
  @media only screen and (max-width: 600px) {
    left: 36%;
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1000;
`

export default function Loading() {
  return (
    <Container>
      <div className={`${styles.container}`}>
        <Image
          draggable={false}
          alt="Loading"
          src="https://syndi-public-assets.s3.eu-west-2.amazonaws.com/Syndi-logo.png"
        />
      </div>
    </Container>
  )
}
