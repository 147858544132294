import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Grid, Container } from '@material-ui/core'
import { fetchUser } from 'reducers/usersReducer'
import Loading from 'fragments/Loading/Loading'
import {
  Title, Content, SubTitle, SubHeader,
  StyledControlLabel,
} from 'components/Typography'
import RemindersDialog from 'components/RemindersDialog'
import ProgressChart from 'components/ProgressChart'
import Button from 'components/Button'
import HealthScoreGauge from 'components/HealthScoreGauge'
import AssessmentModal from 'components/AssessmentModal'
import moment from 'moment'
import { updateRecommendationType } from 'reducers/recommendationsReducer'
import { COLORS, REDUX_FETCH_STATUS } from '../../constants'
import ExpandIcon from '../../assets/img/icons/ExpandIcon.svg'

const HealthAreasContainer = styled.div`
  margin-top: 30px;
`

const HealthAreaContainer = styled.div`
  box-shadow: 0 3px 36px 0 rgba(40, 56, 126, 0.13);
  border-radius: 8px;
  margin-bottom: 24px;
`
const AddHealthAreaContainer = styled(Grid)`
  border-radius: 8px;
  box-shadow: 0 3px 36px 0 rgba(40, 56, 126, 0.13);
  border: dashed 2px #5161aa;
  padding: 24px;
  margin-bottom: 100px;
`

const HealthAreaContainerHeader = styled(Grid)`
  margin: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;

  &:hover {
    cursor: pointer;
  }
`

const HealthSurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
`

const RemindersDialogContainer = styled.div`
  background: ${COLORS.altGrey};
  border-radius: 8px;
  padding: 16px;
`

const EditReminderLink = styled.a`
  text-decoration: underline;
  font-size: 12px;
`

const SurveyDetailContainer = styled(Grid)`
  background-color: ${COLORS.babyBlue};
`

const NoSurveyContainer = styled(Grid)`
  background-color: ${COLORS.babyBlue};
  width: 100%;
  height: 250px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 80px
`

export default function Insights() {
  const dispatch = useDispatch()

  // Redux State
  const organisationHealthSurveys = useSelector((state) => state.organisation.organisationHealthSurveys)
  const organisationHealthAreas = useSelector((state) => state.organisation.organisationHealthAreas)
  const userSelectedHealthAreas = useSelector((state) => state.user.selectedHealthAreas || [])
  const user = useSelector((state) => state.user)
  const {
    userFetchState, health, checkupTime, userSurveys,
  } = user

  // Local Component State
  const [loaded, setLoaded] = useState(false)
  const [reminderModalOpen, setReminderModalOpen] = useState(false)
  const [expandedHealthAreas, setExpandedHealthAreas] = useState([])
  const [orderedHealthAreas, setOrderedHealthAreas] = useState([])
  const [orderedUserSurveys, setOrderedUserSurveys] = useState({})
  const [showAssessmentModal, setShowAssessmentModal] = useState(false)

  const surveyHealthLookup = (surveyName = '') => {
    const surveyHealth = health.filter(({ name = '' }) => name.toLowerCase() === surveyName.toLowerCase())
    return surveyHealth
  }

  const handleTakeAssessment = () => {
    setShowAssessmentModal(true)
  }

  const updateExpandedHealthAreas = (healthAreaName) => {
    // update expandedHealthAreas array
    if (expandedHealthAreas.includes(healthAreaName)) {
      setExpandedHealthAreas(expandedHealthAreas.filter((h) => h !== healthAreaName))
    } else {
      setExpandedHealthAreas([...expandedHealthAreas, healthAreaName])
    }
  }

  const setActiveRecommendation = (healthAreaName) => {
    dispatch(updateRecommendationType(healthAreaName))
  }

  useEffect(() => {
    window.hj('event', 'viewed page syndi.cloud/insights')

    const userSurveyTypes = userSurveys.map((s) => ({
      surveyName: s.surveyType,
      createdAt: new Date(s.createdAt).getTime(),
    }))
    const orderedUserSurveys = userSelectedHealthAreas.reduce((agg, healthAreaName) => {
      const userHealthAreaSurveys = organisationHealthAreas[healthAreaName]

      if (!userHealthAreaSurveys) return agg

      return {
        ...agg,
        [healthAreaName]: [...userHealthAreaSurveys].sort(({ surveyName }) => (
          userSurveyTypes.find((s) => s.surveyName === surveyName) ? -1 : 1
        )),
      }
    }, {})

    const orderedHealthAreas = Object.keys(orderedUserSurveys).sort((a, b) => {
      const aSurveyName = orderedUserSurveys[a][0].surveyName
      const bSurveyName = orderedUserSurveys[b][0].surveyName

      const matchedSurveyA = userSurveyTypes.find((s) => s.surveyName === aSurveyName)
      const matchedSurveyB = userSurveyTypes.find((s) => s.surveyName === bSurveyName)

      const aSurveyType = matchedSurveyA ? matchedSurveyA.createdAt : 0
      const bSurveyType = matchedSurveyB ? matchedSurveyB.createdAt : 0

      return bSurveyType - aSurveyType
    })

    setOrderedUserSurveys(orderedUserSurveys)
    setOrderedHealthAreas(orderedHealthAreas)
    setExpandedHealthAreas(orderedHealthAreas)
  }, [userSelectedHealthAreas, organisationHealthSurveys])

  useEffect(() => {
    if (userFetchState === REDUX_FETCH_STATUS.idle) dispatch(fetchUser())

    if (userFetchState === REDUX_FETCH_STATUS.succeeded) setLoaded(true)
  }, [userFetchState])

  if (!loaded) return <Loading />

  return (
    <Container maxWidth="md" style={{ marginTop: '41px' }}>
      <Title variant="h1">Your Health Insights</Title>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={8}>
          <SubTitle variant="h4">
            Track your progress over time
          </SubTitle>
          <Content variant="body1">
            Tracking your progress helps you to navigate your health & wellbeing journey and
            learn about what works best for you. Set an assessment schedule and we&apos;ll email
            or text with a reminder.
          </Content>
        </Grid>
        <Grid item xs={4}>
          <RemindersDialogContainer onClick={() => setReminderModalOpen(true)}>
            <Content>
              {checkupTime ? 'Next assessment reminder' : 'Set an assessment reminder'}
            </Content>
            {checkupTime && (
              <Content>
                <b>{moment(checkupTime).format('ha on dddd MMMM Do')}</b>
              </Content>
            )}
            <EditReminderLink>
              {checkupTime ? 'Edit reminder' : 'Set reminder'}
              {' '}
              <b>&gt;</b>
            </EditReminderLink>
          </RemindersDialogContainer>

          <RemindersDialog
            openDialog={reminderModalOpen}
            onCloseDialog={() => {
              dispatch(fetchUser())
              setReminderModalOpen(false)
            }}
          />
        </Grid>
      </Grid>

      <HealthAreasContainer>
        {orderedHealthAreas.map((healthAreaName, idx) => (
          <HealthAreaContainer key={`health_area${idx}`}>
            <HealthAreaContainerHeader
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => updateExpandedHealthAreas(healthAreaName)}
            >
              <Grid item>
                <SubTitle>{healthAreaName}</SubTitle>
              </Grid>
              <Grid item>
                <img
                  src={ExpandIcon}
                  alt="expand"
                  style={{
                    transform: expandedHealthAreas.includes(healthAreaName) ? 'rotate(210deg)' : 'rotate(120deg)',
                  }}
                />
              </Grid>
            </HealthAreaContainerHeader>
            {expandedHealthAreas.includes(healthAreaName) && (
              <HealthSurveyContainer>
                {orderedUserSurveys[healthAreaName].map(({
                  surveyName,
                  surveyDescription,
                  negativeScoreBoundary,
                  positiveScoreBoundary,
                }, idx) => (
                  <Grid container direction="column" key={`health_survey${idx}`}>
                    <SubHeader>
                      {surveyName}
                    </SubHeader>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      {surveyHealthLookup(surveyName).length === 0 && (
                        <NoSurveyContainer>
                          <Content>
                            <b>
                              Take an assessment to unlock more insights on your
                              {' '}
                              {healthAreaName}
                            </b>
                          </Content>
                          <br />
                          <Button onClick={() => handleTakeAssessment(surveyName)} variant="secondary">
                            Take assessment now
                          </Button>
                        </NoSurveyContainer>
                      )}
                      {surveyHealthLookup(surveyName).length > 0 && (
                        <SurveyDetailContainer
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="flex-end"
                        >
                          <Grid item xs={12} sm={6}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="flex-end"
                              style={{ padding: '24px' }}
                              spacing={2}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={6}
                              >
                                <StyledControlLabel>
                                  {surveyDescription}
                                </StyledControlLabel>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <HealthScoreGauge
                                  level={surveyHealthLookup(surveyName).slice(-1)[0].level}
                                  score={surveyHealthLookup(surveyName).slice(-1)[0].score}
                                  negativeScoreBoundary={negativeScoreBoundary}
                                  positiveScoreBoundary={positiveScoreBoundary}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <ProgressChart
                              userHealth={surveyHealthLookup(surveyName)}
                              negativeScoreBoundary={negativeScoreBoundary}
                              positiveScoreBoundary={positiveScoreBoundary}
                            />
                          </Grid>
                        </SurveyDetailContainer>
                      )}
                    </Grid>
                  </Grid>
                ))}
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  style={{ marginTop: '26px' }}
                >
                  <Grid item>
                    <Link to="/recommendations">
                      <Button onClick={() => { setActiveRecommendation(healthAreaName) }}>
                        See your recommendations
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </HealthSurveyContainer>
            )}
          </HealthAreaContainer>
        ))}
        <AddHealthAreaContainer item xs={12}>
          <Link to="/home">
            <SubTitle>+ Add a Health Area</SubTitle>
          </Link>
        </AddHealthAreaContainer>
      </HealthAreasContainer>
      <AssessmentModal showModal={showAssessmentModal} setShowModal={setShowAssessmentModal} />
    </Container>
  )
}
