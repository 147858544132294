import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  IconButton,
  Card,
  CardMedia,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { DescriptionText, Title } from '../Typography'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Title style={{ maxWidth: '90%' }}>{children}</Title>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(() => ({
  root: {
    padding: '5px 35px 40px 35px',
  },
}))(MuiDialogContent)

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '61px',
    borderRadius: '16px',
    boxShadow: '0 2px 4px 0 rgba(117, 117, 117, 0.5)',
    backgroundColor: 'transparent',
    marginBottom: theme.spacing(2),
  },
  media: {
    height: '61px',
  },
  modalSubtext: {
    fontSize: '10px',
  },
}))

export default function InfoDialog(props) {
  const {
    open, onClose, title, text, subtext, appIconUrl,
  } = props
  const handleClose = () => onClose()
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle onClose={handleClose}>
        {null}
      </DialogTitle>
      <DialogContent>
        {appIconUrl ? (
          <Card className={classes.card}>
            <CardMedia
              className={classes.media}
              component="img"
              image={appIconUrl}
              title=""
              alt=""
            />
          </Card>
        ) : null}
        <Title>{title}</Title>
        <DescriptionText>
          {text}
          <br />
          <br />
          {subtext}
        </DescriptionText>
      </DialogContent>

    </Dialog>
  )
}

InfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  appIconUrl: PropTypes.string,
}

InfoDialog.defaultProps = {
  open: false,
  title: '',
  text: '',
  appIconUrl: '',
}
