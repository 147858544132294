import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ErrorIcon from '../../assets/img/icons/errorIcon.webp'
import { COLORS } from '../../constants'

const ErrorTextBox = styled.div`
  font-size: 14px;
  color: ${COLORS.errorRed};
  padding: 20px 35px 20px 15px;
  max-width: 270px;
  margin: 20px 0 30px;
  border-radius: 10px;
  background-color: ${COLORS.errorBgRed};
  z-index: 5;
  display: flex;

  @media only screen and (max-width: 600px) {
    width: 225px;
    padding: 20px 5px 20px 20px;
    margin: 20px 0 30px;
  }
`

const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`

export default function ErrorText({ children }) {
  return (
    <ErrorTextBox>
      <Icon src={ErrorIcon} />
      {children}
    </ErrorTextBox>
  )
}

ErrorText.propTypes = {
  children: PropTypes.string,
}

ErrorText.defaultProps = {
  children: '',
}
