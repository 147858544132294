// TO-DO: sort out the different colours for theming

export const REDUX_FETCH_STATUS = {
  idle: 'idle',
  loading: 'loading',
  succeeded: 'succeeded',
  failed: 'failed',
}

export const CARD_POLLING_SECONDS = 30

export const CURRENCY_SYMBOLS = {
  gbp: '£',
  eur: '€',
  usd: '$',
  aud: '$',
  cad: '$',
  chf: 'CHF',
}

export const COLORS = {
  black: '#000',
  white: '#fff',
  red: '#ff0000',
  veryDarkGray: '#383838',
  veryLightGray: '#9B9B9B',
  primary: '#5FCD81',
  primaryLight: '#9FE1B3',
  primaryPale: '#e0f5e7',
  limeGreen: '#52af77',
  navbarGreen: '#5FCD81',
  lightPurple: '#b9cfff',
  darkPurple: '#582AD9',
  apricot: '#ffc46f',
  textColor: '#28387e',
  graphRed: '#ffeaea',

  primaryBlue: '#2f455c',
  blush: '#f8c7c7',
  palePink: '#fdf5f5',
  lightGreen: '#eefaf2',
  primaryRed: '#dd7273',
  lightRed: '#f8e3e3',
  grey: '#96a1ac',
  orange: '#fac280',
  lightOrange: '#fef3e6',
  darkOrange: '#e09d7f',
  alternateBlack: '#3e3e3e',
  veryDarkBlue: '#1d2b39',
  darkBlue: '#233345',
  bannerBlue: '#3a536e',
  lightGrey: '#f4f5f7',
  alternateGrey: '#e9ebef',
  backgroundGrey: '#cfd4d8',
  darkGreen: '#7bca88',
  errorRed: '#ad433f',
  errorBgRed: '#fdeceb',
  darkNavyBlue: '#28387e',
  lightGreenGauge: '#55e2b7',

  // New Syndi Colors
  navy: '#28387E',
  lightNavy: '#5161AA',
  babyBlue: '#F7FBFE',
  primaryGreen: '#66F091',
  altLightGreen: '#D4FFE0',
  altDarkGreen: '#7AB98E',
  altGrey: '#EDEFF4',
  darkerGrey: '#D9DFEA',
  darkerNavyBlue: '#18224C',

  // RGB With Opacity dimmed
  duskyBlue: 'rgba(81, 97, 170, 0.55)',
  lightDuskyBlue: 'rgba(40, 56, 126, 0.49)',

}

export const FEATURE_FLAGS = {
  communityFeature: 'community',
  searchFeature: 'search',
  crisisContent: 'crisis-content',
}

export const TripettoStyle = {
  direction: 'horizontal',
  verticalAlignment: 'top',
  hidePast: true,
  hideUpcoming: true,
  showNavigation: 'always',
  showProgressbar: true,
  showEnumerators: false,
  // autoFocus: false,
  showPreviousButton: true,
  showSeparateSubmit: false,
  hideRequiredIndicator: true,
  showScrollbar: false,
  disableScrolling: false,
  noBranding: true,
  contract: { name: 'tripetto-runner-autoscroll', version: '6.1.7' },
  navigation: {
    progressbarColor: COLORS.textColor,
    textColor: COLORS.textColor,
    backgroundColor: COLORS.altGrey,
  },
  color: COLORS.textColor,
  mode: 'progressive',
  showPageIndicators: false,
  inputs: {
    borderSize: 1,
    roundness: 8,
    errorColor: 'red',
    agreeColor: 'green',
    declineColor: 'red',
    selectionColor: COLORS.textColor,
    borderColor: COLORS.lightNavy,
    backgroundColor: COLORS.altGrey,
    textColor: COLORS.textColor,
  },
  font: {
    family: 'Poppins:wght@400;600;700',
    size: 10,
    sizeSmall: 10,
  },
  buttons: {
    mode: 'fill',
    roundness: 8,
    textColor: COLORS.textColor,
    baseColor: COLORS.primaryGreen,
  },
}

export const SIZES = [
  8,
  16,
  24,
  32,
]
