import { REDUX_FETCH_STATUS } from 'constants'
import Loading from 'fragments/Loading'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser } from 'reducers/usersReducer'
import { saveSession } from 'utils'
import { fetchOrganisation } from 'reducers/organisationReducer'
import { hasIncompleteOnboardingSurveys } from 'pages/OnboardingQuestions'

/**
 * A component to protect routes.
 * Shows Auth page if the user is not authenticated
 */
function PrivateRoutes() {
  const dispatch = useDispatch()
  const location = useLocation()

  // Global State Selectors
  const userFetchState = useSelector((state) => state.user.userFetchState)
  const loadedUser = useSelector((state) => state.user)
  const organisationFetchState = useSelector((state) => state.organisation.organisationFetchState)
  const loadedOrganisation = useSelector((state) => state.organisation)

  // Local State
  const [redirectPath, setRedirectPath] = React.useState(null)
  const [redirectParams, setRedirectParams] = React.useState(null)

  // Query Params
  const query = new URLSearchParams(window.location.search)
  const userId = query.get('userId')
  const userToken = query.get('token')

  useEffect(() => {
    setRedirectPath(null)

    // Autologin from query parameters
    if (userId && userToken) {
      window.hj('event', `logged_in via ${window.location.pathname}`)
      saveSession(userId, userToken)
    }

    if (userFetchState === REDUX_FETCH_STATUS.idle) dispatch(fetchUser())

    if (organisationFetchState === REDUX_FETCH_STATUS.idle) dispatch(fetchOrganisation())

    if (
      userFetchState === REDUX_FETCH_STATUS.failed
      || organisationFetchState === REDUX_FETCH_STATUS.failed
    ) {
      setRedirectPath('/signout')
    }

    if (
      userFetchState === REDUX_FETCH_STATUS.succeeded
      && organisationFetchState === REDUX_FETCH_STATUS.succeeded
    ) {
      if (loadedUser.verified !== true) {
        setRedirectPath('/verification')
        setRedirectParams({ email: loadedUser.email })
        return
      }

      // if (loadedUser.requiresUnidaysVerification) {
      //   window.location.replace(`${config.domains.api}/unidays-web`)
      //   return
      // }

      if (loadedUser.acceptedPrivacyPolicy === false) {
        setRedirectPath('/privacy-policy')
        return
      }

      if (hasIncompleteOnboardingSurveys(loadedUser, loadedOrganisation)) {
        setRedirectPath('/questions')
      }
    }
  }, [userFetchState, organisationFetchState, loadedUser])

  // Special case to allow use effect to indicate that routing should be done to Outlet
  if (redirectPath && location.pathname !== redirectPath) {
    return <Navigate to={redirectPath} state={{ ...redirectParams }} />
  }

  if (
    userFetchState === REDUX_FETCH_STATUS.succeeded
    && organisationFetchState === REDUX_FETCH_STATUS.succeeded
  ) {
    return <Outlet />
  }

  return <Loading />
}

export default PrivateRoutes
