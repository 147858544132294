import React, { useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import Loading from 'fragments/Loading'
import { isIOS, isAndroid } from 'react-device-detect'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../constants'

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  max-width: 900px;
  color: ${COLORS.veryDarkGray};
  border-radius: 8px;
  background-color: ${COLORS.white};
`

export default function AppStoreRedirect() {
  const navigate = useNavigate()

  useEffect(() => {
    if (isIOS) navigate('https://apps.apple.com/us/app/syndi-assess/id1563004457')
    else if (isAndroid) navigate('https://play.google.com/store/apps/details?id=digital.syndi.assess')
    else navigate('https://www.syndi.university/unidays')
  }, [])

  return (
    <GridContainer justifyContent="center" direction="column" alignItems="center">
      <OuterContainer>
        <Loading />
      </OuterContainer>
    </GridContainer>
  )
}
