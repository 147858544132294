import React, { useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Fab from '../Fab'
import * as style from './Support.style'
import SOS from '../../assets/img/sos_lg.png'

export default function Support({ open, setOpen, content = [] }) {
  const isMobile = window.innerWidth < 600

  return (
    <>
      <Fab onClick={() => setOpen(true)}>
        <img id="fab-img" src={SOS} style={{ height: 56 }} alt="sos" />
      </Fab>
      {isMobile ? (
        <MobileSOS onClose={() => setOpen(false)} open={open} content={content} />
      ) : (
        <DesktopSOS onClose={() => setOpen(false)} open={open} content={content} />
      )}
    </>
  )
}

export function MobileSOS({ onClose, open, content }) {
  const { t } = useTranslation('common')
  const sosContent = content.content || []
  const handleClose = () => onClose()
  const classes = style.useMobileStyles()
  useEffect(() => {
    if (open) {
      window.hj('trigger', 'track SOS modal click')
    }
  }, [open])

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <style.StyledDialogTittle
        id="alert-dialog-title"
        onClose={handleClose}
      />

      <DialogContent>
        <Typography className={classes.title} variant="subtitle1">
          Crisis Support
        </Typography>
        <DialogContentText
          id="alert-dialog-description"
          variant="subtitle2"
          className={classes.text}
        >
          {t('support.message')}
        </DialogContentText>
        <List>
          {sosContent.map((sos, i) => (
            <ListItem key={i.toString()} className={classes.sosContainer}>
              <ListItemAvatar>
                <Avatar variant="square" src={sos.logo} className={classes.sosLogo} />
              </ListItemAvatar>
              <ListItemText
                className={classes.sosName}
                primary={<style.PrimaryTypography>{sos.title}</style.PrimaryTypography>}
              />
              <ListItemSecondaryAction>
                <style.ActionButton href={sos.href}>{sos.action}</style.ActionButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export function DesktopSOS({ onClose, open, content }) {
  const { t } = useTranslation('common')
  const sosContent = content.content || []
  const handleClose = () => onClose()
  const classes = style.useDesktopStyles()
  useEffect(() => {
    if (open) {
      window.hj('trigger', 'track SOS modal click')
    }
  }, [open])

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <style.StyledDialogTittle
        id="alert-dialog-title"
        onClose={handleClose}
      />
      <style.StyledDialogContent>
        <CardMedia
          component="img"
          // height={64}
          className={classes.sosIcon}
          image={SOS}
          title="Sos icon"
        />
        <Typography className={classes.title} variant="subtitle1">
          Crisis Support
        </Typography>
        <DialogContentText
          id="alert-dialog-description"
          variant="subtitle2"
          className={classes.text}
        >
          {t('support.message')}
        </DialogContentText>
        {sosContent.map((sos, i) => (
          <Card key={i.toString()} className={classes.root} id="card_root" elevation={3}>
            <CardMedia
              component="img"
              className={classes.cover}
              src={sos.logo}
              title="Cover title"
            />
            <div className={classes.details} id="details_container">
              <CardContent className={classes.content} id="card_content">
                <Typography variant="body1" className={classes.appName}>
                  {sos.title}
                </Typography>
                <Typography variant="subtitle1" className={classes.appDesc} dangerouslySetInnerHTML={{ __html: sos.subtitle }} />
              </CardContent>
            </div>
            <CardMedia
              component="img"
              className={classes.code}
              src={sos.qr_image}
              title="Cover title"
            />
          </Card>
        ))}
      </style.StyledDialogContent>
    </Dialog>
  )
}
