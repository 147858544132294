import React from 'react'
import { Fab } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

const styles = (theme) => ({
  fab: {
    position: 'fixed',
    bottom: isMobile ? theme.spacing(7) : theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: 'rgb(207,119,118)',
    marginBottom: 30,
    zIndex: 3,
  },
})

function FloatingActionButton({
  classes, children, onClick, ...rest
}) {
  return (
    <Fab
      {...rest}
      onClick={onClick}
      className={classes.fab}
      color="secondary"
    >
      {children}
    </Fab>
  )
}

FloatingActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(FloatingActionButton)
