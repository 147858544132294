import React from 'react'
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { COLORS, CURRENCY_SYMBOLS } from '../../constants'

const Status = styled.span`
    color: ${COLORS.black};
    background-color: ${COLORS.backgroundGrey};
    font-size: 12px;
    padding: 8px;
    border-radius: 8px;
    font-weight: 600;
`

const HeaderText = styled.h1`
  margin: 10px 0 10px 0;
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.43px;
  text-align: left;
  color: ${COLORS.darkNavyBlue};
`

export default function Transactions({
  transactionData,
}) {
  const rows = transactionData.map((transaction, idx) => {
    const transactionRow = {
      name: `Transaction ${idx}`,
      date: moment.unix(transaction.created).format('DD MMM'),
      description: transaction.merchant_data.name,
      // is no declined transactions,
      // only approved transactions go into transaction list
      // only other status is 'Refund' which is rare to see
      status: 'Approved',
      price: ((transaction.amount * -1) / 100).toFixed(2),
      currency: transaction.currency.toUpperCase(),
      currencySymbol: CURRENCY_SYMBOLS[transaction.currency] || '?',
    }
    return transactionRow
  })

  return (
    <TableContainer style={{ margin: isMobile ? '10px' : '150px', height: '350px', width: '80%' }}>
      <HeaderText>Your Purchases</HeaderText>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ color: COLORS.darkNavyBlue }}>Date</TableCell>
            <TableCell style={{ color: COLORS.darkNavyBlue }} align="left">Description</TableCell>
            <TableCell style={{ color: COLORS.darkNavyBlue }} align="left">Status</TableCell>
            <TableCell style={{ color: COLORS.darkNavyBlue }} align="left">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell
                style={{
                  fontSize: '13px', fontWeight: 600, color: COLORS.darkNavyBlue, minWidth: '45px',
                }}
                align="left"
              >
                {row.date}
              </TableCell>
              <TableCell style={{ fontSize: '13px', color: COLORS.primaryBlue, width: 'auto' }} align="left">{row.description}</TableCell>
              <TableCell align="left"><Status>{row.status}</Status></TableCell>
              <TableCell
                style={{
                  fontSize: '13px', fontWeight: 600, color: COLORS.primaryBlue, minWidth: '67px',
                }}
                align="left"
              >
                -
                { row.currencySymbol }
                {row.price}
                <span style={{
                  fontSize: '13px', fontWeight: 600, marginLeft: '2px', color: COLORS.grey,
                }}
                >
                  { row.currency }
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
