/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { GiHamburgerMenu } from 'react-icons/gi'
import { COLORS } from 'constants'
import { Link } from 'react-router-dom'
import SyndiLogo from '../../assets/img/logos/syndi-logo-white.webp'

const Icon = styled.span`
  color: ${COLORS.white};
  height: 18px;
  width: 18px;
  margin: 0px 15px 8px 5px;
`
const NavText = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: ${({ selected }) => (selected ? '600' : '500')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${COLORS.white};

  &:hover {
    color: ${COLORS.white};
    font-weight: 600;
  }

  &:hover ${Icon} {
    color: ${COLORS.white};
  }
`

const NavButton = styled.button`
  padding: 16px 24px 16px 24px;
  width: 100%;
  border: none;
  border-radius: 4px;
  background-color: ${({ selected }) => (selected ? COLORS.darkPurple : 'transparent')};
  cursor: pointer;
`

const SecondaryNavButton = styled.button`
  border: none;
  border-radius: 4px;
  background-color: ${({ selected }) => (selected ? 'transparent' : 'transparent')};
  cursor: pointer;
`

const Logo = styled.img`
  width: 70px;
  height: 34px;
  margin-top: 5px;
`
export default function NavbarMobile({
  locationPathName, primaryNavMenu, secondaryNavMenu,
}) {
  const [mobileNavExpanded, setMobileNavExpanded] = useState(false)

  return (
    <Grid container direction="column">
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Link to="/home">
            <Logo src={SyndiLogo} />
          </Link>
        </Grid>

        <Grid item>

          {secondaryNavMenu.map((item, idx) => (
            <Link to={item.link} key={`${idx}u`}>
              <SecondaryNavButton
                selected={locationPathName === item.link}
                onClick={item.onClick}
              >
                <Icon>{item.icon}</Icon>
              </SecondaryNavButton>
            </Link>
          ))}
          <Icon>
            <GiHamburgerMenu onClick={() => setMobileNavExpanded(!mobileNavExpanded)} />
          </Icon>
        </Grid>
      </Grid>

      {mobileNavExpanded && (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          {primaryNavMenu.map((item, idx) => (
            <Grid item key={`${idx}u`}>
              <Link
                to={item.link}
              >
                <NavButton
                  selected={locationPathName === item.link}
                  onClick={item.onClick}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Icon>{item.icon}</Icon>
                    <NavText>{item.name}</NavText>
                  </Grid>
                </NavButton>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  )
}
