import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../constants'

const InputContainer = styled.div`
    width: 300px;
    margin: 0 0 20px;

    @media only screen and (max-width: 600px) {
        width: 230px;
      }
`

const Label = styled.p`
    height: 5px;
    font-family: Poppins;
    margin: 0 -5px 0 0;
    padding-top: 4px;
    font-size: 10px;
    text-align: right;
    color: ${COLORS.white};
    display: block;
    margin: 5px 0px 15px 0px;
`

const ErrorLabel = styled(Label)`
    color: ${COLORS.primaryRed};
`

const Input = styled.input`
    width: 100%;
    height: 35px;
    padding: 0 0 0 5px;
    background-color: ${COLORS.primaryBlue};
    font-size: 15px;
    color: ${COLORS.white};
    border-bottom-color: ${({ error }) => (error ? COLORS.primaryRed : COLORS.primary)};
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    @media only screen and (max-width: 600px) {
      min-width: ${(props) => props.minWidth || null};
    }

    &:focus ${Label} {
        display: block;
    }

    &:focus {
        background-color: #3a536e;
    };
`

export default function TextField({
  type,
  placeholder,
  value,
  onChange,
  label,
  showError,
  errorMessage,
  disabled,
  min,
  max,
  minWidth,
}) {
  return (
    <InputContainer>
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        error={showError}
        disabled={disabled}
        min={min}
        max={max}
        minWidth={minWidth}
      />
      {showError
        ? (
          <ErrorLabel>
            {errorMessage}
          </ErrorLabel>
        )
        : (
          <Label>
            {label}
          </Label>
        )}
    </InputContainer>
  )
}
