import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../constants'

const Button = styled.button`
    width: ${(props) => props.width || '288px'};
    border-radius: 16.5px;
    border: transparent;
    font-family: Poppins;
    font-size: ${(props) => props.fontSize || '15px'};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.darkNavyBlue};
    cursor: pointer;
    margin: 5px auto;
    padding: 5px 10px 5.5px 10px;
    background-color: ${(props) => props.backgroundColor || '#66f091'};
    display: ${(props) => props.display || 'block'};
    justify-content: space-evenly;
    &:hover {
      background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor || ''};
      color: ${({ hoverColor }) => hoverColor || ''};
    }

    // mobile
    @media only screen and (max-width: 600px) {
      padding: 10px 60px 10.5px 60px;
    }
`

export default function DownloadButton(
  {
    onClick,
    children,
    width,
    display,
    fontSize,
    margin,
    backgroundColor,
    hoverBackgroundColor,
    hoverColor,
  },
) {
  return (
    <Button
      onClick={onClick}
      width={width}
      display={display}
      fontSize={fontSize}
      margin={margin}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverColor={hoverColor}
    >
      {children}
    </Button>
  )
}
