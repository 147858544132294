/* eslint-disable max-len */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import { COLORS } from '../../constants'

const Container = styled.div`
  height: 100%;
  padding: 16px;
  color: ${COLORS.veryDarkGray};
`

const ConsentArea = styled.div`
  margin-bottom: -127px;
  padding: 16px;
  padding-bottom: 160px;
`

const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${COLORS.veryDarkGray};
  padding: 8px 0;
`

const Text = styled.div`
  color: ${COLORS.veryDarkGray};
  font-size: 16px;
  padding: 8px 0;
`

const Link = styled.a`
  color: ${COLORS.veryDarkGray};
  font-size: 16px;
  padding: 8px 0;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  background-color: ${COLORS.white};
  left: 0;
  bottom: 0;
  width: 100%;
  height: 127px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  border-top: solid 1px ${COLORS.veryLightGray};
  color: white;
`

export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onClick = () => {
    window.history.back()
  }

  return (
    <Container>
      <ConsentArea>
        <Header>Privacy Policy</Header>
        <Text>Last updated: August 02, 2023</Text>
        <Text>
          This policy describes our policies and procedures for collection,
          transmission, storage, processing, disclosure and protection of any
          data, including, but not limited to, personal data provided by you as
          a user while using the service. user shall mean any person/ persons,
          who visits, uses, deals with and/ or transacts through Syndi App
          (“privacy policy”). The privacy policy and any other terms and
          conditions as may be applicable shall hereinafter collectively be
          referred to as “agreement” or “contract”.
        </Text>
        <Header>Important Notice</Header>
        <Text>
          1. If You are having suicidal thoughts or planning to act on suicidal
          thoughts, or if You feel that You may be a danger to Yourself or to
          others, or if You otherwise have any medical or mental health
          emergency or severe mental health condition, or if You are in a crisis
          or trauma or abuse, please discontinue use of the Service immediately
          and call the relevant emergency number in Your country and notify the
          police or emergency medical Services. For example, You can find Your
          country-specific suicide emergency number at:
          {' '}
          <a>
            <Link href="http://www.suicide.org/international-suicide-hotlines.html">
              <u>
                Suicide.org - Suicide Prevention, Awareness, and Support
              </u>
            </Link>
          </a>
          .
        </Text>

        <Text>
          2. Syndi Health is not to be used by a person under 18
          years of age.
        </Text>
        <Text>
          3. The Syndi App will supply patient health questionnaires for you
          to monitor your physical and mental wellbeing. The underlying principle of the
          Service is that You have the knowledge and capacity to make
          desired changes in Your life.
        </Text>
        <Text>
          4. The Syndi App is not intended to be a replacement for face-to-face
          psychotherapy or to provide a diagnosis, prognosis, treatment or cure
          for a disease/condition/disorder or disability or provide any type of
          state-regulated health services in Your country of residence.
          It is an enabling and empowering mode of support, rather than
          treatment of illness or a health condition.
        </Text>
        <Text>
          5. The Syndi App cannot and will not offer medical or clinical advice.
          In case You mention the need for such advice, they will suggest that
          You seek advanced (medical) help.
        </Text>
        <Text>
          6. We take great pains to keep Your data private and secure.
          You can also keep Your data private and device secure by
          following the best practices outlined in this Privacy Policy.
        </Text>
        <Header>Introduction</Header>
        <Text>
          Welcome to Syndi App, the mobile and online service of Syndi Ltd
          (hereinafter “Syndi”, “We”, “Us”, or “Our”). When you as a Data
          Subject (“User”, or “You/r”) use Syndi Health’s Services, You trust us with
          Your Data. This Privacy Policy governs Your use of the mobile or web
          browser based software application on the Syndi website (hereinafter
          the “Syndi App”) created by Syndi and also covers Your use of Our
          Website, other websites maintained by Us. This Privacy Policy along
          Syndi Health’s Terms of Service constitutes a legal agreement between
          You and Syndi Health.
        </Text>
        <Text>
          This Privacy Policy (“Policy” or “Privacy Policy”) has been drafted in
          accordance with the Information Technology Act, 2000, the Information
          Technology (Reasonable security practices and procedures and sensitive
          personal data or data) Rules, 2011, including (but not limited to)
          requirements within General Data Protection Regulation (GDPR) (EU)
          2016/679 regulation and where applicable the Health Insurance
          Portability and Accountability Act (collectively referred to as “Data
          Protection Laws”).
        </Text>
        <Header>Identity of the controller of personal information</Header>
        <Text>
          The Data Controller for Syndi App is Syndi Ltd, a company registered
          in the United Kingdom and having its registered office address at
          Unit 1, Cambridge House Camboro Business Park, Oakington Road, Girton,
          Cambridge, Cambridgeshire, United Kingdom, CB3 0QH.
        </Text>
        <Header>Contact details of the Data Protection Officer</Header>
        <Text>
          If You have any concerns or grievances about this Privacy Policy You
          will need to send an email request to
          {' '}
          <a href="mailto:hello@Syndi.health"><Link><u>hello@Syndi.health</u></Link></a>
          {' '}
          with Attn. to
          our Data Protection Officer Mr. Jorge Alexander. We will respond to
          You within 48 hours and help resolve Your concerns or complaints.
        </Text>
        <Text>
          If You are not satisfied with Our resolution, You have the right to
          complain to a Data Protection supervisory authority in Your country or
          state of residence. We will fully cooperate with the supervisory
          authority. Contact details for Data Protection Authorities in the EU
          are available
          {' '}
          <a href="https://ec.europa.eu/newsroom/article29/items/612080"><Link><u>here</u></Link></a>
          .
        </Text>
        <Header>What does this Privacy Policy apply to?</Header>
        <Text>
          This Privacy Policy applies to the data You provide Us when You Use
          the Syndi App and Service. The mobile-based and/or web browser based
          service provided via Syndi App are collectively referred to
          as the &quot;Service(s)&quot;.
        </Text>
        <Text>
          This Privacy Policy is meant to help You understand what data We
          collect, why We collect it, what We do with it, how You can manage and
          control the Use of Your data and the rights You have to access and
          control Your Personal data. Please read the definitions in the next
          section to understand the terminologies used in this Privacy Policy.
        </Text>
        <Text>
          We will always respect and protect Your privacy, and this forms a part
          of Our guiding principles. We have policies and procedures in place to
          protect the privacy and security of Your Personal data. Your trust
          means a lot to Us. Your data is secured with strong encryption during
          transmission and storage.
        </Text>
        <Text>
          Your use of the Syndi App will be governed by this Privacy Policy as
          applicable to the Syndi App together with all policies, notices,
          guidelines, disclaimers that are published and shared with You from
          time to time including but not limited to Syndi Health’s Terms of Service.
        </Text>
        <Text>
          Users may request additional Services from Syndi Health or Syndi Health affiliates.
          Because the needs and choices of each User may vary, We may provide
          separate privacy policies or addendums to this Privacy Policy for
          certain additional Services. Any applicable separate privacy policies
          and addendums will explain the types of data We collect, their
          purposes of Use and other policies that may apply to that Service.
          When You choose to Use an additional Service, You may be informed of
          the applicable privacy policy or addendum which applies in addition
          to, and may modify this Privacy Policy, before You can access the
          additional Service.
        </Text>
        <Text>
          Syndi reserves the right to make changes to this Privacy Policy and to
          make such changes effective for all data We may already have collected
          from You.
        </Text>
        <Text>
          Please note that by using Syndi App and Services, You acknowledge and
          agree that You have read and understood this Privacy Policy.
        </Text>
        <Header>What is Syndi App?</Header>
        <Text>
          Using the Syndi App, You can track and manage Your mental and physical wellbeing, and learn context-sensitive evidence-based techniques that can help You feel better.
          Syndi App and Service is not intended for use in crisis such as abuse or complex or severe mental health conditions that causes for example;
          ideation of suicide, harm to self and others, or for any medical emergencies. Syndi App and Service cannot and will not offer medical or clinical advice.
          It can only suggest that the user seeks advanced (medical) help.
        </Text>
        <Header>Who can Use the Service</Header>
        <Text>
          You may Use the Service only if You are a natural/legal person, agree
          to this Agreement and form a binding contract with Syndi Health, and only in
          compliance with all applicable local, state, national, and
          international laws, rules and regulations. Anyone under 18 is strictly
          prohibited from creating an account and/or Using the Service.
        </Text>
        <Header>What Data do We collect and why?</Header>
        <Text>
          When you use the Services, we may collect and process different
          personal data about you. The personal data we process, the basis of
          processing and the purposes of processing are detailed below.
        </Text>
        <Text>
          We encourage you to supply only the information you are comfortable
          with.
        </Text>
        <Text>
          1.
          {' '}
          <strong>Phone Number</strong>
          {' '}
          When You use the Syndi App, you may receive notifications by SMS.
          To do this We receive, transmit and securely store your phone number on Our servers.
          By deleting your account via your profile page within the Syndi App we remove your phone number from our systems.
          IF YOU DELETE YOUR DATA IT WILL BE IMPOSSIBLE TO RESTORE.
        </Text>
        {/* <Text>
          2.
          {' '}
          <strong>Conversation Data</strong>
          {' '}
          When You Use the Syndi App
          Service, You provide Your messages by text. We collect, transmit and
          securely store Your messages on Our servers. We process Your messages
          in real-time using Google Dialogflow that detects the context and
          directs You appropriately to subsequent conversation. At no point
          during Your conversation does Google Dialogflow store your
          conversational data, Google’s GDPR policy can be read here. Your data,
          messages or usage is not used for direct marketing nor is it sold to
          advertisers. We do not use the messages or the data You submit to Us
          as a way to generate revenue for Syndi. We do use anonymised and only
          the minimal data that is required to answer the research question for
          research and statistical purposes based on Our Legitimate Interest to
          improve Our Product and Services and contribute to the development of
          user-centered mental wellbeing best practices globally. The messages
          You send are strongly encrypted during transmission and storage. You
          have the Right to be Forgotten. You can also, at any point of time,
          clear all Your personal data by texting “delete data” to the Syndi SMS
          Bot. All the conversations You have with the Syndi App are private. No
          one within or outside of Syndi has access to Your Data except to
          process based on Our Legitimate Interest and based on principles of
          privacy by design. We will do our best to irreversibly redact any
          Personally Identifiable data inadvertently submitted by You as per Our
          Legitimate Interest. AS A BEST PRACTICE, IT IS ADVISED THAT YOU TAKE
          ADEQUATE PRECAUTIONS TO NOT SHARE YOUR SENSITIVE HEALTH OR PERSONAL
          DATA WHILE TEXTING THE SYNDI SMS BOT.
        </Text> */}
        <Text>
          2.
          {' '}
          <strong>Health Assessment Responses</strong>
          {' '}
          When You Use the Service, You will be asked to respond to validated
          self-assessments. Syndi App currently Uses several validated assessment
          scales for understanding Your emotional and physical wellbeing to self-report
          any symptoms.
          Assessments are a proven way to baseline and track the
          progress of Your self-reported symptoms. Processing of Your assessment
          response is based on Our Agreement and used for the purpose of
          determining if escalation is required and to provide You access to
          scientific-evidence based tools and techniques to manage emotions and
          encourage mental and physical wellbeing in a self-help context. YOUR RESPONSES
          TO THESE ASSESSMENT QUESTIONS ARE NOT PROCESSED TO FORM A DIAGNOSTIC
          OPINION NOR PROCESSED FOR ANY MEDICAL PURPOSES OR FOR GIVING CLINICAL
          ADVICE. We may use Your anonymized assessment scores for population-level
          research and statistical purposes as per Our Legitimate Interest. We
          apply organizational and technical measures to endeavour to irreversibly
          redact any Personally Identifiable data inadvertently submitted by You as
          per Our Legitimate Interest. Your response is encrypted during transmission
          and is securely stored. YOUR PERSONAL DATA IS NEVER SHARED WITH A THIRD PARTY
          WITHOUT YOUR EXPLICIT CONSENT.
        </Text>
        <Text>
          3.
          {' '}
          <strong>Contact Form Data</strong>
          {' '}
          When You Use the Service, You have an option
          to contact us through Our Website. You can Use this feature to email
          Us Your feedback. Personal data, if any provided in Your feedback,
          will be manually redacted before any processing of Your feedback. Your
          email ID resides in our GSuite Gmail servers.
        </Text>
        <Text>
          4.
          {' '}
          <strong>Email & Password</strong>
          {' '}
          When You use the Syndi App, you may choose an email and password combination in order
          to access your account. To do this We receive, transmit and securely store your email and encrypted password
          on Our servers. By deleting your account via your profile page within the Syndi App we remove your email and password
          from our systems. IF YOU DELETE YOUR DATA IT WILL BE IMPOSSIBLE TO RESTORE.
        </Text>
        <Text>
          5.
          {' '}
          <strong>Third party website analytics data</strong>
          {' '}
          Our Service uses Hotjar. Hotjar is a third-party technology service that helps us better understand our users&apos; experience
          (e.g. how much time they spend on which pages, which links they choose to click, what users do and don&apos;t like, etc.) and
          this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users&apos;
          behaviour and their devices. This includes a device&apos;s IP address (processed during your session and stored in a de-identified form),
          device screen size, device type (unique device identifiers), browser information, geographic location (country only),
          and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile.
          Hotjar is contractually forbidden to sell any of the data collected on our behalf.
          {' '}
          <br />
          For further details, please see the &apos;about Hotjar&apos; section of
          {' '}
          <a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar"><Link><u>Hotjar&apos;s support site</u></Link></a>
          .
        </Text>
        <Text>
          6.
          {' '}
          <strong>Unidays Sign in Data</strong>
          {' '}
          When You Use the Service You have an option to log in using Unidays single sign-on (SSO).
          Unidays single sign-on will allow Unidays to authenticate your identity and provide you the option to share certain personal information with us such as your email and place of study.
        </Text>

        <Text>
          7.
          {' '}
          <strong>Google Sign in Data</strong>
          {' '}
          When You Use the Service You have an option to log in using Google single sign-on (SSO).
          Google single sign-on will allow Google to authenticate your identity and provide you the option to share certain personal information with us such as your email.
        </Text>

        <Text>
          8.
          {' '}
          <strong>Third party financial data</strong>
          {' '}
          When You Use the Service You have an option to register for a Stripe virtual payment card.
          To register a virtual payment card we ask for your first name, last name, email, phone number, date of birth and address.
          This is then shared with Stripe for them to issue a virtual card. For more information on Stripe&apos;s privacy policy see:
          {' '}
          <a>
            <Link href="https://stripe.com/en-gb-pt/legal/privacy-center">
              <u>
                Stripe Privacy Center
              </u>
            </Link>
          </a>
        </Text>

        <Header>Disclosures of your personal data</Header>

        <Header>Use of de-identified and aggregated information </Header>
        <Text>
          We do use anonymised and only the minimal data that is required to
          answer the research question for research and statistical purposes
          based on Our Legitimate Interest to improve Our Product and Services
          and contribute to the development of user-centered mental wellbeing
          best practices globally. The messages You send are strongly encrypted
          during transmission and storage.
        </Text>

        <Header>Transfer outside the European Economic area/UK</Header>
        <Text>
          Your personal data may be transferred, stored and processed in one or
          more countries outside the European Economic Area (“EEA”) or the UK,
          for example, when one of our service providers use employees or
          equipment based outside the EEA or UK. For transfers of your personal
          data to third parties outside of the EEA or UK, we take additional
          steps in line with applicable law. We will put in place adequate
          safeguards with respect to the protection of your privacy, fundamental
          rights and freedoms, and the exercise of your rights, e.g. we will
          establish an adequate level of data protection through EU Standard
          Contractual Clauses based on the EU Commission’s model clauses.
        </Text>

        <Header>How is my personal data secured</Header>
        <Text>
          Syndi operates and uses appropriate technical and physical security
          measures to protect your personal data. We have, in particular, taken
          appropriate security measures to protect your personal data from
          accidental or unlawful destruction, loss, alteration, unauthorized
          disclosure, or access. Access is only granted on a need-to-know basis
          to those people whose roles require them to process your personal
          data. You are also responsible for helping to protect the security of
          your personal data. For instance, you should not disclose any personal
          information via text to the Syndi App. You are responsible for
          maintaining the security of any device on which you utilize the
          Services. Unfortunately, no system is 100% secure, and we cannot
          ensure or warrant the security of any personal data you provide to us.
          To the fullest extent permitted by applicable law, we do not accept
          liability for unintentional disclosure.
        </Text>

        <Header>Storage of personal data</Header>
        <Text>
          We will keep your personal data for as long as it is necessary to
          fulfil the purposes for which it was collected as described above and
          in accordance with our legal and regulatory obligations.
        </Text>
        <Text>
          If you would like further information about our data retention
          practices you can ask for this at any time (see “Contact Us” section
          below).
        </Text>

        <Header>Your rights</Header>
        <Text>
          You may have various rights under data protection legislation in your
          country (where applicable).
        </Text>
        <Text>These may include (as relevant):</Text>
        <Text>
          1. The right of access enables you to check what type of personal data
          we hold about you and what we do with that personal data and to
          receive a copy of this personal data;
        </Text>
        <Text>
          2. The right to rectification enables you to correct any inaccurate or
          incomplete personal data that we hold about you;
        </Text>
        <Text>
          3. The right to erasure enables you to request that we erase personal
          data held about you in certain circumstances;
        </Text>
        <Text>
          4. The right to restrict processing of your personal data by us in
          certain cases, including if you believe that the personal data held
          about you is inaccurate or our use of the personal data is unlawful;
          and
        </Text>
        <Text>
          5. The right to data portability enables you to receive your personal
          data in a structured, commonly used and machine readable format and to
          have that personal data transmitted to another data controller.
        </Text>
        <Text>
          Note that we will require you to take steps to verify your identity in
          accordance with applicable law.
        </Text>

        <Header>&quot;Do not Track&quot;</Header>
        <Text>
          Do Not Track (“DNT”) is a privacy preference that users can set in
          certain web browsers. Please note that we do not respond to or honor
          DNT signals or similar mechanisms transmitted by web browsers.
        </Text>

        <Header>
          Your right to lodge a complaint with a supervisory authority
        </Header>
        <Text>
          If you are unhappy about any aspect of the way we collect, share or
          use your personal data, please let us know using the contact details
          below. You also have a right to complain to your local Data Protection
          Authority if you prefer. Contact details for Data Protection
          Authorities in the EU are available at Data Protection.
        </Text>

        <Header>Glossary</Header>
        <Text>
          <strong>Personal data</strong>
          {' '}
          or
          {' '}
          <strong>Personal Information</strong>
          {' '}
          means data relating to an
          identified or identifiable natural person who can be directly or
          indirectly identified by reference to an identifier such as full name,
          identification numbers, location address, online identifier and other
          identifiers within the definitions of The Information Technology
          (Reasonable Security Practices and Procedures and Sensitive Personal
          Data or data) Rules 2011 and General Data Protection Regulation (GDPR)
          (EU) 2016/679 regulation. Personally identifiable information (PII)
          and Special Category of Personal data is covered within the definition
          of Personal Data.
        </Text>
        <Text>
          <strong>Non-Personal</strong>
          {' '}
          data or
          {' '}
          <strong>Non-Personal Information</strong>
          {' '}
          means any data that does not
          reveal Your specific identity either directly or indirectly.
        </Text>
        <Text>
          <strong>Data</strong>
          {' '}
          or
          <strong>Information</strong>
          {' '}
          under this
          Privacy Policy means Both Personal and Non-Personal data or
          information.
        </Text>
        <Text>
          <strong>Pseudonymisation</strong>
          {' '}
          means the processing of Personal
          data in such a manner that the Personal data can no longer be
          attributed to a specific User without the use of additional
          information, provided that such additional information is kept
          separately and is subject to technical and organisational measures to
          ensure that the Personal data are not attributed to an identified or
          identifiable natural person
        </Text>
        <Text>
          A
          {' '}
          <strong>Cookie</strong>
          {' '}
          is a small amount of data generated by a
          Website and saved by Your Web browser. Cookies are used to store User
          preferences for a specific site. Use of cookies makes Web-surfing
          easier. You may refuse to accept Cookies by activating the setting on
          Your browser which allows You to refuse the setting of Cookies.
        </Text>
        <Text>
          <strong>Encryption</strong>
          {' '}
          is the process of transforming data into
          unreadable text so that it is only legible to those possessing an
          encryption key. The process of making encrypted data readable again is
          referred to as decryption.
        </Text>
        <Text>
          <strong>Web browser</strong>
          {' '}
          is a software program that allows User to
          access, retrieve and view data on the World Wide Web. Examples of
          browsers include Internet Explorer, Firefox, Google Chrome and Safari.
        </Text>

        <Header>Changes to this policy</Header>
        <Text>
          We may need to make changes to this Privacy Policy at any time. If we
          make any material changes to how we collect your personal data, or how
          we use or share it, we will post or provide appropriate notice in
          accordance with applicable law.
        </Text>
        <Text>
          In order to ensure fairness of the processing, we encourage you to
          review the content of this Privacy Policy regularly.
        </Text>

        <Header>Contact us</Header>
        <Text>
          For further information, to exercise your rights, or if you have any
          questions or queries about this Privacy Policy, please contact Syndi Health’s
          Data Protection Officer:
        </Text>
        <Text>
          Email:
          {' '}
          <a href="mailto:hello@Syndi.health"><Link><u>hello@Syndi.health</u></Link></a>
        </Text>
        <Text>
          Postal: Unit 1, Cambridge House Camboro Business Park, Oakington Road, Girton,
          Cambridge, Cambridgeshire, United Kingdom, CB3 0QH.

        </Text>
      </ConsentArea>
      <Footer>
        <Button onClick={onClick}>Back</Button>
      </Footer>
    </Container>
  )
}
