import React from 'react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts'
import { COLORS } from '../../constants'
import './styles.css'

const AxisLabelStyle = {
  fontSize: '10px',
  lineHeight: '20px',
  letterSpacing: '0.42px',
  fontFamily: 'Poppins',
  color: '#414D55',
  fontWeight: 'bold',
}

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div>
        <p>{`${label}`}</p>
      </div>
    )
  }
  return null
}

export default function CommunityBarChart({ data }) {
  const isMobile = window.innerWidth < 600
  const addColorToBar = (data) => {
    const colorList = ['#5e4bdb', '#2cc8d2', '#53a6e8', '#5dcc81', '#f9c280']
    const newData = data.map((item, index) => {
      const newItem = { ...item }
      newItem.fill = colorList[index]
      return newItem
    })
    return newData
  }
  const chartData = addColorToBar(data)

  const formatName = (healthServiceName) => {
    const maxLength = 15
    if (healthServiceName.length > maxLength && isMobile) {
      return `${healthServiceName.substring(0, maxLength)}...`
    }
    return healthServiceName
  }

  return (
    <ResponsiveContainer width="100%" height="80%">
      <BarChart
        data={chartData}
        layout="vertical"
        barSize={25}
        stackOffset="sign"
        margin={{
          top: 5,
          right: 30,
          left: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid
          vertical={false}
          horizontal={false}
        />
        <XAxis
          type="number"
          style={AxisLabelStyle}
          tickLine={false}
          axisLine={false}
          tick={false}
        />
        <YAxis
          dataKey="appName"
          type="category"
          tickLine={false}
          axisLine={false}
          style={AxisLabelStyle}
          tickFormatter={formatName}
          width={isMobile ? 50 : 200}
        />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
        <Bar
          dataKey="users"
          fill={COLORS.navbarGreen}
          radius={[8, 8, 8, 8]}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
