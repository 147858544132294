import React from 'react'
import {
  FormControlLabel, Checkbox,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const GreenCheckbox = withStyles({
  root: {
    color: '#a0a0a0',
    '&$checked': {
      color: '#5fcd81',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export function PrivacyPolicyCheckbox({ onChange, value }) {
  const [state, setState] = React.useState({
    agree: value,
  })

  const handleChange = (event) => {
    event.persist()
    const newValue = event.target.checked
    setState((state) => ({ ...state, [event.target.name]: event.target.checked }))
    onChange(newValue)
  }

  return (
    <FormControlLabel
      id="privacy-policy-checkbox"
      name="formControlLabel"
      control={(
        <GreenCheckbox
          checked={state.agree}
          name="agree"
          onChange={handleChange}
        />
      )}
    />
  )
}
