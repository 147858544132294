import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, ThemeProvider } from '@material-ui/core'
import TripettoSurvey from 'components/TripettoSurvey'
import { fetchUser } from 'reducers/usersReducer'
import { COLORS } from 'constants'
import { useNavigate } from 'react-router-dom'
import Loading from 'fragments/Loading'
import theme from './OnboardingQuestions.style'

export const hasIncompleteOnboardingSurveys = (user, organisation) => {
  const userSurveys = user?.userSurveys || []

  const orgSurveys = organisation.organisationOnboardingSurveys

  const prevCompletedSurveyNames = userSurveys.map((survey) => survey.surveyType)

  if (orgSurveys.length === 0) return false

  const incompletedOnboardinSurveys = orgSurveys.filter(
    (survey) => !prevCompletedSurveyNames.includes(survey.surveyName),
  )
  return incompletedOnboardinSurveys.length > 0
}

export default function OnboardingQuestions() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Global Redux State
  const organisationOnboardingSurveys = useSelector((state) => state.organisation.organisationOnboardingSurveys)

  // User State
  const userSurveys = useSelector((state) => state.user.userSurveys)

  // Local component state
  const [tripettoSurveys, setTripettoSurveys] = useState(null)

  const buildSurveys = (userSurveys, organisationOnboardingSurveys) => {
    // Only repeat onboarding surveys that haven't been completed by the user already
    const previousSurveyNames = userSurveys.map((survey) => survey.surveyType)
    return organisationOnboardingSurveys.filter((survey) => !previousSurveyNames.includes(survey.surveyName))
  }

  useEffect(() => {
    window.hj('event', 'viewed page syndi.cloud/questions')
    const tripettoSurveys = buildSurveys(userSurveys || [], organisationOnboardingSurveys || [])
    if (tripettoSurveys.length === 0) navigate('/home')
    setTripettoSurveys(tripettoSurveys)
  }, [])

  if (!tripettoSurveys) return <Loading />

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: '40px' }}
    >
      <ThemeProvider theme={theme}>
        <Grid
          item
          id="item_title_questions"
          style={{ width: '360px' }}
        >
          <Typography variant="h6" id="title" align="left" style={{ color: COLORS.textColor }}>
            Health & Wellbeing Self-Assessment
          </Typography>
        </Grid>
        <Grid
          item
          id="item_body"
          style={{
            width: '360px',
            minHeight: '0px',
            minWidth: '0px',
            maxHeight: '100%',
          }}
        >
          <TripettoSurvey
            tripettoSurveys={tripettoSurveys}
            onComplete={() => {
              dispatch(fetchUser())
              navigate('/home')
            }}
          />
        </Grid>
      </ThemeProvider>
    </Grid>
  )
}
