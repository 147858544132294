import styled from 'styled-components'
import { COLORS } from '../../constants'

export const Header = styled.div`
  font-size: 20px;
  color: ${COLORS.veryDarkGray};
  font-weight: bold;
  line-height: 36px;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (min-width: 1024px) {
    width: 500px;
  }
`

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 280px;
  padding: 8px 0;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  max-width: 900px;
  color: ${COLORS.veryDarkGray};
  border-radius: 8px;
  background-color: ${COLORS.white};
`

export const ModalClose = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  margin: 0 auto;
`

export const ModalDiv = styled.div`
  transform: translate(-50%, -50%);
  border-radius: 8px;
  top: 50%;
  left: 50%;
  padding: 16px;
  position: absolute;
  text-align: center;
  width: 300px;
  background-color: ${COLORS.white};

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`

export const InputBoxLabel = styled.p`
  font-size: 12px;
  text-align: left;
  margin: 16px 0 0 0;
`
