import React, { useEffect, useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import {
  SubHeader, HelperText, Title, ErrorMessage,
} from 'components/Typography'
import { sendEmailVerification } from 'utils'
import SyndiLogo from 'components/SyndiLogo'
import { useDispatch } from 'react-redux'
import { verifyUser } from 'reducers/usersReducer'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from 'components/Button'
import { COLORS } from 'constants'
import styled from 'styled-components'

const ResendButton = styled.button`
  font-size: 10px;
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0;
  color: ${COLORS.grey};
  &:hover {
    cursor: pointer;
    color: rgba(47, 69, 92, 1);
  }
`

export default function Verification() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [email, setEmail] = useState('')
  const [resendingCode, setResendingCode] = useState(false)
  const [verifyCode, setVerifyCode] = useState('')
  const [verifyCodeError, setVerifyCodeError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  // Extract email from location params
  useEffect(() => {
    setEmail(location.state?.email || '')
  }, [location.state?.email])

  const resendVerificationCode = async () => {
    setResendingCode(true)
    try {
      await sendEmailVerification(email)
    } catch (error) {
      setErrorMessage(error.message)
    }
    setResendingCode(false)
  }

  const onEmailVerify = async () => {
    try {
      await dispatch(verifyUser({
        email,
        code: verifyCode,
      }))
      navigate('/home')
    } catch (error) {
      if (error.code === 'PININVALID00') {
        setVerifyCodeError('The verification code you have entered is not recognised. Please double check it\'s correct or contact us at hello@syndi.health')
      } else {
        setErrorMessage(error.message)
      }
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={10} md={6}>
        <Grid
          container
          direction="column"
          alignItems="stretch"
          justifyContent="center"
          spacing={2}
          style={{
            borderRadius: '8px',
            background: '#F7FBFE',
            boxShadow: '0px 20px 70px 0px rgba(40, 56, 126, 0.14)',
            padding: '32px',
          }}
        >
          <Grid item>
            <SyndiLogo />
          </Grid>
          <Grid item>
            <Title>Almost there 👋</Title>
            <SubHeader>We sent you an email verification code to:</SubHeader>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              placeholder={email}
              disabled
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              placeholder="Verification code"
              label="Verification code"
              error={!!verifyCodeError}
              helperText={verifyCodeError}
              onChange={(e) => {
                setVerifyCodeError('')
                setVerifyCode(e.target.value)
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="primary"
              onClick={onEmailVerify}
              disabled={!!verifyCodeError || !verifyCode}
            >
              Continue
            </Button>
          </Grid>

          {!resendingCode && (
          <Grid item>
            <HelperText>
              Didn&apos;t receive the code?
              {' '}
              <ResendButton type="button" onClick={() => resendVerificationCode()}>
                resend code
              </ResendButton>
            </HelperText>
          </Grid>
          )}

          <Grid item>
            <HelperText>
              Alternatively, you can
              {' '}
              <a
                href="mailto:hello@syndi.health"
                style={{
                  fontSize: '10px',
                  textDecoration: 'underline',
                }}
              >
                contact us
              </a>
            </HelperText>
          </Grid>
          {errorMessage && (
            <Grid item>
              <ErrorMessage style={{
                maxWidth: '350px',
              }}
              >
                {errorMessage}
              </ErrorMessage>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
